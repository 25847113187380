import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { CardElement } from '@stripe/react-stripe-js';

import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
// import ReactPixel from 'react-facebook-pixel';
import { navigate } from '@reach/router';

import { getBasket, getBasketSubtotal } from '@helpers/store';
import { Grid, Row, Col } from '@components/Grid';
import { emptyBasket } from '@actions/basket';
import { handleError } from '@helpers/errors';
import Layout from '@components/Layout';
import { makePutRequest, makePostRequest, makeGetRequest } from '@helpers/requests';
import {
    /*CHECKOUT_SHIPPING,*/ CHECKOUT_BILLING,
    CHECKOUT_COMPLETE,
    CHECKOUT_SHIPPING,
    COUNTRIES,
} from '@helpers/api';

import './styles.module.scss';
import Order from '@views/checkout/Order';
import Overview from '@views/checkout/Overview';
import Steps from '@components/Steps';

// checkout views
import Address from '@views/checkout/Address';
// import Shipping from '@views/checkout/Shipping';
import Payment from '@views/checkout/Payment';
import { bakeLocalStorage, readLocalStorage } from '@helpers/storage';
import { useSnackbar } from '@components/Snackbar';
import SEO from "@components/SEO";

// const key = process.env.NODE_ENV === 'development' ? 'pk_test_BAuXvwuzrqbm3a7RgiDuziu6001f6c7jMA' : 'pk_live_LH2DN07O3KpEZsPOMiARhqcJ00A0HXOyk5';
// const stripePromise = loadStripe(key);

// const stripePromise = loadStripe('pk_test_jyD0modilADVBQmu5gOy74Ws');

const Checkout = ({
    location,
    currentStep,
    emptyBasket,
    addresses,
    basket: { id: basketId },
    data: {
        merlinWebsite: {
            store: { currency },
        },
        merlinStore: { products },
    },
}) => {
    const [openSnackbar] = useSnackbar();
    const [order, setOrder] = useState(null);
    // const [shipping, setShipping] = useState(null);
    const [cardError, setCardError] = useState(null);
    const [countries, setCountries] = useState([]);
    const basketProducts = getBasket(products);
    const subtotal = getBasketSubtotal(basketProducts);

    const checkoutProps = {
        currentStep,
        addresses,
        // shipping,
        location,
        basketProducts,
        basketId,
        subtotal,
        currency,
        countries,
        cardError,
        setCardError,
    };

    useEffect(() => {
        if (!basketProducts.length) {
            toast.error(`You don't have any products to checkout with!`);

            if (typeof window !== 'undefined') {
                navigate('/');
            }

            return;
        }

        // if (process.env.NODE_ENV === 'production') {
        //     ReactPixel.track('InitiateCheckout', {
        //         content_type: 'product',
        //         content_ids: basketProducts.map(item => item.id),
        //         currency: currency.code,
        //         num_items: basketProducts.length,
        //         value: parseFloat(subtotal),
        //     });
        // }

        (async () => {
            try {
                const { data: countries } = await makeGetRequest(COUNTRIES);
                setCountries(countries.sort((a, b) => (a.name > b.name ? -1 : 1)));
            } catch (error) {
                if (error !== 'cancelled') {
                    openSnackbar(error?.errorMessage ?? 'An error occurred loading your checkout.');
                    navigate('/');
                }
            }
        })();

        const possibleSteps = ['address', /*'shipping',*/ 'payment'];

        // if one of the steps match with the url we're good to go
        if (possibleSteps.indexOf(currentStep) > -1) {
            // step matches and all required info is set, don't change the step, we're on the correct route
            if (currentStep === 'payment' && !addresses) {
                return changeStep('address', true);
            }

            return;
        }

        // incorrect step given (doesn't exist) or
        // or no step given at all
        return changeStep('address', true);
    }, []);

    const handlePrevStep = () => {
        // if (currentStep === 'shipping') {
        //     return changeStep('address');
        // }

        if (currentStep === 'payment') {
            return changeStep('address'); // return changeStep('shipping');
        }
    };

    const handleNextStep = async ({ stripe, elements, values, actions }) => {
        if (currentStep === 'address') {
            bakeLocalStorage('checkout_addresses', values);
            const { shipping, billing } = values;

            const shippingData = {
                streetOne: shipping.addressOne,
                streetTwo: shipping.addressTwo || undefined,
                city: shipping.city,
                county: shipping.state,
                postcode: shipping.zip,
                country: shipping.country,
                fullName: shipping.name,
                email: shipping.email,
                phone: shipping.phoneNumber,
            };

            const billingData = billing.sameAsShipping
                ? shippingData
                : {
                      streetOne: billing.addressOne,
                      streetTwo: billing.addressTwo || undefined,
                      city: billing.city,
                      county: billing.state,
                      postcode: billing.zip,
                      country: billing.country,
                      fullName: billing.name,
                      email: billing.email,
                      phone: billing.phoneNumber,
                  };

            await makePutRequest(CHECKOUT_SHIPPING(basketId), shippingData);
            await makePutRequest(CHECKOUT_BILLING(basketId), billingData);

            return changeStep('payment'); // return changeStep('shipping');
        }

        // if (currentStep === 'shipping') {
        //     setShipping(values.service);
        //     return changeStep('payment');
        // }

        if (currentStep === 'payment') {
            // process payment, on success delete booking cookie!
            await handleSubmit({ stripe, elements });
        }
    };

    const changeStep = (newStep, replace = false) => {
        navigate(
            `/shop/checkout?${queryString.stringify({
                ...queryString.parse(location.search),
                step: newStep,
            })}`,
            { replace }
        );
    };

    const handleSubmit = async ({ stripe, elements }) => {
        try {
            const { data: checkoutComplete } = await makePostRequest(
                CHECKOUT_COMPLETE(basketId),
                {
                    // shippingCode: shipping?.serviceCode,
                    // shippingName: shipping?.serviceName,
                    // shippingPrice: (shipping?.shipmentCost ?? 0 + shipping?.otherCost ?? 0) * 100,
                }
            );

            const result = await stripe.confirmCardPayment(checkoutComplete.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: addresses?.billing?.sameAsShipping
                        ? {
                              address: {
                                  city: addresses?.shipping.city,
                                  country: addresses?.shipping.country,
                                  line1: addresses?.shipping.addressOne,
                                  line2: addresses?.shipping.addressTwo,
                                  postal_code: addresses?.shipping.zip,
                                  state: addresses?.shipping.state,
                              },
                              email: addresses?.shipping.email,
                              name: addresses?.shipping.name,
                          }
                        : {
                              address: {
                                  city: addresses?.billing?.city,
                                  country: addresses?.billing?.country,
                                  line1: addresses?.billing?.addressOne,
                                  line2: addresses?.billing?.addressTwo,
                                  postal_code: addresses?.billing?.zip,
                                  state: addresses?.billing?.state,
                              },
                              email: addresses?.billing?.email,
                              name: addresses?.billing?.name,
                          },
                },
            });

            // if (process.env.NODE_ENV === 'production') {
            //     ReactPixel.track('Purchase', {
            //         content_type: 'product',
            //         content_ids: basketProducts.map(item => item.id),
            //         currency: currency.code,
            //         num_items: basketProducts.length,
            //         value: parseFloat(subtotal),
            //     });
            // }

            if (result.error) {
                setCardError(result.error);
                return;
            } else {
                setOrder({
                    email: addresses?.billing?.sameAsShipping
                        ? addresses?.shipping?.email
                        : addresses?.billing?.email,
                    subtotal,
                    // shipping: { ...shipping },
                    addresses: { ...addresses },
                    total: result.paymentIntent.amount,
                    created: result.paymentIntent.created,
                    countries,
                    products: [...basketProducts],
                    currency,
                });
            }

            emptyBasket();
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred processing your order.');
        }
    };

    return (
        <Layout>
            <SEO
                title="Checkout Shop | Suffolk Weekend Breaks | Retreat East"
                description="Visit our online shop checkout to purchase a range of items and gift vouchers for weekend breaks in Suffolk."
            />
            <Grid>
                {order && <Order {...order} />}

                {!order && (
                    <Row>
                        <Col xs={12} lg={8} first="lg">
                            <Steps current={currentStep}>
                                <Steps.Step name="address" title="Address" />
                                {/*<Steps.Step name="shipping" title="shipping" />*/}
                                <Steps.Step name="payment" title="Payment" complete={!!order} />
                            </Steps>

                            {!order && currentStep === 'address' && (
                                <Address
                                    onPrevious={handlePrevStep}
                                    onContinue={handleNextStep}
                                    {...checkoutProps}
                                />
                            )}
                            {/*{!order && currentStep === 'shipping' && (*/}
                            {/*    <Shipping*/}
                            {/*        onPrevious={handlePrevStep}*/}
                            {/*        onContinue={handleNextStep}*/}
                            {/*        {...checkoutProps}*/}
                            {/*    />*/}
                            {/*)}*/}
                            {!order && currentStep === 'payment' && (
                                <Payment
                                    onPrevious={handlePrevStep}
                                    onSubmit={handleNextStep}
                                    {...checkoutProps}
                                />
                            )}
                        </Col>
                        <Col xs={12} lg={4} first="xs">
                            <Overview {...checkoutProps} />
                        </Col>
                    </Row>
                )}
            </Grid>
        </Layout>
    );
};

export default connect(
    ({ basket }, ownProps) => {
        const params = queryString.parse(ownProps.location.search);

        return {
            basket: basket || {},
            currentStep: params.step,
            addresses: readLocalStorage('checkout_addresses'),
        };
    },
    dispatch => bindActionCreators({ emptyBasket }, dispatch)
)(Checkout);

export const query = graphql`
    query {
        merlinWebsite {
            store {
                name
                description
                currency {
                    symbol
                    code
                }
                stripePublishableKey
            }
        }
        merlinStore: allMerlinStoreProduct {
            products: nodes {
                _id
                name
                price
                shortName
                images {
                    image: url {
                        childImageSharp {
                            fluid(maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                description
            }
        }
    }
`;
