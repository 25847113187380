import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import { Divider } from '@components/Grid';
import Hero from '@components/Hero';
import Button from '@components/Button';
import Slider from '@components/Slider';
import CallToActions from '@components/CallToActions';
import Card from '@components/Card';
import Modal from '@components/Modal';
import SEO from '@components/SEO';
import CustomMap from '@components/CustomMap';
import Accordion from "@components/Accordion";
import {Container} from "@mui/material";

const Explore = ({
    data: {
        page: {
            frontmatter: {
                heroTitle,
                heroDescription,
                heroImage,
                heroButtonText,
                heroButtonLink,
                items,
                callToActions,
                interactiveMap,
                faqs,
            },
        },
    },
}) => {
    const [openItem, setOpenItem] = useState(null);

    return (
        <Layout>
            <SEO
                title="Explore | Suffolk Weekend Breaks | Retreat East"
                description="Explore the activities available at Retreat East for you to enjoy whilst on your Suffolk weekend break."
            />
            <Hero image={heroImage} imageFullWidth>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
                {!!heroButtonLink && !!heroButtonText && (
                    <Button link={heroButtonLink}>{heroButtonText}</Button>
                )}
            </Hero>

            <Divider />

            <Slider>
                {items.map(({ image, title, description }, index) => (
                    <Slider.Item key={`${title}_${index}`}>
                        <Card onClick={() => setOpenItem({ image, title, description })}>
                            <Card.Image height={200} fluid={image.childImageSharp.fluid} />
                            <Card.Title multiLine>{title}</Card.Title>
                            <Card.Content>
                                <p>
                                    {description.length > 115
                                        ? `${description
                                              .substr(0, 115)
                                              .replace(/<br\s*[\/]?>/gi, '')}...`
                                        : description.replace(/<br\s*[\/]?>/gi, '')}
                                </p>
                            </Card.Content>
                        </Card>
                    </Slider.Item>
                ))}
            </Slider>

            <Divider margin={5} />

            <CustomMap
                map={interactiveMap[0].image.childImageSharp}
                compass={interactiveMap[1].compass.childImageSharp}
            />

            <Divider margin={5}/>

            <Container>
            {!!faqs && <h2>Commonly asked questions</h2>}
            {!!faqs &&
                faqs.map(({ question, answer }, index) => {
                    return <Accordion key={index} title={question} text={answer} />;
                })}

            </Container>
            <Divider margin={5}/>


            {callToActions && (
                <CallToActions>
                    {callToActions.map(callToAction => (
                        <CallToActions.CallToAction key={callToAction.title} {...callToAction} />
                    ))}
                </CallToActions>
            )}

            <Modal isOpen={openItem} onClose={() => setOpenItem(null)} width={700}>
                <Modal.Image image={openItem?.image} />
                <Modal.Title>{openItem?.title}</Modal.Title>
                <Modal.Content truncateHtml html={openItem?.description} />
            </Modal>
        </Layout>
    );
};

export default Explore;

export const query = () => graphql`
    query ExploreQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                heroTitle: explore_hero_title
                heroDescription: explore_hero_description
                heroImage: explore_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroButtonText: explore_hero_button_text
                heroButtonLink: explore_hero_button_link
                items: explore_items {
                    title: explore_items_title
                    description: explore_items_description
                    image: explore_items_image {
                        childImageSharp {
                            fluid(maxWidth: 430) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }

                interactiveMap: explore_interactive_map {
                    image: explore_interactive_map_image {
                        childImageSharp {
                            fluid(maxHeight: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    compass: explore_interactive_map_compass {
                        childImageSharp {
                            fluid(maxHeight: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                
                faqs: explore_faqs {
                    question: explore_faq_title
                    answer: explore_faq_answer
                }

                callToActions: explore_call_to_actions {
                    title: explore_call_to_actions_title
                    description: explore_call_to_actions_description
                    buttonText: explore_call_to_actions_button_text
                    buttonLink: explore_call_to_actions_button_link
                    image: explore_call_to_actions_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
