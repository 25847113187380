import React, { Fragment } from 'react';
import Img from 'gatsby-image';
import paragraphs from 'lines-to-paragraphs';

import { Divider, Grid } from '@components/Grid';
import { findByType } from '@helpers';

import styles from './styles.module.scss';

const Modal = ({ grid = true, isOpen = true, children, onClose, width, center = false }) => {
    const ModalGrid = ({ children }) =>
        grid ? <Grid noMargin>{children}</Grid> : <Fragment>{children}</Fragment>;

    return isOpen ? (
        <div
            className={`${styles.modal} ${center ? styles.modalCenter : ''}`}
            onClick={onClose && typeof onClose === 'function' ? () => onClose() : undefined}
        >
            <ModalGrid>
                <div
                    className={styles.modalCard}
                    onClick={e => e.stopPropagation()}
                    style={{ maxWidth: width }}
                >
                    {findByType(children, Image, true)}
                    {findByType(children, Title, true)}
                    {findByType(children, Content, true)}
                </div>
            </ModalGrid>
        </div>
    ) : null;
};

const Image = ({ image, height }) =>
    image && image.childImageSharp.fluid ? (
        <div className={styles.modalCardImage} style={{ height }}>
            <Img fluid={image.childImageSharp.fluid} />
        </div>
    ) : null;
const Title = ({ children }) => <h3 className={styles.modalCardTitle}>{`${children}`}</h3>;
const Content = ({ children, html, truncateHtml = false }) => {
    return (
        <div className={styles.modalCardContent}>
            {!!html && (
                <Fragment>
                    <div
                        dangerouslySetInnerHTML={
                            { __html: truncateHtml ? paragraphs(html) : html }
                        }
                    />
                    <Divider />
                </Fragment>
            )}
            {children}
        </div>
    );
};

Modal.Title = Title;
Title.displayName = 'Title';

Modal.Content = Content;
Content.displayName = 'Content';

Modal.Image = Image;
Image.displayName = 'Image';

export default Modal;
