import React from 'react';
import styles from './styles.module.scss';
import {graphql} from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import Card from '@components/Card';
import Button from '@components/Button';
import {Grid, Container} from '@mui/material';
import {Divider} from '@components/Grid';
import moment from "moment/moment";

const Blog = ({
	              data: {
		              page: {
			              frontmatter: {heroTitle, heroDescription, heroImage, blogPostsTitle},
		              },
		              blogPosts: {edges: posts},
	              },
              }) => {


	return (
		<Layout>
			<SEO
				title="Blog | Spa Retreat Suffolk | Retreat East"
				description="Read our blog for the lastest news and updates on spa retreats in suffolk, things to do, food and more."
			/>
			<Hero imageFullWidth image={heroImage}>
				<h1>{heroTitle}</h1>
				{!!heroDescription && <p>{heroDescription}</p>}
			</Hero>

			<Divider margin={4}/>

			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h2>{blogPostsTitle}</h2>
					</Grid>
					{!!posts?.length &&
						posts?.map(
							(
								{
									node: {
										frontmatter: {
											path,
											blogImage,
											blogTitle,
											blogDescription,
											blogPublishedDate,
										},
									},
								},
								index
							) => {
								return (
									<Grid item xs={12} md={6} lg={4} key={`${path}_${index}`}>
										<Card fullHeight>
											<Card.Image
												square
												fluid={blogImage?.childImageSharp?.fluid}
											/>
											<Card.Title lineWrap>
												<div style={{minHeight: '90px'}}>{blogTitle}
												</div>
											</Card.Title>

											<Card.Content className={styles.blogPostCardContent}>
												<p style={{flexGrow: '1'}}>
													<b>
														{moment(new Date(blogPublishedDate)).format("Do MMMM Y")}
													</b>
													<br/>
													{blogDescription?.length > 200
														? `${blogDescription?.slice(0, 200)}...`
														: blogDescription}
												</p>
												<Button
													link={path}
													primary
													className={styles.blogPostCardContentButton}
												>
													Read more
												</Button>
											</Card.Content>
										</Card>
									</Grid>
								);
							}
						)}
				</Grid>
			</Container>
			<Divider margin={4}/>
		</Layout>
	);
};

export default Blog;

export const query = graphql`
    query BlogsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: blog_hero_title
                heroDescription: blog_hero_description
                heroImage: blog_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                blogPostsTitle: blog_posts_title
            }
        }
        blogPosts: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/blog-posts/(?!.md$)/" } }
            sort: { order: DESC, fields: frontmatter___blog_post_published }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        blogTitle: blog_post_title
                        blogDescription: blog_post_description
                        blogPublishedDate: blog_post_published
                        blogImage: blog_post_cover_image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
