import React from 'react';
import { graphql } from 'gatsby';
import Hero from '@components/Hero';
import { Grid, Row, Col } from '@components/Grid';
import Layout from '@components/Layout';
import Accordion from '@components/Accordion';
import SEO from "@components/SEO";

const WeddingsFaqs = ({
    data: {
        page: {
            frontmatter: { title, items }
        }
    }
}) => {

    return (
        <Layout>
            <SEO
                title="FAQs | Weddings | Wedding Venues Suffolk | Retreat East"
                description="Visit our FAQ page for the answers to questions about all things wedding at Retreat East. If you can't find the answer you are looking for, contact our team."
            />
            <Hero>
                <h1>{title}</h1>
            </Hero>
            <Grid>
                <Row>
                    <Col xs={12}>
                        {!!items?.length && items?.map(({question, answer}, index) => (
                            <Accordion key={`${question}_${index}`} title={question} text={answer}/>
                        ) )}
                    </Col>
                </Row>
            </Grid>
        </Layout>
    )
}

export default WeddingsFaqs;

export const query = graphql`
    query WeddingsFaqsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: weddings_faqs_title
                items: weddings_faqs_items {
                    question: weddings_faqs_items_question
                    answer: weddings_faqs_items_answer
                }
            }
        }
    }
`;
