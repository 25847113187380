import React from 'react';
import Img from 'gatsby-image';

import styles from './styles.module.scss';
import Button from '@components/Button';
import { Col, Grid, Row } from '../Grid';
import { findByType } from '../../helpers';

const CallToActions = ({ children }) => <div>{findByType(children, CallToAction)}</div>;

const CallToAction = ({ title, description, button = {}, buttonText, buttonLink, image }) => {
    return (
        <div className={styles.callToAction}>
            <Grid className={styles.callToActionContainer}>
                <div className={styles.callToActionWrapper}>
                    <div className={styles.callToActionImageWrapper}>
                        {!!image && (
                            <div className={styles.callToActionImage}>
                                <Img
                                    className={styles.callToActionImageGatsbyImageWrapper}
                                    fluid={image.childImageSharp.fluid}
                                    alt={`${title} call to action image`}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.callToActionContentWrapper}>
                        <div className={styles.callToActionContent}>
                            <h3>{title}</h3>
                            <p>{description}</p>
                            {((button && button.link && button.text) ||
                                (buttonText && buttonLink)) && (
                                <Button inverse link={(button && button.link) || buttonLink} className={styles.callToActionContentImage}>
                                    {(button && button.text) || buttonText}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    );
};

CallToActions.CallToAction = CallToAction;
CallToAction.displayName = 'CallToAction';

export default CallToActions;
