import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import { findByType, filterInternalChildren } from '@helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Position from './Position';

import styles from './styles.module.scss';

const List = ({ children, title, divider = true, className = '', loading }) => {
    return (
        <ul className={`${styles.cardList} ${divider ? styles.cardListDivider : ''} ${className}`}>
            {title && <li className={styles.cardListTitle}>{title}</li>}
            {loading
                ? Array(parseInt(loading) || 10)
                      .fill()
                      .map((x, i) => i)
                      .map(i => findByType(children, Item, false, { loading }))
                : findByType(children, Item, false, { loading })}
        </ul>
    );
};

const Item = ({
    label,
    value,
    bold,
    title,
    description,
    icon,
    children,
    hoverable,
    onClick,
    className = '',
    loading,
}) => {
    const cardTopLeft = findByType(children, Position.Top.Left, true, { loading });
    const cardTopRight = findByType(children, Position.Top.Right, true, { loading });
    const cardBottomLeft = findByType(children, Position.Bottom.Left, true, { loading });
    const cardBottomRight = findByType(children, Position.Bottom.Right, true, { loading });
    const content = findByType(children, Content, true);
    const renderValue = () => {
        if (value === undefined) return value;

        if (value === true) {
            return <FontAwesomeIcon icon="check" />;
        }

        if (value === false) {
            return <FontAwesomeIcon icon="times" />;
        }

        return value;
    };

    return (
        <li
            onClick={onClick}
            className={`${styles.cardListItem} ${bold ? styles.cardListItemBold : ''} ${
                hoverable || onClick ? styles.cardListItemHoverable : ''
            } ${loading ? styles.cardListItemLoading : ''} ${className}`}
        >
            {findByType(children, Avatar, true)}
            {!!icon && (
                <div className={styles.cardListItemIcon}>
                    <FontAwesomeIcon icon={icon} />
                </div>
            )}
            <div className={styles.cardListItemContentWrapper}>
                {cardTopLeft || cardTopRight ? (
                    <div className={`${styles.cardPositionEnd}`}>
                        {cardTopLeft} {cardTopRight}
                    </div>
                ) : null}

                <div className={styles.cardListItemContent}>
                    <div className={styles.cardListItemContentOverview}>
                        {!title && label && (value !== undefined || content) && (
                            <ul>
                                <li>{label}</li>
                                <li
                                    className={`${
                                        value === true ? styles.cardListItemListItemSuccess : ''
                                    } ${value === false ? styles.cardListItemListItemDanger : ''}`}
                                >
                                    {renderValue() || content}
                                </li>
                            </ul>
                        )}
                        {!title && !label && value && <p>{value}</p>}

                        {!label && !value && title && (
                            <p className={styles.cardListItemTitle}>{title}</p>
                        )}
                        {!label && !value && description && (
                            <p className={styles.cardListItemDescription}>
                                {loading ? (
                                    <Fragment>
                                        <span />
                                        <span />
                                    </Fragment>
                                ) : (
                                    description
                                )}
                            </p>
                        )}

                        {!label && content}
                    </div>

                    {findByType(children, Action, true, { loading })}
                </div>

                {cardBottomLeft || cardBottomRight ? (
                    <div className={`${styles.cardPositionEnd}`}>
                        {cardBottomLeft} {cardBottomRight}
                    </div>
                ) : null}
            </div>
        </li>
    );
};

const Content = ({ children }) => (
    <div className={styles.cardListItemContentChildren}>{children}</div>
);

const Avatar = ({ src, small = false, size = null }) => (
    <div
        className={`${styles.cardListItemAvatar} ${small ? styles.cardListItemAvatarSmall : ''}`}
        style={{
            backgroundImage: src && `url(${src})`,
            width: size,
            height: size,
        }}
    />
);

const Action = ({ onClick, link, icon, loading }) => {
    return link ? (
        <Link
            to={link}
            className={`${styles.cardListItemAction} ${
                onClick || link ? styles.cardListItemActionHoverable : ''
            }`}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={['fad', icon || '']} />
        </Link>
    ) : (
        <div
            className={`${styles.cardListItemAction} ${
                onClick || link ? styles.cardListItemActionHoverable : ''
            } ${loading ? styles.cardListItemActionLoading : ''}`}
            onClick={onClick}
        >
            {!loading && <FontAwesomeIcon icon={['fad', icon || '']} />}
        </div>
    );
};

List.displayName = 'List';
Item.displayName = 'Item';
Action.displayName = 'Action';
Avatar.displayName = 'Avatar';
Content.displayName = 'Content';

List.Item = Item;
List.Item.Position = Position;
List.Item.Action = Action;
List.Item.Avatar = Avatar;
List.Item.Content = Content;

export default List;
