import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/Layout';
import Hero from '@components/Hero';
import CallToActions from '@components/CallToActions';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import useScript from '@helpers/hooks/useScript';
import SEO from '@components/SEO';
import Accordion from '@components/Accordion';

const Events = ({
    data: {
        page: {
            frontmatter: {
                path,
                heroTitle,
                heroDescription,
                heroSubtitle,
                heroImage,
                email,
                items,
                callToActions,
                faqs,
            },
        },
    },
}) => {
    useScript('/resdiary-booking.js');

    // fix/events-build-error

    return (
        <Layout>
            <SEO
                title="Events | Retreat East | Suffolk Weekend Breaks"
                description="Check out the events at Retreat East and around our local area. You'll find something to suit everyone."
            />
            <Hero image={heroImage} imageFullWidth>
                {heroSubtitle && <h2>{heroSubtitle}</h2>}
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>

                <div id="rd-widget-frame" style={{ maxWidth: 600, margin: 'auto' }} />
                <input
                    id="rdwidgeturl"
                    name="rdwidgeturl"
                    value="https://booking.resdiary.com/widget/Standard/RetreatEast/40820?includeJquery=false"
                    type="hidden"
                />
            </Hero>

            <Grid>
                <Row>
                    {items.map(({ title, description, image, button }, index) => (
                        <Col key={`${title}-${index}`} xs={12} className={styles.item}>
                            <div>
                                <Row middle="xs" reverse={index % 2 !== 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.itemContent}>
                                            <h2>{title}</h2>
                                            <p>{description}</p>
                                            {button && button.text && (
                                                <Button
                                                    link={
                                                        button.link ||
                                                        `mailto:${email}?subject=Events - Enquiry for ${title}`
                                                    }
                                                >
                                                    {button.text}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Img
                                            style={{ maxHeight: 270 }}
                                            className={styles.itemImage}
                                            fluid={image?.childImageSharp?.fluid}
                                            alt={`Event image ${index + 1}`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Grid>

            {!!faqs?.length &&
                faqs.map(({ question, answer }, index) => (
                    <Grid>
                        <Accordion key={`${question}_${index}`} title={question} text={answer} />
                    </Grid>
                ))}

            {callToActions && (
                <CallToActions>
                    {callToActions.map(callToAction => (
                        <CallToActions.CallToAction key={callToAction.title} {...callToAction} />
                    ))}
                </CallToActions>
            )}
        </Layout>
    );
};

export default Events;

export const query = graphql`
    query EventsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                email: events_email
                heroSubtitle: events_hero_subtitle
                heroTitle: events_hero_title
                heroDescription: events_hero_description
                heroImage: events_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                faqs: events_faqs_items {
                    question: events_faqs_items_question
                    answer: events_faqs_items_answer
                }
                events: events_faqs_items {
                    question: events_faqs_items_question
                    answer: events_faqs_items_answer
                }
                items: events_items {
                    title: events_items_title
                    description: events_items_description
                    buttonText: events_items_button_text
                    buttonLink: events_items_button_link
                    image: events_items_image {
                        childImageSharp {
                            fluid(maxWidth: 555) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                callToActions: events_call_to_actions {
                    title: events_call_to_actions_title
                    description: events_call_to_actions_description
                    buttonText: events_call_to_actions_button_text
                    buttonLink: events_call_to_actions_button_link
                    image: events_call_to_actions_image {
                        childImageSharp {
                            fluid(maxHeight: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
