import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextareaAutosize from 'react-textarea-autosize';

import styles from './styles.module.scss';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleIconClick = this.handleIconClick.bind(this);
        this.inputWrapper = React.createRef();
        this.input = this.props.forwardedRef || React.createRef();
        this.state = {
            focused: false,
            flipPosition: false,
        };
    }

    handleKeyDown(e) {
        const keyCode = e.which || e.keyCode;

        if (keyCode === 13 && this.props.onEnter) {
            this.props.onEnter(e);
        }

        if (keyCode === 27 && this.props.onEscape) {
            this.props.onEscape(e);
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.focused !== this.props.focused) {
            this.setState({ focused: this.props.focused });
        }
    }

    handleChange(e) {
        if (e.target.value) {
            if (this.props.number && !/^\d*(\.\d{0,2})?$/.test(e.target.value)) {
                e.preventDefault();
                e.stopPropagation();
                return;
            }

            if (typeof this.props.number === 'object' && this.props.number !== null) {
                if (
                    typeof this.props.number.min === 'number' &&
                    parseInt(e.target.value) < this.props.number.min
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (
                    typeof this.props.number.max === 'number' &&
                    parseInt(e.target.value) > this.props.number.max
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }
        }

        if (this.props.onChange) {
            this.props.onChange(e, e.target.value);
        }
    }

    handleIconClick(e) {
        if (this.props.onIconClick) {
            e.preventDefault();

            this.props.onIconClick(e);
        }
    }

    render() {
        const icon = this.props.icon ? (
            <FontAwesomeIcon
                icon={this.props.icon}
                className={this.props.onIconClick ? styles['input-icon-clickable'] : ''}
                onMouseDown={this.handleIconClick}
            />
        ) : null;

        const iconClass = this.props.icon ? styles.inputHasIcon : '';
        const noBorderClass = this.props.border === false ? styles.inputNoBorder : '';
        const blockClass = !this.props.autoWidth ? styles.inputBlock : '';
        const pointerClass = this.props.pointer ? styles.inputPointer : '';
        const focusedClass = this.state.focused ? styles.inputFocused : '';
        const labelClass = this.props.label ? styles.inputHasLabel : '';
        const inlineClass = this.props.inline ? styles.inputInline : '';
        const minimalClass = this.props.minimal ? styles.inputMinimal : '';
        const noMarginBottomClass = this.props.noMarginBottom ? styles.inputNoMarginBottom : '';
        const iconBeforeClass = this.props.iconBefore ? styles.inputIconBefore : '';
        const textareaClass = this.props.textarea ? styles.inputTextarea : '';

        return (
            <div
                ref={this.inputWrapper}
                className={`${styles.input} ${iconBeforeClass} ${noMarginBottomClass} ${minimalClass} ${inlineClass} ${labelClass} ${focusedClass} ${pointerClass} ${blockClass} ${iconClass} ${noBorderClass} ${textareaClass}`}
            >
                {this.props.label && (
                    <div className={styles.inputLabel}>
                        <label
                            className={`${this.props.required ? styles.inputLabelRequired : ''}`}
                            onClick={() => this.input.current.focus()}
                        >
                            {this.props.label}
                            {this.props.hint ? (
                                <span
                                    className={
                                        this.props.hintBlock ? styles.inputhintBlock : ''
                                    }
                                >
                                    {this.props.hint}
                                </span>
                            ) : null}
                        </label>
                    </div>
                )}

                <div className={styles.inputHolder} style={{ minWidth: this.props.minWidth }}>
                    {this.props.textarea ? (
                        <TextareaAutosize
                            inputRef={this.input}
                            type={this.props.type}
                            autoComplete="off"
                            disabled={this.props.disabled}
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={this.props.placeholder}
                            onClick={this.props.onClick}
                            rows={this.props.rows}
                        />
                    ) : (
                        <input
                            ref={this.input}
                            type={this.props.type}
                            autoComplete="off"
                            disabled={this.props.disabled}
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={this.props.placeholder}
                            onClick={this.props.onClick}
                        />
                    )}

                    {icon}
                </div>
            </div>
        );
    }
}

Input.defaultProps = {
    value: '',
    type: 'text',
    fullWidth: true,
};

Input.propTypes = {
    noMarginBottom: PropTypes.bool,
    type: PropTypes.string,
    minimal: PropTypes.bool,
    required: PropTypes.bool,
    inline: PropTypes.bool,
    focused: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fullWidth: PropTypes.bool,
    autoWidth: PropTypes.bool,
    pointer: PropTypes.bool,
    number: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
    ]),
    label: PropTypes.string,
    hint: PropTypes.string,
    hintBlock: PropTypes.bool,
    icon: PropTypes.string,
    iconBefore: PropTypes.bool,
    onIconClick: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    date: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    textarea: PropTypes.bool,
};

export default React.forwardRef((props, ref) => <Input forwardedRef={ref} {...props} />);
