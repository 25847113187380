import React from 'react';
import {
    Grid,
    DialogContent,
    Dialog,
    DialogTitle,
    IconButton,
    Stack,
    Box,
    CardActionArea,
} from '@mui/material';
import { Link } from 'gatsby';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BookDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: styles.bookDialog,
                paper: styles.bookDialogPaper,
            }}
            fullWidth
            maxWidth="lg"
        >
            <DialogContent className={styles.bookDialogContent}>
                <h2 className={styles.bookDialogTitle}>
                    <span className={styles.bookDialogTitleText}>Book Now</span>
                </h2>
                <p className={styles.bookDialogDescription}>
                    We offer a range of accomodations, activities and events for you to book while
                    your with us at Retreat East. If there are custom requests please{' '}
                    <a href="mailto:reception@retreateast.co.uk">
                        <b>contact us</b>
                    </a>
                    .
                </p>

                <Grid container sx={{ mt: 1 }} spacing={4}>
                    <Grid item xs={12} md={6}>
                        <CardActionArea
                            component="a"
                            href="?book=true"
                            rel="noopener noreferrer"
                            sx={{ borderRadius: 2, p: 1 }}
                        >
                            <img alt="Stay" src="/images/book-assets/book-stay.png" />

                            <Stack direction="row" alignItems="center" sx={{ pt: 2 }} spacing={2}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <p>
                                        <b>Stay</b>
                                    </p>
                                    <p>
                                        Stay in one of our beautiful accommodations on the heart of
                                        Suffolk.
                                    </p>
                                </Stack>
                                <Box sx={{ flex: '0 0 auto' }}>
                                    <IconButton className={styles.bookDialogIconButton}>
                                        <FontAwesomeIcon
                                            className={styles.bookDialogIconButtonIcon}
                                            icon={['fal', 'book-arrow-right']}
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardActionArea
                            component={Link}
                            to="/offers"
                            sx={{ borderRadius: 2, p: 1 }}
                        >
                            <img alt="Offers" src="/images/book-assets/book-offers.png" />

                            <Stack direction="row" alignItems="center" sx={{ pt: 2 }} spacing={2}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <p>
                                        <b>Offers</b>
                                    </p>
                                    <p>
                                        View our recommended packages per person for an extra
                                        special experience.
                                    </p>
                                </Stack>
                                <Box sx={{ flex: '0 0 auto' }}>
                                    <IconButton className={styles.bookDialogIconButton}>
                                        <FontAwesomeIcon
                                            className={styles.bookDialogIconButtonIcon}
                                            icon={['fal', 'book-arrow-right']}
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CardActionArea component={Link} to="/spa" sx={{ borderRadius: 2, p: 1 }}>
                            <img alt="Spa" src="/images/book-assets/book-spa.png" />

                            <Stack direction="row" alignItems="center" sx={{ pt: 2 }} spacing={2}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <p>
                                        <b>Spa</b>
                                    </p>
                                    <p>
                                        Book treatments, spa days, packages and unwind and relax in
                                        style.
                                    </p>
                                </Stack>
                                <Box sx={{ flex: '0 0 auto' }}>
                                    <IconButton className={styles.bookDialogIconButton}>
                                        <FontAwesomeIcon
                                            className={styles.bookDialogIconButtonIcon}
                                            icon={['fal', 'book-arrow-right']}
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardActionArea component={Link} to="/dine" sx={{ borderRadius: 2, p: 1 }}>
                            <img alt="Dine" src="/images/book-assets/book-dine.png" />

                            <Stack direction="row" alignItems="center" sx={{ pt: 2 }} spacing={2}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <p>
                                        <b>Dine</b>
                                    </p>
                                    <p>
                                        Eat at our Great Barn a range of meals made with local foods
                                        by our chefs.
                                    </p>
                                </Stack>
                                <Box sx={{ flex: '0 0 auto' }}>
                                    <IconButton className={styles.bookDialogIconButton}>
                                        <FontAwesomeIcon
                                            className={styles.bookDialogIconButtonIcon}
                                            icon={['fal', 'book-arrow-right']}
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardActionArea
                            component={Link}
                            to="/weddings"
                            sx={{ borderRadius: 2, p: 1 }}
                        >
                            <img alt="Weddings" src="/images/book-assets/book-weddings.png" />

                            <Stack direction="row" alignItems="center" sx={{ pt: 2 }} spacing={2}>
                                <Stack sx={{ flexGrow: 1 }}>
                                    <p>
                                        <b>Weddings</b>
                                    </p>
                                    <p>
                                        Book your perfect wedding and create memories at our
                                        stunning venue.
                                    </p>
                                </Stack>
                                <Box sx={{ flex: '0 0 auto' }}>
                                    <IconButton className={styles.bookDialogIconButton}>
                                        <FontAwesomeIcon
                                            className={styles.bookDialogIconButtonIcon}
                                            icon={['fal', 'book-arrow-right']}
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </CardActionArea>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default BookDialog;
