import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accordion = ({ title, text }) => {
    const [height, setHeight] = useState(0);

    return (
        <div
            tabIndex={0}
            className={`${styles.accordion}`}
            onClick={() => setHeight(height === 0 ? 'auto' : 0)}
        >
            <div className={styles.accordionTitle}>
                {title}

                <div className={styles.accordionTitleIcon}>
                    <FontAwesomeIcon icon={height !== 0 ? 'minus' : 'plus'} />
                </div>
            </div>
            <AnimateHeight
                duration={300}
                height={height} // see props documentation below
            >
                <div
                    className={styles.accordionContent}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </AnimateHeight>
        </div>
    );
};

export default Accordion;
