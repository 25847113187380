import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import { Grid, Row, Col } from '@components/Grid';
import Card from '@components/Card';
import Breadcrumbs from '@components/Breadcrumbs';

import styles from './styles.module.scss';
import { Divider } from '../../components/Grid';
import SEO from '@components/SEO';
import Button from '@components/Button';

const FoodMenu = ({ data }) => {
    const { page } = data;
    const { frontmatter } = page;
    const {
        path,
        name,
        description,
        allergen,
        times,
        menus,
        image,
        startingPrice,
        optionsPerRound,
        viewText,
        viewButtonText,
        viewButtonLink,
        viewContactText,
        viewContactLink,
        seoTitle,
        seoDescription,
    } = frontmatter;

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <div className={styles.menu}>
                <Hero className={styles.hero} image={image}>
                    <h1>{`${name}`}</h1>
                    <p>{description}</p>
                    <Divider margin={3} />
                    <Row>
                        <Col xs={12}>
                            {(!!startingPrice || !!optionsPerRound) && (
                                <ul className={styles.menuCounter}>
                                    {!!startingPrice && (
                                        <li>
                                            <p>£{startingPrice}</p>
                                            <Divider margin={1} />
                                            <p>per person</p>
                                            <Divider margin={1} />
                                        </li>
                                    )}
                                    {!!optionsPerRound && (
                                        <li>
                                            <p>{optionsPerRound}</p>
                                            <Divider margin={1} />
                                            <p>options per round</p>
                                            <Divider margin={1} />
                                        </li>
                                    )}
                                </ul>
                            )}
                        </Col>
                    </Row>
                </Hero>

                <Grid>
                    {(times || allergen) && (
                        <Fragment>
                            <Row>
                                {times && (
                                    <Col xs={12} md={6}>
                                        <Card>
                                            <Card.Title icon="clock">Times</Card.Title>
                                            <Card.Content>
                                                <p>{times}</p>
                                            </Card.Content>
                                        </Card>
                                    </Col>
                                )}

                                {allergen && (
                                    <Col xs={12} md={6}>
                                        <Card>
                                            <Card.Title icon="allergies">Allergens</Card.Title>
                                            <Card.Content>
                                                <p>{allergen}</p>
                                            </Card.Content>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                            <Divider />
                        </Fragment>
                    )}

                    <div className={styles.menus}>
                        {menus.map(({ name, sections }, index) => (
                            <Row className={styles.menusMenu} key={index}>
                                <Col xs>
                                    {name && (
                                        <div className={styles.menusMenuTitle}>
                                            <h2>{name}</h2>
                                        </div>
                                    )}
                                    {sections &&
                                        Array.isArray(sections) &&
                                        sections.map(({ name, description, items }, index) => (
                                            <div className={styles.menusMenuSection} key={index}>
                                                {name ? (
                                                    <div className={styles.menusMenuSectionTitle}>
                                                        <h3>{name}</h3>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`${styles.menusMenuSectionTitle} ${styles.menusMenuSectionTitleEmpty}`}
                                                    />
                                                )}
                                                {description && (
                                                    <p
                                                        className={
                                                            styles.menusMenuSectionDescription
                                                        }
                                                    >
                                                        {description}
                                                    </p>
                                                )}
                                                {items &&
                                                    Array.isArray(items) &&
                                                    items.map(
                                                        (
                                                            { name, description, prices = [] },
                                                            index
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    styles.menusMenuSectionItem
                                                                }
                                                            >
                                                                {name && (
                                                                    <div
                                                                        className={
                                                                            styles.menusMenuSectionItemName
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={
                                                                                styles.menusMenuSectionItemNameTitle
                                                                            }
                                                                        >
                                                                            {name}
                                                                        </p>
                                                                        {description && (
                                                                            <p
                                                                                className={
                                                                                    styles.menusMenuSectionItemNameDescription
                                                                                }
                                                                            >
                                                                                {description}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                <span
                                                                    className={
                                                                        styles.menusMenuSectionItemDivider
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        ))}
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <Divider margin={4} />
                    <Row>
                        <Col xs={12}>
                            <div className={styles.heroBottom}>
                                <p>{viewText}</p>
                                <Divider margin={3} />
                                <Button link={viewButtonLink}>{viewButtonText}</Button>
                                <Divider margin={3} />
                                <Button link={viewContactLink}>{viewContactText}</Button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </Layout>
    );
};

export default FoodMenu;

export const query = () => graphql`
    query FoodMenuQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                name: weddings_food_and_drink_name
                seoTitle: weddings_food_and_drink_seo_title
                seoDescription: weddings_food_and_drink_seo_description
                description: weddings_food_and_drink_overview_text
                allergen: weddings_food_and_drink_allergen
                times: weddings_food_and_drink_times
                startingPrice: weddings_food_and_drink_starting_price
                optionsPerRound: weddings_food_and_drink_options_per_round
                image: weddings_food_and_drink_image {
                    childImageSharp {
                        fluid(maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                viewText: weddings_food_and_drink_view_text
                viewButtonText: weddings_food_and_drink_view_button_text
                viewButtonLink: weddings_food_and_drink_view_button_link
                viewContactText: weddings_food_and_drink_view_contact_text
                viewContactLink: weddings_food_and_drink_view_contact_link
                menus: weddings_food_and_drink_menus {
                    name: weddings_food_and_drink_menus_name
                    sections: weddings_food_and_drink_menus_sections {
                        name: weddings_food_and_drink_menus_sections_name
                        description: weddings_food_and_drink_menus_sections_description
                        items: weddings_food_and_drink_menus_sections_items {
                            name: weddings_food_and_drink_menus_sections_items_name
                            description: weddings_food_and_drink_menus_sections_items_description
                        }
                    }
                }
            }
        }
    }
`;
