import React, { Fragment } from 'react';
import { graphql, navigate } from "gatsby";

import { Grid, Col, Row, Divider } from '@components/Grid';
import ImageSlider from '@components/ImageSlider';
import Button from '@components/Button';
import { addToBasket, removeFromBasket, addBasket } from '@actions/basket';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleError } from '@helpers/errors';
import { getBasket } from '@helpers/store';
import { makePutRequest, makePostRequest } from '@helpers/requests';
import { BASKET_ADD, BASKET_CREATE } from '@helpers/api';
import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';
import { useSnackbar } from "@components/Snackbar";
import SEO from "@components/SEO";

const Product = ({
    data: {
        merlinWebsite: {
            websiteId,
            store: {
                currency: { symbol: currencySymbol },
            },
        },
        merlinStoreProduct: { _id: id, name, price, description, images },
        merlinStore: { products },
    },
    basket,
    addToBasket,
    removeFromBasket,
    addBasket,
}) => {
    const [openSnackbar] = useSnackbar();
    const basketProducts = getBasket(products, basket);
    const handleAddToBasket = async () => {
        try {
            addToBasket({ id, quantity: 1 });

            if (basket.id) {
                await makePutRequest(
                    BASKET_ADD(basket.id),
                    {
                        productId: id,
                        quantity:
                            ((basketProducts.find(item => item.id === id) || {}).quantity || 0) + 1,
                    },
                    { websiteId }
                );
            } else {
                const { data: basketData } = await makePostRequest(
                    BASKET_CREATE,
                    { productId: id, quantity: 1 },
                    { websiteId }
                );

                addBasket({ id: basketData._id });
            }
        } catch (error) {
            error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred adding this item to your basket.');
            removeFromBasket({ id, quantity: 1 });
        }
    };
    const handlePurchase = async () => {
        try {
            handleAddToBasket();
            navigate('/shop/basket');
        } catch (error) {
            error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred adding this item to your basket.');
        }
    };

    return (
        <Layout>
            <SEO title={`Product - ${name}`} />
            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb title="Shop" link="/shop" />
                <Breadcrumbs.Breadcrumb title={name}/>
            </Breadcrumbs>
            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        {!!images && !!images.length && (
                            <Fragment>
                                <ImageSlider>
                                    {images
                                        .filter(image => !!image && !!image.image)
                                        .map(({ image }, index) => (
                                            <ImageSlider.Item
                                                key={index}
                                                alt={name}
                                                fluid={image.childImageSharp.fluid}
                                            />
                                        ))}
                                </ImageSlider>
                                <Divider />
                            </Fragment>
                        )}
                    </Col>
                    <Col xs={12} md={6}>
                        <h1>{name}</h1>
                        {!!description && <p>{description}</p>}
                        <p>
                            <b>{`${currencySymbol}${parseFloat(price / 100).toFixed(2)}`}</b>
                        </p>
                        <Button primary text="Add to basket" onClick={() => handleAddToBasket()} />
                        <Button secondary text="Purchase" onClick={() => handlePurchase()} />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default connect(
    ({ basket }) => ({ basket: basket || {} }),
    dispatch => bindActionCreators({ addToBasket, removeFromBasket, addBasket }, dispatch)
)(Product);

export const query = graphql`
    query($id: String!) {
        merlinWebsite {
            websiteId
            store {
                currency {
                    symbol
                }
            }
        }
        merlinStoreProduct(_id: { eq: $id }) {
            _id
            name
            description
            price
            images {
                image: url {
                    childImageSharp {
                        fluid(maxHeight: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        merlinStore: allMerlinStoreProduct {
            products: nodes {
                _id
            }
        }
    }
`;
