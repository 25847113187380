import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import { Col, Grid, Row } from '@components/Grid';
import moment from 'moment';

const GhostPage = ({
    data: {
        page: {
            frontmatter: {
                ghostTitle,
                seoTitle,
                seoDescription,
                ghostCoverImage,
                ghostHeroImage,
                ghostAuthor,
                ghostPublished,
                ghostBody,
                ghostUrl,
            },
        },
    },
}) => {
    const embedUrl = ghostUrl ? ghostUrl?.replace('watch?v=', 'embed/') : '';

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <Hero imageFullWidth image={ghostHeroImage}>
                <h1>{ghostTitle}</h1>
            </Hero>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '2rem',
                }}
            >
                <img
                    height={400}
                    src={ghostCoverImage.childImageSharp.fluid.src}
                    alt={ghostTitle}
                />
            </div>
            <Grid>
                <Row>
                    {!!embedUrl && (
                        <Col xs={12}>
                            <iframe
                                style={{ aspectRatio: '16 / 9', marginBottom: '2rem' }}
                                width="100%"
                                src={embedUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </Col>
                    )}

                    <Col xs={12}>
                        <div className={styles.ghostContentWrapper}>
                            {!!ghostBody && (
                                <div
                                    style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                                    dangerouslySetInnerHTML={{ __html: ghostBody }}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default GhostPage;

export const query = () => graphql`
    query GhostPageQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                seoTitle: ghost_page_seo_title
                seoDescription: ghost_page_seo_description
                ghostBody: ghost_page_body
                ghostTitle: ghost_page_title
                ghostCoverImage: ghost_page_cover_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ghostHeroImage: ghost_page_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ghostTags: ghost_page_tags {
                    ghost_tag
                }
                ghostTagIds: ghost_page_tag_ids {
                    ghost_tag_id
                }
                ghostAuthor: ghost_page_author
                ghostPublished: ghost_page_published
            }
        }
    }
`;
