import React, { Fragment, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import Label from '../Label';

const Switch = ({
    label,
    checked,
    onChange,
    onClick,
    allowDisabledOnClick = false,
    hint,
    optional,
    inline,
    name,
    disabled,
}) => {
    const [fallbackName] = useState(uuidv4());
    const $input = useRef(null);

    return (
        <Label htmlFor={name || fallbackName} text={label} hint={hint} optional={optional}>
            <div
                className={`${styles.switch} ${inline ? styles.switchInline : ''} ${
                    disabled ? styles.switchDisabled : ''
                }`}
                onClick={(allowDisabledOnClick || !disabled) && !!onClick ? onClick : undefined}
            >
                <div
                    tabIndex={disabled ? -1 : 0}
                    className={`${styles.switchToggle} ${
                        checked ? styles.switchToggleChecked : ''
                    }`}
                    onClick={() => {
                        return !disabled && $input?.current?.click();
                    }}
                    onKeyDown={e => e.key === 'Enter' && $input?.current?.click()}
                />
                <input
                    ref={$input}
                    type="checkbox"
                    name={name || fallbackName}
                    checked={checked}
                    onChange={onChange ? e => onChange(e, !checked) : undefined}
                />
            </div>
        </Label>
    );
};

export default Switch;
