import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { Card, CardContent, CardMedia, Container, Grid as MuiGrid } from '@mui/material';
import Img from 'gatsby-image';

export const MeetTheTeam = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroDescription, heroImage, employees },
        },
    },
}) => {
    console.log(employees);

    return (
        <Layout>
            <SEO
                title="Meet The Team | Luxury Retreat UK | Retreat East"
                description="Learn more about the fantastic team the keep Retreat East running smoothly. From the kitchen to shop they make Retreat special."
            />
            <Hero image={heroImage} imageFullWidth imageStyle={{ objectPosition: '50% 100%' }}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>
            <Container>
                <MuiGrid container spacing={6} className={styles.employees}>
                    {employees?.length &&
                        employees
                            .filter(employee => employee.name)
                            .sort((a, b) => parseInt(a.order) - parseInt(b.order))
                            .map(
                                ({ name, description, image, role }, index) =>
                                    !!image && (
                                        <MuiGrid item xs={12} sm={6} md={4}>
                                            <Card className={styles.card}>
                                                <CardMedia className={styles.cardMedia}>
                                                    <Img
                                                        alt="employee-image"
                                                        fluid={image?.childImageSharp?.fluid}
                                                        className={styles.cardMediaImage}
                                                    />
                                                    <div className={styles.cardMediaInfo}>
                                                        <h4>{name}</h4>
                                                        <p>{role}</p>
                                                    </div>
                                                </CardMedia>
                                                <CardContent className={styles.cardContent}>
                                                    <p>{description}</p>
                                                </CardContent>
                                            </Card>
                                        </MuiGrid>
                                    )
                            )}
                </MuiGrid>
            </Container>
        </Layout>
    );
};

export default MeetTheTeam;

export const query = () => graphql`
    query MeetTheTeamQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                heroTitle: meet_the_team_hero_title
                heroImage: meet_the_team_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroDescription: meet_the_team_hero_description
                employees: employees {
                    name: employee_name
                    description: employee_description
                    order: employee_order
                    role: employee_role
                    image: employee_image {
                        childImageSharp {
                            fluid(maxWidth: 1920) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
