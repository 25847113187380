import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';
import Accordion from '@components/Accordion';
import CallToActions from '@components/CallToActions';
import Hero from '@components/Hero';
import ReactMapGL, { Marker } from 'react-map-gl';
import styles from './styles.module.scss';
import SEO from '@components/SEO';
import Features from '@components/Features';
import FsLightbox from 'fslightbox-react';
import Badge from '@components/Badge';
import { Stack } from '@mui/material';

const Accommodation = ({
    data: {
        barnData: {
            frontmatter: {
                stay_barn_room_title: title,
                stay_barn_room_seo_title: seoTitle,
                stay_barn_room_seo_description: seoDescription,
                stay_barn_room_category: category,
                stay_barn_room_description: description,
                stay_barn_room_weddings_accommodation_image: image,
                stay_barn_room_images: images,
                stay_barn_room_weddings_button_link: buttonLink,
                stay_barn_room_weddings_button_text: buttonText,
                stay_barn_room_guests: guests,
                stay_barn_room_bedrooms: bedRooms,
                stay_barn_room_bathrooms: bathRooms,
                stay_barn_room_weddings_accommodation_features: features,
                stay_barn_room_weddings_accommodation_plan_image: planImage,
                stay_barn_room_weddings_accommodation_view_text: viewText,
                stay_barn_room_weddings_accommodation_view_button_text: viewButtonText,
                stay_barn_room_weddings_accommodation_view_button_link: viewButtonLink,
                stay_barn_room_weddings_accommodation_view_contact_text: viewContactText,
                stay_barn_room_weddings_accommodation_view_contact_link: viewContactLink,
                stay_barn_room_weddings_accommodation_ceremony_ids: ceremonyIds,
            },
        },
        ceremonies: { edges: ceremonies },
    }
}) => {
    const filteredCeremonies = ceremonies
        ?.filter(({ node: { frontmatter: { id } } }) =>
            ceremonyIds.some(({ ceremony }) => id === ceremony)
        )
        ?.map(ceremony => ceremony?.node?.frontmatter);

    const onlyImages = images.map(image => {
        return image?.childImageSharp?.fluid?.src;
    });

    const [viewOpen, setViewOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = event => {
        setCurrentImage(event);
        setViewOpen(!viewOpen);
    };

    return (
        <Layout>
            <SEO title={`Weddings - ${seoTitle}`} description={`Weddings - ${seoDescription}`}/>
            <FsLightbox
                type="image"
                toggler={viewOpen}
                sources={onlyImages}
                slide={currentImage + 1}
            />
            <Hero
                images={images}
                imageWrapperStyle={{ maxHeight: 400 }}
                openLightBox={openLightbox}
            >
                <h1>{title}</h1>
                {filteredCeremonies?.map(({ id, title }) => (
                    <Badge key={id} text={title} />
                ))}
                <Divider margin={3} />
                <p>{description}</p>
                <Divider margin={3} />
                <Row>
                    <Col xs={12}>
                        <ul className={styles.barnCounter}>
                            <li>
                                <p>{guests}</p>
                                <Divider margin={1} />
                                <p>Guests</p>
                                <Divider margin={1} />
                            </li>
                            <li>
                                <p>{bedRooms}</p>
                                <Divider margin={1} />
                                <p>Bedrooms</p>
                                <Divider margin={1} />
                            </li>
                            <li>
                                <p>{bathRooms}</p>
                                <Divider margin={1} />
                                <p>Bathrooms</p>
                                <Divider margin={1} />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Divider margin={3} />
                <Button link={buttonLink}>{buttonText}</Button>
                <Divider xsMargin={3} mdMargin={0} />
            </Hero>

            <Col xs={12} noGutters>
                <div className={styles.middleHero}>
                    <Img className={styles.image} fluid={image?.childImageSharp?.fluid} />
                </div>
            </Col>

            <Features data={features} />

            <div className={styles.planHero}>
                {!!planImage && (
                    <Grid noMargin>
                        <Fragment>
                            <Divider margin={5} />
                            <Row>
                                <Col xs={12}>
                                    <Img
                                        fluid={planImage?.childImageSharp?.fluid}
                                        alt={`Accommodation image ${index + 1}`}
                                    />
                                </Col>
                            </Row>
                            <Divider margin={5} />
                        </Fragment>
                    </Grid>
                )}

                <ReactMapGL
                    mapStyle="mapbox://styles/mapbox/outdoors-v10?optimize=true"
                    mapboxApiAccessToken="pk.eyJ1IjoiYmlncml2YWwiLCJhIjoiY2phMzRsanUzMHV6ajJxbGZzdDZsamtqayJ9.PngeT-c1grgQoIMXMhkBUA"
                    zoom={13}
                    longitude={1.121334}
                    latitude={52.134348}
                    height={370}
                    width="100%"
                >
                    <Marker
                        longitude={1.121334}
                        latitude={52.134348}
                        offsetLeft={-19}
                        offsetTop={-8}
                    >
                        <FontAwesomeIcon className={styles.marker} icon="map-marker-alt" />
                    </Marker>
                </ReactMapGL>

                {(!!viewText ||
                    (!!viewButtonLink && !!viewButtonText) ||
                    (!!viewContactLink && !!viewContactText)) && (
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.heroBottom}>
                                    {!!viewText && (
                                        <Fragment>
                                            <p>{viewText}</p>
                                            <Divider margin={3} />
                                        </Fragment>
                                    )}
                                    {!!viewButtonLink && !!viewButtonText && (
                                        <Fragment>
                                            <Button link={viewButtonLink}>{viewButtonText}</Button>
                                            <Divider margin={3} />
                                        </Fragment>
                                    )}
                                    {!!viewContactLink && !!viewContactText && (
                                        <Button link={viewContactLink}>{viewContactText}</Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                )}
            </div>
        </Layout>
    );
};

export default Accommodation;

export const query = graphql`
    query AccommodationQuery($slug: String!) {
        barnData: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            frontmatter {
                slug
                stay_barn_room_title
                stay_barn_room_seo_title
                stay_barn_room_seo_description
                stay_barn_room_category
                stay_barn_room_description
                stay_barn_room_weddings_accommodation_image {
                    childImageSharp {
                        fluid(maxWidth: 3000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                stay_barn_room_guests
                stay_barn_room_bedrooms
                stay_barn_room_bathrooms
                stay_barn_room_weddings_accommodation_ceremony_ids {
                    ceremony: stay_barn_room_weddings_accommodation_ceremony_id
                }
                stay_barn_room_images {
                    childImageSharp {
                        fluid(maxWidth: 555) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                stay_barn_room_weddings_button_link
                stay_barn_room_weddings_button_text
                stay_barn_room_weddings_accommodation_features {
                    title: stay_barn_room_weddings_accommodation_feature_title
                    description: stay_barn_room_weddings_accommodation_feature_description
                    image: stay_barn_room_weddings_accommodation_feature_image {
                        childImageSharp {
                            fluid(maxWidth: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                stay_barn_room_weddings_accommodation_plan_image {
                    childImageSharp {
                        fluid(maxWidth: 3000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                stay_barn_room_weddings_accommodation_view_text
                stay_barn_room_weddings_accommodation_view_button_text
                stay_barn_room_weddings_accommodation_view_button_link
                stay_barn_room_weddings_accommodation_view_contact_text
                stay_barn_room_weddings_accommodation_view_contact_link
            }
        }
        ceremonies: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { fileAbsolutePath: { regex: "/data/ceremonies/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        title: ceremonies_ceremony_title
                        id: ceremonies_ceremony_id
                    }
                }
            }
        }
    }
`;
