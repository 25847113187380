import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/Layout';
import Hero from '@components/Hero';
import Button from '@components/Button';

import styles from './styles.module.scss';
import SEO from '@components/SEO';
import {
    Card,
    CardActionArea,
    CardHeader,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Stack,
    Box,
} from '@mui/material';
import Slider from '@components/Slider';
import Modal from '@components/Modal';

const Awards = ({
    data: {
        page: {
            frontmatter: {
                path,
                heroTitle,
                heroDescription,
                heroImage,
                awards,
                otherAwardsTitle,
                otherAwards,
                reviews,
            },
        },
    },
}) => {
    const [openItem, setOpenItem] = useState(null);

    return (
        <Layout>
            <SEO
                title="Awards | Retreats in UK | Retreat East"
                description="Learn more about the awards and recognition Retreat East have been awarded with."
            />
            <Hero image={heroImage} imageFullWidth imageAlt={heroTitle}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>

            <Container>
                <Stack spacing={3} sx={{ my: 6 }}>
                    {awards?.map(({ title, description, awardImage, link }) => (
                        <Card className={styles.awardItem}>
                            <CardActionArea>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    <Grid container>
                                        <Grid item xs={12} md={5}>
                                            <div>
                                                <Img
                                                    className={styles.awardImage}
                                                    fluid={awardImage?.childImageSharp?.fluid}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <Stack
                                                justifyContent="center"
                                                sx={{ p: 4, height: '100%' }}
                                                spacing={4}
                                            >
                                                <Stack>
                                                    <h2>{title}</h2>
                                                    <p>{description}</p>
                                                </Stack>

                                                {link && (
                                                    <div>
                                                        <Button className={styles.awardButton}>
                                                            Visit Award
                                                        </Button>
                                                    </div>
                                                )}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </a>
                            </CardActionArea>
                        </Card>
                    ))}
                </Stack>

                {!!otherAwards?.length && (
                    <>
                        <h2>{otherAwardsTitle}</h2>

                        <ul className={styles.awardOtherAwards}>
                            {otherAwards?.map(({ text }) => (
                                <li>{text}</li>
                            ))}
                        </ul>
                    </>
                )}
            </Container>

            <Box mt={3} />
            <Slider size="large">
                {reviews?.map(({ image, title, description }, index) => (
                    <Slider.Item key={`${title}_${index}`}>
                        <CardActionArea onClick={() => setOpenItem({ image, title, description })}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    height: '100%',
                                }}
                            >
                                <CardMedia
                                    sx={{
                                        width: { xs: '100%', sm: 151 },
                                        height: { xs: 'auto', sm: '100%' },
                                        flex: '0 0 auto',
                                    }}
                                >
                                    <Img
                                        style={{ width: '100%', height: '100%' }}
                                        sizes={{ ...image.childImageSharp.fluid }}
                                    />
                                </CardMedia>
                                <Stack>
                                    <CardHeader title={title} />
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <p>
                                            {description.length > 115
                                                ? `${description
                                                      .substr(0, 115)
                                                      .replace(/<br\s*[\/]?>/gi, '')}...`
                                                : description.replace(/<br\s*[\/]?>/gi, '')}
                                        </p>
                                    </CardContent>
                                </Stack>
                            </Card>
                        </CardActionArea>
                    </Slider.Item>
                ))}
            </Slider>

            <Box mb={6} />

            <Modal isOpen={openItem} onClose={() => setOpenItem(null)} width={700}>
                <Modal.Image image={openItem?.image} />
                <Modal.Title>{openItem?.title}</Modal.Title>
                <Modal.Content truncateHtml html={openItem?.description} />
            </Modal>
        </Layout>
    );
};

export default Awards;

export const query = graphql`
    query AwardsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                heroTitle: awards_recognition_hero_title
                heroDescription: awards_recognition_hero_description
                heroImage: awards_recognition_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                awards: awards_recognition_items {
                    link: awards_recognition_item_link
                    title: awards_recognition_item_title
                    description: awards_recognition_item_description
                    awardImage: awards_recognition_item_image {
                        childImageSharp {
                            fluid(maxWidth: 555) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                otherAwardsTitle: awards_recognition_other_awards_title
                otherAwards: awards_recognition_other_awards_items {
                    text: awards_recognition_other_awards_item_text
                }
                reviews: awards_recognition_review_items {
                    title: awards_recognition_review_item_title
                    description: awards_recognition_review_item_description
                    image: awards_recognition_review_item_image {
                        childImageSharp {
                            fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
