import React from 'react';
import { graphql } from 'gatsby';
import Hero from '@components/Hero';
import { Grid, Row, Col } from '@components/Grid';
import Layout from '@components/Layout';
import Accordion from '@components/Accordion';
import SEO from "@components/SEO";

const Faqs = ({
    data: {
        page: {
            frontmatter: { title, items },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="FAQs | Retreat East | Retreats in UK"
                description="Visit our FAQ page for the answers to questions about the accomodation, spa or restaurant at Retreat East."
            />
            <Hero>
                <h1>{title}</h1>
            </Hero>
            <Grid>
                <Row>
                    <Col xs={12}>
                        {!!items.length &&
                            items.map(({ question, answer }, index) => (
                                <Accordion
                                    key={`${question}_${index}`}
                                    title={question}
                                    text={answer}
                                />
                            ))}
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default Faqs;

export const query = graphql`
    query FaqsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: faqs_title
                items: faqs_items {
                    question: faqs_items_question
                    answer: faqs_items_answer
                }
            }
        }
    }
`;
