import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { findByType } from '@helpers';

import styles from './styles.module.scss';

const Select = ({
    placeholder,
    className = '',
    minimal,
    label,
    labelInline,
    hint,
    hintBlock,
    labelClassName = '',
    required,
    icon,
    value,
    fullWidth,
    inline,
    border = true,
    name,
    onChange,
    options,
    children,
}) => {
    const minimalClass = minimal ? styles.selectMinimal : '';
    const fullWidthClass = fullWidth ? styles.selectFullWidth : '';
    const placeholderClass = !value ? styles.selectPlaceholder : '';
    const noBorderClass = !border ? styles.selectNoBorder : '';
    const inlineClass = inline ? styles.selectInline : '';
    const labelClass = label ? styles.selectHasLabel : '';
    const svgIcon = icon ? (
        <FontAwesomeIcon icon={icon} />
    ) : (
        <FontAwesomeIcon icon={'caret-down'} />
    );

    return (
        <div
            className={`${styles.select} ${className} ${minimalClass} ${fullWidthClass} ${placeholderClass} ${noBorderClass} ${labelClass} ${inlineClass}`}
        >
            {label ? (
                <div className={`${styles.selectLabel} ${labelClassName}`}>
                    <label className={required ? styles.selectLabelRequired : null}>
                        {label}
                        {hint ? (
                            <span className={hintBlock ? styles.selecthintBlock : null}>
                                {hint}
                            </span>
                        ) : null}
                    </label>
                </div>
            ) : null}

            <select name={name} onChange={onChange} value={value || ''}>
                {!value && (
                    <option value="" disabled>
                        {placeholder || 'Please choose an option...'}
                    </option>
                )}
                {findByType(children, Option)}
            </select>
            {svgIcon}
        </div>
    );
};

const Option = ({ value, label }) => <option value={value}>{label}</option>;

Select.Option = Option;
Option.displayName = 'Option';

export default Select;
