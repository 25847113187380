import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';
import Hero from '@components/Hero';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Button from '@components/Button';
import Card from '@components/Card';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SEO from '@components/SEO';
import DownloadFile from '@components/DownloadFile';

const Package = ({ data }) => {
    const { page } = data;
    const { frontmatter } = page;
    const {
        path,
        package_email: email,
        package_title: title,
        package_description: description,
        package_images: images,
        package_button_text: buttonText,
        package_button_link: buttonLink,
        package_dates: dates,
        package_itinerary: itinerary,
        package_details: details,
        package_prices: prices,
        package_extras: extras,
        package_downloads: downloads,
    } = frontmatter;

    const parent = path
        .split('/')
        .slice(0, 2)
        .join('/');
    const parentName = path
        .split('/', 2)[1]
        .replace(/-/g, ' ')
        .split(' ')
        .map(v => v.slice(0, 1).toLocaleUpperCase() + v.slice(1))
        .join(' ');

    return (
        <Layout> 
            <SEO title={`Package - ${title}`} description={description} />
            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb title={parentName} link={parent} />
                <Breadcrumbs.Breadcrumb title={title} link={path} />
            </Breadcrumbs>

            <Hero images={images}>
                <h1>{title}</h1>
                <p>{description}</p>
                {dates && (
                    <div>
                        <div className={styles.dates}>
                            <FontAwesomeIcon icon="clock" />
                            <span>{dates}</span>
                        </div>
                    </div>
                )}
                <Button
                    link={
                        buttonLink
                            ? buttonLink
                            : `mailto:${email}?subject=Packages - Enquiry for ${title}`
                    }
                >
                    {buttonText}
                </Button>
            </Hero>

            <Grid>
                {!!details?.length && details.every(({ package_details_text: text}) => !!text) && (
                    <Fragment>
                        <Row>
                            <Col xs={12}>
                                <h2>Details</h2>
                                <Card>
                                    <Card.List>
                                        {details.map(({ package_details_text: text,package_details_button_link: buttonLink, package_details_button_text: buttonText  }) => (
                                            <Card.List.Item key={text}>
                                                <Card.List.Item.Content>
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: text }}
                                                    ></span>
                                                {!!buttonLink && !!buttonText && <Button
                                                    link={
                                                        buttonLink
                                                    }
                                                >
                                                    {buttonText}
                                                </Button>}
                                                </Card.List.Item.Content>
                                            </Card.List.Item>
                                        ))}
                                    </Card.List>
                                </Card>
                            </Col>
                        </Row>
                        <Divider />
                    </Fragment>
                )}

                {!!itinerary?.length &&
                    itinerary.every(({ package_itinerary_date: date }) => !!date) && (
                        <Fragment>
                            <Row>
                                <Col xs={12}>
                                    <h2>Itinerary</h2>
                                    <Card>
                                        {itinerary.map(
                                            ({
                                                package_itinerary_date: date,
                                                package_itinerary_items: items,
                                            }) => (
                                                <Card.List key={date} title={date}>
                                                    {items.map(
                                                        (
                                                            { package_itinerary_items_text: text },
                                                            index
                                                        ) => (
                                                            <Card.List.Item key={index}>
                                                                <Card.List.Item.Content>
                                                                    {text}
                                                                </Card.List.Item.Content>
                                                            </Card.List.Item>
                                                        )
                                                    )}
                                                </Card.List>
                                            )
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                            <Divider />
                        </Fragment>
                    )}

                {!!prices?.length && prices.every(({ package_prices_price_2: price }) => !!price) && (
                    <Fragment>
                        <Row>
                            <Col xs={12}>
                                <h2>Pricing</h2>
                                <Card>
                                    <Card.List>
                                        {/* TODO: Need to add priceTwo to map*/}
                                        {prices.map(
                                            ({
                                                package_prices_name: name,
                                                package_prices_price_1: priceOne,
                                                package_prices_price_2: priceTwo,
                                            }) => {
                                                let priceCombo = null;
                                                if (priceOne !== '0' && priceTwo !== '0') {
                                                    priceCombo = `£${priceOne} - £${priceTwo}`;
                                                } else if (priceOne !== '0') {
                                                    priceCombo = `£${priceOne}`;
                                                } else if (priceTwo !== '0') {
                                                    priceCombo = `£${priceTwo}`;
                                                }
                                                return (
                                                    <Card.List.Item
                                                        key={`${name}_${priceCombo}`}
                                                        label={name}
                                                        value={priceCombo}
                                                        bold
                                                    />
                                                );
                                            }
                                        )}
                                    </Card.List>
                                </Card>
                            </Col>
                        </Row>
                        <Divider />
                    </Fragment>
                )}

                {!!extras?.length && extras.every(({ package_extras_text: text }) => !!text) && (
                    <Fragment>
                        <Row>
                            <Col xs={12}>
                                <h2>Extras</h2>
                                <Card>
                                    <Card.List>
                                        {extras.map(({ package_extras_text: text }) => (
                                            <Card.List.Item key={text}>
                                                <Card.List.Item.Content>
                                                    {text}
                                                </Card.List.Item.Content>
                                            </Card.List.Item>
                                        ))}
                                    </Card.List>
                                </Card>
                            </Col>
                        </Row>
                        <Divider />
                    </Fragment>
                )}

                {!!downloads?.length && (
                    <Fragment>
                        <Row>
                            <Col xs={12}>
                                <h2>Downloads</h2>
                            </Col>

                            {downloads.map(
                                ({ package_downloads_name: name, package_downloads_file: file }) =>
                                    !!name && (
                                        <Col xs={12} md={3}>
                                            <DownloadFile
                                                file={file?.publicURL}
                                                name={name}
                                                key={name}
                                            />
                                        </Col>
                                    )
                            )}
                        </Row>
                    </Fragment>
                )}
            </Grid>
        </Layout>
    );
};

export default Package;

export const query = () => graphql`
    query PackageQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                package_email
                package_title
                package_description
                package_downloads {
                    package_downloads_name
                    package_downloads_file {
                        publicURL
                    }
                }
                package_images {
                    childImageSharp {
                        fluid(maxHeight: 290) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                package_button_text
                package_button_link
                package_dates
                package_itinerary {
                    package_itinerary_date
                    package_itinerary_items {
                        package_itinerary_items_text
                    }
                }
                package_details {
                    package_details_text
                    package_details_button_link
                    package_details_button_text
                }
                package_prices {
                    package_prices_name
                    package_prices_price_1
                    package_prices_price_2
                }
                package_extras {
                    package_extras_text
                }
            }
        }
    }
`;
