import React, { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import { Grid, Col, Row, Divider } from '@components/Grid';
import Slider from 'react-slick';
import Img from 'gatsby-image';
const Features = ({ data }) => {
    const [slickSlider, setSlickSlider] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        afterChange: e => {
            setActiveIndex(e);
        },
    };

    const handleSlide = index => {
        slickSlider.slickGoTo(index);
    };

    return (
        <div className={styles.sliderMain}>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <Slider
                            {...settings}
                            ref={e => {
                                if (e) setSlickSlider(e);
                            }}
                        >
                            {data.map(({ title, description, image }, index) => {
                                return (
                                    <div>
                                        <Row middle="xs">
                                            <Col xs={12} md={6}>
                                                <h2>{title}</h2>
                                                <Divider margin={3} />
                                                <p>{description}</p>
                                                <Divider xsMargin={3} mdMargin={0} />
                                            </Col>
                                            <Col xs={12} md={6} className={styles.imageParent}>
                                                <div className={styles.sliderImage}>
                                                    <Img
                                                        imgStyle={{ maxHeight: 300 }}
                                                        fluid={image?.childImageSharp?.fluid}
                                                        alt={`Features image ${index + 1}`}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        </Slider>
                    </Col>
                </Row>
                <Divider margin={5} />
                <Divider margin={5} />
                <Row className={styles.sliderPaginationMain}>
                    {data.map(({ title, description, image }, index) => {
                        return (
                            <Col
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={` ${styles.col}
                                 ${activeIndex === index && styles.active}
                                    `}
                            >
                                <div
                                    className={styles.paginationCard}
                                    onClick={() => {
                                        handleSlide(index);
                                    }}
                                >
                                    <h3>{title}</h3>
                                    <Divider margin={2} />
                                    <p>{description}</p>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Grid>
        </div>
    );
};

export default Features;
