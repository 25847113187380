import React from 'react';
import { Grid as ReactGrid, Row as ReactRow, Col as ReactCol } from 'react-flexbox-grid';

import styles from './styles.module.scss';

export const Grid = ({ children, noMargin, className = '', ...props }) => (
    <ReactGrid
        className={`${styles.grid} ${noMargin ? styles.gridNoMargin : ''} ${className}`}
        {...props}
    >
        {children}
    </ReactGrid>
);

export const Row = ({ children, column, row, className = '', ...props }) => {
    const renderClassNames = () => {
        const classNames = [];

        switch (row) {
            case 'xs': {
                classNames.push(styles.flexboxGridRowDirectionRowXs);
                break;
            }
            case 'sm': {
                classNames.push(styles.flexboxGridRowDirectionRowSm);
                break;
            }
            case 'md': {
                classNames.push(styles.flexboxGridRowDirectionRowMd);
                break;
            }
            case 'lg': {
                classNames.push(styles.flexboxGridRowDirectionRowLg);
                break;
            }
            case 'xl': {
                classNames.push(styles.flexboxGridRowDirectionRowXl);
                break;
            }
        }

        switch (column) {
            case 'xs': {
                classNames.push(styles.flexboxGridRowDirectionColumnXs);
                break;
            }
            case 'sm': {
                classNames.push(styles.flexboxGridRowDirectionColumnSm);
                break;
            }
            case 'md': {
                classNames.push(styles.flexboxGridRowDirectionColumnMd);
                break;
            }
            case 'lg': {
                classNames.push(styles.flexboxGridRowDirectionColumnLg);
                break;
            }
            case 'xl': {
                classNames.push(styles.flexboxGridRowDirectionColumnXl);
                break;
            }
        }

        return classNames.join(' ');
    };

    return (
        <ReactRow {...props} className={`${styles.row} ${renderClassNames()} ${className}`}>
            {children}
        </ReactRow>
    );
};

export const Col = ({
    children,
    shrink,
    xsShrink,
    smShrink,
    mdShrink,
    lgShrink,
    xlShrink,
    noGutters,
    className = '',
    ...props
}) => {
    const renderClassNames = () => {
        const classNames = [];
        if (shrink) classNames.push(styles.flexboxGridColshrink);
        if (xsShrink) classNames.push(styles.flexboxGridColXsShrink);
        if (smShrink) classNames.push(styles.flexboxGridColSmShrink);
        if (mdShrink) classNames.push(styles.flexboxGridColMdShrink);
        if (lgShrink) classNames.push(styles.flexboxGridColLgShrink);
        if (xlShrink) classNames.push(styles.flexboxGridColXlShrink);
        if (noGutters) classNames.push(styles.noGutters);

        return classNames.join(' ');
    };

    return (
        <ReactCol {...props} className={`${styles.col} ${renderClassNames()} ${className}`}>
            {children}
        </ReactCol>
    );
};

// Builds margin spacer (responsive)
export const Divider = ({
    margin = 3,
    xsMargin,
    smMargin,
    mdMargin,
    lgMargin,
    xlMargin,
    children,
}) => {
    const renderClassNames = () => {
        const breakpoints = [
            { name: 'Xs', size: xsMargin },
            { name: 'Sm', size: smMargin },
            { name: 'Md', size: mdMargin },
            { name: 'Lg', size: lgMargin },
            { name: 'Sm', size: xlMargin },
        ];

        const classNames = [];

        // base margins (not responsive)
        if (margin === 0) classNames.push(styles.dividerNone);
        if (margin === 1) classNames.push(styles.dividerExtraSmall);
        if (margin === 2) classNames.push(styles.dividerSmall);
        if (margin === 3) classNames.push(styles.dividerMedium);
        if (margin === 4) classNames.push(styles.dividerLarge);
        if (margin === 5) classNames.push(styles.dividerExtraLarge);

        breakpoints.forEach(breakpoint => {
            switch (breakpoint.size) {
                case 0: {
                    classNames.push(styles[`divider${breakpoint.name}None`]);
                    break;
                }
                case 1: {
                    classNames.push(styles[`divider${breakpoint.name}ExtraSmall`]);
                    break;
                }
                case 2: {
                    classNames.push(styles[`divider${breakpoint.name}Small`]);
                    break;
                }
                case 3: {
                    classNames.push(styles[`divider${breakpoint.name}Medium`]);
                    break;
                }
                case 4: {
                    classNames.push(styles[`divider${breakpoint.name}Large`]);
                    break;
                }
                case 5: {
                    classNames.push(styles[`divider${breakpoint.name}ExtraLarge`]);
                    break;
                }
            }
        });

        return classNames.join(' ');
    };

    return <div className={`${styles.divider} ${renderClassNames()}`}>{children}</div>;
};
