import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

const SEO = (config = {}) => {
    return (
        <StaticQuery
            query={pageQuery}
            render={data => {
                const initialConfig = {
                    htmlAttributes: { lang: 'en' },
                    title: 'Retreat East',
                    titleTemplate: 'Retreat East - %s',
                    meta: [
                        {
                            name: 'description',
                            content: 'A rejuvenating retreat in the beautiful Suffolk countryside.',
                        },
                    ],
                };

                return (
                    <Helmet {...initialConfig} {...config}>
                        {config?.description && (
                            <meta name="description" content={config.description} />
                        )}
                         {config?.canonical && (
                            <link rel="canonical" href={`https://retreateast.co.uk${config.canonical}`} />
                        )}                    
                    </Helmet>
                );
            }}
        />
    );
};

export default SEO;

const pageQuery = graphql`
    query LayoutIndexQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
