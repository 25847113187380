import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { graphql } from 'gatsby';
import ReactMapGL, { Marker } from 'react-map-gl';
import Layout from '@components/Layout';
import Hero from '@components/Hero';
import Input from '@components/Input';
import Select from '@components/Select';
import Alert from '@components/Alert';
import { Grid, Row, Col } from '@components/Grid';
import styles from './styles.module.scss';
import Button from '@components/Button';
import { Divider } from '@components/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePostRequest } from '@helpers/requests';
import { useSnackbar } from '@components/Snackbar';
import { FORM } from '@helpers/api';
import SEO from '@components/SEO';
import { Typography } from '@mui/material';

const contactSchema = Yup.object().shape({
    full_name: Yup.string().required('Full name is required'),
    email_address: Yup.string()
        .email('Email address must be valid')
        .required('Email address is required'),
    department: Yup.string().required('Department is required'),
    message: Yup.string().required('Message is required'),
});

const WeddingContact = ({
    data: {
        form,
        page: {
            frontmatter: {
                heroTitle,
                heroImage,
                heroPhoneNumber,
                heroEmail,
                heroDescription,
                heading,
                subheading,
                description,
                departments,
            },
        },
    },
}) => {
    const [openSnackbar] = useSnackbar();

    const handleSubmit = async (values, actions) => {
        try {
            const postData = {
                formId: form?._id,
                sections: Object.keys(values).map(sectionId => {
                    const formSection = form?.sections?.find(({ _id }) => _id === sectionId);

                    let sectionData = {
                        _id: sectionId,
                        fields: [],
                    };

                    for (let fieldId in values[sectionId]) {
                        if (values[sectionId].hasOwnProperty(fieldId)) {
                            const formSectionField = formSection?.fields?.find(
                                ({ _id }) => _id === fieldId
                            );

                            sectionData.fields.push({
                                _id: fieldId,
                                value:
                                    formSectionField?.type === 'dropdown' ||
                                    formSectionField?.type === 'radio'
                                        ? formSectionField?.options?.find(
                                              ({ _id }) => _id === values[sectionId][fieldId]
                                          )?.label
                                        : values[sectionId][fieldId],
                            });
                        }
                    }

                    return sectionData;
                }),
            };

            const { data: formData } = await makePostRequest(FORM, postData);
            if (typeof window !== 'undefined') {
                window.open('/wedding-brochure.pdf', '_blank');
            }
            openSnackbar('Your application form was successfully submitted.');
            actions?.resetForm();
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred submitting this form, please try clearing your cache or try again later.'
                );
        }
    };

    const getFields = () => {
        const sections = {};

        for (let section of form?.sections) {
            sections[section._id] = {};

            for (let field of section?.fields) {
                sections[section._id][field?._id] = undefined;
            }
        }
        return sections;
    };

    const renderValidationField = ({ type, label, optional, validation }) => {
        let value;

        switch (type) {
            case 'string':
                value = Yup.string();
                if (validation?.type === 'text' && validation?.condition === 'email') {
                    value = value.email(validation?.error ?? 'Must be an email address.');
                }
                if (!optional) value = value.required(`${label} is required.`);
                return value;
            case 'paragraph':
            case 'dropdown':
                // case 'radio':
                value = Yup.string();
                if (!optional) value = value.required(`${label} is required.`);
                return value;
            // case 'checkbox':
            //     value = Yup.array().of(Yup.string());
            //     if (!optional) value = value.required(`${label} is required.`);
            //     return value;
        }

        return Yup.mixed().nullable();
    };

    const renderValidationFields = () => {
        const schema = {};

        Object.keys(form?.sections).forEach(sectionKey => {
            const section = form?.sections[sectionKey];
            const sectionId = section?._id;
            const fieldSchema = {};

            Object.keys(section?.fields).forEach(fieldKey => {
                const field = section?.fields[fieldKey];
                const fieldId = field?._id;

                fieldSchema[fieldId] = renderValidationField(field);
            });

            schema[sectionId] = Yup.object().shape(fieldSchema);
        });

        return Yup.object().shape(schema);
    };

    return (
        <Layout>
            <SEO
                title="Contact | Weddings | Wedding Venues Suffolk | Retreat East"
                description="Contact our team for further information about the wedding packages we offer. We look forward to being part of your big day."
            />
            <Hero className={styles.hero} image={heroImage} imageFullWidth>
                <h1>{heroTitle}</h1>
                {/* <p>{`Email: ${heroEmail}`}</p>
                <p>{`Phone: ${heroPhoneNumber}`}</p> */}

                {!!heroDescription && (
                    <Fragment>
                        <Divider margin={2} />
                        <p>{heroDescription}</p>
                    </Fragment>
                )}
            </Hero>

            <Grid>
                <Row>
                    <Col xs={12}>
                        <h2>{heading}</h2>
                        <Typography variant="body1">{subheading}</Typography>
                        <p className={styles.contactUsDescription}>{description}</p>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col xs={12} md={6}>
                        {/* <p>Extra venue Options</p> */}
                        <Formik
                            initialValues={getFields()}
                            validationSchema={renderValidationFields()}
                            onSubmit={handleSubmit}
                        >
                            {props => {
                                return (
                                    <Fragment>
                                        <Row>
                                            <Col xs={12}>
                                                <ContactFormikForm {...props} form={form} />
                                            </Col>
                                        </Row>
                                        <Divider margin={3} />
                                        <Row>
                                            <Col xs={12}>
                                                <Button
                                                    disabled={props.isSubmitting}
                                                    onClick={props.handleSubmit}
                                                >
                                                    Send
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                );
                            }}
                        </Formik>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12}>
                                <ReactMapGL
                                    mapStyle="mapbox://styles/mapbox/outdoors-v10?optimize=true"
                                    mapboxApiAccessToken="pk.eyJ1IjoiYmlncml2YWwiLCJhIjoiY2phMzRsanUzMHV6ajJxbGZzdDZsamtqayJ9.PngeT-c1grgQoIMXMhkBUA"
                                    zoom={13}
                                    longitude={1.121334}
                                    latitude={52.134348}
                                    height={315}
                                    width="100%"
                                >
                                    <Marker
                                        longitude={1.121334}
                                        latitude={52.134348}
                                        offsetLeft={-19}
                                        offsetTop={-8}
                                    >
                                        <FontAwesomeIcon
                                            className={styles.marker}
                                            icon="map-marker-alt"
                                        />
                                    </Marker>
                                </ReactMapGL>
                            </Col>
                        </Row>

                        <Divider margin={2} />

                        <Row end="xs">
                            <Col xs={12}>
                                <Button link="https://www.google.com/maps/dir/retreat+east">
                                    Get directions
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

const ContactFormikForm = ({ values, handleChange, setFieldValue, touched, errors, form }) => {
    // const [messageRows, setMessageRows] = useState(0);
    // const handleMessageChange = (e, { value, rows }) => {
    //     setFieldValue('message', value);
    //     setMessageRows(rows);
    // };

    return (
        <div>
            {form?.sections?.map(section => (
                <Fragment key={section?._id}>
                    {section?.fields?.map(field => {
                        const { _id, type, hint, label, optional } = field;
                        const name = `${section._id}.${_id}`;

                        const fieldProps = {
                            name,
                            optional: optional,
                            label: label,
                            hint: hint,
                            value: values?.[section?._id]?.[_id],
                            onChange: handleChange,
                            id: _id,
                        };
                        return (
                            <Fragment key={_id}>
                                {type === 'string' && <Input {...fieldProps} />}
                                {type === 'paragraph' && <Input {...fieldProps} textarea />}
                                {/*{type === 'radio' && <Radios {...fieldProps} />}*/}
                                {/*{type === 'checkbox' && <Checkboxes {...fieldProps} />}*/}
                                {type === 'dropdown' && (
                                    <Select {...fieldProps}>
                                        {field?.options?.map(option => (
                                            <Select.Option
                                                key={option?._id}
                                                value={option?._id}
                                                label={option?.label}
                                            />
                                        ))}
                                    </Select>
                                )}

                                {/*{type === 'checkbox' && <Checkboxes {...fieldProps} />}*/}

                                {touched?.[section?._id]?.[_id] &&
                                    errors?.[section?._id]?.[_id] && (
                                        <Alert
                                            type="error"
                                            message={errors?.[section?._id]?.[_id]}
                                        />
                                    )}
                            </Fragment>
                        );
                    })}
                </Fragment>
            ))}
        </div>
    );
};

export default WeddingContact;

export const query = () => graphql`
    query WeddingContactQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: weddings_contact_hero_title
                heroImage: weddings_contact_hero_image {
                    childImageSharp {
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroPhoneNumber: weddings_contact_hero_phone_number
                heroEmail: weddings_contact_hero_email
                heroDescription: weddings_contact_hero_description
                heading: weddings_contact_heading
                subheading: weddings_contact_subheading
                description: weddings_contact_description
            }
        }
        form: merlinForm(_id: { eq: "5f35657b1e92f600179cfff4" }) {
            _id
            name
            description
            meta {
                updatedTimestamp
            }
            sections {
                _id
                title
                description
                multiple
                min
                max
                fields {
                    label
                    hint
                    optional
                    options {
                        _id
                        label
                    }
                    validation {
                        type
                        condition
                        value
                        error
                    }
                    type
                    placeholder
                    id
                    _id
                }
            }
        }
    }
`;
