import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';

export const Careers = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroDescription, jobs, email, notAvailable },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Careers | Ipswich Hotel & Restaurant | Retreat East"
                description="Visit our careers page to find out about the vacancies we have available at our Ipswich hotel and restaurant."
            />
            <Hero>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>
            <Grid>
                {jobs && jobs.length && jobs.every(job => job.name && job.description) ? (
                    jobs.map(({ name, description, pay, buttonText, buttonLink }, index) => (
                        <Row>
                            <Col xs={12}>
                                <div className={styles.job}>
                                    <h2>{name}</h2>
                                    <p>{description}</p>
                                    {pay && <span>{pay}</span>}
                                    {buttonText && (
                                        <div className={styles.jobButton}>
                                            <Button
                                                link={
                                                    buttonLink
                                                        ? buttonLink
                                                        : `mailto:${email}?subject=Recruitment - Enquiry for job role: ${name}`
                                                }
                                            >
                                                {buttonText}
                                            </Button>
                                        </div>
                                    )}
                                </div>

                                {index !== jobs.length - 1 && <hr />}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <Row center="xs">
                        <Col xs={12}>
                            <p style={{ marginBottom: 0 }}>{notAvailable}</p>
                        </Col>
                    </Row>
                )}
            </Grid>
        </Layout>
    );
};

export default Careers;

export const query = () => graphql`
    query CareersQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                heroTitle: careers_hero_title
                heroDescription: careers_hero_description
                email: careers_email
                notAvailable: careers_not_available_text
                jobs: careers_jobs {
                    name: careers_jobs_name
                    description: careers_jobs_description
                    pay: careers_jobs_pay
                    buttonLink: careers_jobs_button_link
                    buttonText: careers_jobs_button_text
                }
            }
        }
    }
`;
