import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Fragment, useEffect } from 'react';

import Alert from '@components/Alert';
import Button from '@components/Button';
import { Divider, Row, Col } from '@components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import * as Yup from 'yup';
import { Formik } from 'formik';

const stripeKey =
    process.env.NODE_ENV === 'development'
        ? 'pk_test_51H4jeWG6ZsZwJgoloEmWJ0RQsXBMsLxSuKBxT8ahYMhTTo667pjXYAUzT6YnhaMbL3PRnXnOnIi5xX0XTAYNs8mR002TtDSRj2'
        : 'pk_live_51H4jeWG6ZsZwJgolUmY4cxKKtzxaLNLMeCc66aUhfIjqaInxWjHnXgT1pGdN5u2yZ4AUzh7y2gaKQYhlYqYVpArk00nWBJJUOs';
const stripePromise = loadStripe(stripeKey);

const Payment = ({
    onPrevious,
    currency,
    subtotal,
    shipping,
    cardError,
    setCardError,
    onSubmit,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handlePreSubmit = async () => {
        if (cardError) {
            elements.getElement('card').focus();
            return;
        }

        await onSubmit({ stripe, elements });
    };

    return (
        <Formik
            initialValues={{
                cardComplete: false,
            }}
            validationSchema={Yup.object().shape({
                cardComplete: Yup.boolean().oneOf([true], `Card must be completed`),
            })}
            onSubmit={handlePreSubmit}
        >
            {({ setFieldValue, touched, errors, isSubmitting, handleSubmit }) => (
                <Fragment>
                    <h1>Payment</h1>
                    <p>Review your order and confirm payment method.</p>
                    <Divider margin={5} />
                    <p>
                        <b>
                            That'll be{' '}
                            {`${currency.symbol}${parseFloat(
                                subtotal +
                                    (shipping?.shipmentCost ?? 0) +
                                    (shipping?.otherCost ?? 0)
                            ).toFixed(2)} ${currency.code}`}
                        </b>
                    </p>
                    <CardElement
                        onChange={e => {
                            setCardError(e.error);
                            setFieldValue('cardComplete', !!e.complete);
                        }}
                        options={{
                            hidePostalCode: true,
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#252525',
                                    '::placeholder': {
                                        color: '#9b9b9b',
                                    },
                                },
                                invalid: {
                                    color: '#d46b5f',
                                },
                            },
                        }}
                    />
                    {cardError && (
                        <Fragment>
                            <Alert message={cardError.message} type="error" />
                        </Fragment>
                    )}
                    {!cardError && touched.cardComplete && errors.cardComplete && (
                        <Fragment>
                            <Alert message={errors.cardComplete} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                    <Row between="xs">
                        <Col>
                            <Button text="Back" secondary onClick={onPrevious} />
                        </Col>
                        <Col>
                            <Button
                                success
                                text="Place order"
                                onClick={handleSubmit}
                                disabled={!stripe}
                                submitting={isSubmitting}
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Formik>
    );
};

const PaymentWithStripe = props => {
    // const [stripePromise, setStripePromise] = useState(null);
    //
    // useEffect(() => {
    //     if (stripePublishableKey) setStripePromise(loadStripe(stripePublishableKey))
    // }, [stripePublishableKey]);

    return (
        <Elements stripe={stripePromise}>
            <Payment {...props} />
        </Elements>
    );
};

export default PaymentWithStripe;
