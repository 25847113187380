import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import { findByType } from '@helpers';

import styles from './styles.module.scss';

const Breadcrumbs = ({ location, children }) => {
    return (
        <ul className={styles.breadcrumbs}>
            {findByType(children, Breadcrumb, false, { location })}
        </ul>
    );
};

const Breadcrumb = ({ title, link, location }) => {
    return (
        <li
            className={`${styles.breadcrumb} ${
                location.pathname === link ? styles.breadcrumbActive : ''
            }`}
        >
            <Link to={link}>{title}</Link>
        </li>
    );
};

const BreadcrumbsWithLocation = props => (
    <Location>{locationContext => <Breadcrumbs {...locationContext} {...props} />}</Location>
);

BreadcrumbsWithLocation.Breadcrumb = Breadcrumb;
Breadcrumb.displayName = 'Breadcrumb';

export default BreadcrumbsWithLocation;
