import React, { useEffect } from 'react';
// import ReactHlsPlayer from 'react-hls-player';
import loadable from '@loadable/component'

const ReactHlsPlayer = loadable(() => import('react-hls-player'))

const VideoPlayer = ({url}) => {
    return (
        <ReactHlsPlayer
            // url='https://retreateast.co.uk/videos/wedding/output.m3u8'
            // url="/videos/wedding/output.m3u8"
            url={url}
            autoplay
            controls={false}
            loop
            muted
        />
    )
}

export default React.memo(VideoPlayer);

