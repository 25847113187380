import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '@components/Label';
const Checkboxes = ({
    value,
    name,
    id,
    label,
    hint,
    options,
    optional,
    error,
    setFieldValue,
    inline,
}) => {
    const handleChange = e => {
        const option = options.find(({ _id }) => {
            return _id === e.target.value;
        });
        setFieldValue(
            name,
            !value?.some(({ _id }) => _id === e.target.value)
                ? [...(value || []), option]
                : (value || []).filter(({ _id }) => _id !== e.target.value)
        );
    };

    return (
        <div className={`${styles.checkboxes} ${inline ? styles.checkboxesInline : ''}`}>
            {label && <label>{label}</label>}
            <div className={styles.checkboxesWrapper}>
                {options?.map(({ label, _id }, index) => {
                    return (
                        <div className={styles.checkbox}>
                            <input
                                checked={value?.some(({ _id: selectedId }) => selectedId === _id)}
                                type="checkbox"
                                value={_id}
                                id={_id}
                                onChange={handleChange}
                            />
                            <div className={styles.checkboxLabel}>
                                <Label
                                    text={label}
                                    //  onClick={handleClick}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Checkboxes;
