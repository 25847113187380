import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import { Grid, Row, Col } from '@components/Grid';
import Card from '@components/Card';
import Breadcrumbs from '@components/Breadcrumbs';

import styles from './styles.module.scss';
import { Divider } from '../../components/Grid';
import SEO from '@components/SEO';
import Button from '@components/Button';
import Accordion from '@components/Accordion/index.js';

const Menu = ({ data }) => {
    const { page } = data;
    const { frontmatter } = page;
    const {
        path,
        name,
        seoTitle,
        faqs,
        seoDescription,
        description,
        conclusion,
        allergen,
        times,
        menus,
        image,
        bookTextPrimary,
        bookLinkPrimary,
        bookTextSecondary,
        bookLinkSecondary,
    } = frontmatter;



    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />

            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb title="Dine" link="/dine" />
                <Breadcrumbs.Breadcrumb title={name} link={path} />
            </Breadcrumbs>

            <div className={styles.menu}>
                <Hero className={styles.hero} image={image}>
                    <h1>{`${name} Menu`}</h1>
                    <p>{description}</p>
                    {!!bookTextPrimary && !!bookLinkPrimary && <Button link={bookLinkPrimary}>{bookTextPrimary}</Button>}
                    {!!bookTextSecondary && !!bookLinkSecondary && <Button secondary link={bookLinkSecondary}>{bookTextSecondary}</Button>}
                </Hero>



                <Grid>
                    {(times || allergen) && (
                        <Fragment>
                            <Row>
                                {times && (
                                    <Col xs={12} md={6}>
                                        <Fragment>
                                            <div>
                                                <Card fullHeight>
                                                    <Card.Title icon="clock">Times</Card.Title>
                                                    <Card.Content>
                                                        <p>{times}</p>
                                                    </Card.Content>
                                                </Card>
                                            </div>
                                            <Divider xsMargin={3} lgMargin={0} />
                                        </Fragment>
                                    </Col>
                                )}

                                {allergen && (
                                    <Col xs={12} md={6}>
                                        <Card fullHeight>
                                            <Card.Title icon="allergies">Allergens</Card.Title>
                                            <Card.Content>
                                                <p>{allergen}</p>
                                            </Card.Content>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                            <Divider />
                        </Fragment>
                    )}

                    <div className={styles.menus}>
                        {menus.map(({ name, sections }, index) => (
                            <Row className={styles.menusMenu} key={index}>
                                <Col xs>
                                    {name && (
                                        <div className={styles.menusMenuTitle}>
                                            <h2>{name}</h2>
                                        </div>
                                    )}
                                    {sections &&
                                        Array.isArray(sections) &&
                                        sections.map(({ name, description, items }, index) => (
                                            <div className={styles.menusMenuSection} key={index}>
                                                {name ? (
                                                    <div className={styles.menusMenuSectionTitle}>
                                                        <h3>{name}</h3>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`${styles.menusMenuSectionTitle} ${styles.menusMenuSectionTitleEmpty}`}
                                                    />
                                                )}
                                                {description && (
                                                    <p
                                                        className={
                                                            styles.menusMenuSectionDescription
                                                        }
                                                    >
                                                        {description}
                                                    </p>
                                                )}
                                                {items &&
                                                    Array.isArray(items) &&
                                                    items.map(
                                                        (
                                                            { name, description, prices = [] },
                                                            index
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    styles.menusMenuSectionItem
                                                                }
                                                            >
                                                                {name && (
                                                                    <div
                                                                        className={
                                                                            styles.menusMenuSectionItemName
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={
                                                                                styles.menusMenuSectionItemNameTitle
                                                                            }
                                                                        >
                                                                            {name}
                                                                        </p>
                                                                        {description && (
                                                                            <p
                                                                                className={
                                                                                    styles.menusMenuSectionItemNameDescription
                                                                                }
                                                                            >
                                                                                {description}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                <span
                                                                    className={
                                                                        styles.menusMenuSectionItemDivider
                                                                    }
                                                                />
                                                                <div
                                                                    className={
                                                                        styles.menusMenuSectionItemPrices
                                                                    }
                                                                >
                                                                    {prices.map(
                                                                        ({
                                                                            name = '',
                                                                            price = '',
                                                                        }) => (
                                                                            <div
                                                                                key={`${name}-${price}`}
                                                                                className={
                                                                                    styles.menusMenuSectionItemPricesPrice
                                                                                }
                                                                            >
                                                                                {name && (
                                                                                    <span
                                                                                        className={
                                                                                            styles.menusMenuSectionItemPricesPriceLabel
                                                                                        }
                                                                                    >
                                                                                        {name}
                                                                                    </span>
                                                                                )}
                                                                                {price ? (
                                                                                    <span
                                                                                        className={
                                                                                            styles.menusMenuSectionItemPricesPriceValue
                                                                                        }
                                                                                    >
                                                                                        {`£${price}`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className={
                                                                                            styles.menusMenuSectionItemPricesPriceValueEmpty
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        ))}
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <Divider />
                    {!!conclusion && <p>{conclusion}</p>}
                    <Divider />
                    {!!faqs && <h2>Commonly asked questions</h2>}
                    {!!faqs &&
                        faqs.map(({ question, answer }, index) => {
                            return <Accordion key={index} title={question} text={answer} />;
                        })}
                </Grid>
            </div>
        </Layout>
    );
};

export default Menu;

export const query = () => graphql`
    query MenuQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                name: menu_name
                seoTitle: menu_description_seo_title
                seoDescription: menu_description_seo_description
                description: menu_description
                conclusion: menu_conclusion_text
                allergen: menu_allergen
                times: menu_times
                image: menu_image {
                    childImageSharp {
                        fluid(maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                bookTextPrimary: menu_book_button_text
                bookLinkPrimary: menu_book_button_link
                bookTextSecondary: menu_book_button_text_secondary
                bookLinkSecondary: menu_book_button_link_secondary
                menus: menu_menus {
                    name: menu_menus_name
                    sections: menu_menus_sections {
                        name: menu_menus_sections_name
                        description: menu_menus_sections_description
                        items: menu_menus_sections_items {
                            name: menu_menus_sections_items_name
                            description: menu_menus_sections_items_description
                            prices: menu_menus_sections_items_prices {
                                name: menu_menus_sections_items_prices_name
                                price: menu_menus_sections_items_prices_price
                            }
                        }
                    }
                }
                faqs: menu_faqs {
                    question: menu_faq_title
                    answer: menu_faq_answer
                }
            }
        }
    }
`;
