import React, { useState, Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import styles from './styles.module.scss';
import { Divider } from '@components/Grid';
import Hero from '@components/Hero';
import { Container, ImageList, ImageListItem } from '@mui/material';
import Img from 'gatsby-image';
import Card from '@components/Card';
import Modal from '@components/Modal';
import { any } from 'prop-types';
import FsLightbox from 'fslightbox-react';

const WeddingsGallery = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroImage, heroDescription, galleryImages },
        },
        allFile,
    },
}) => {
    const [activeImageIndex, setActiveImageIndex] = useState(null);

    const images = allFile.edges.map(image => image?.node?.childImageSharp?.fluid);

    return (
        <Layout>
            <SEO
                title="Gallery | Weddings | Wedding Venues Suffolk | Retreat East"
                description="View our wedding gallery on our website. Here you'll be able to see how beautiful a wedding at Retreat East can be."
            />

            <FsLightbox
                toggler={activeImageIndex !== null}
                sources={images?.map(x => x?.src)}
                slide={activeImageIndex + 1}
            />

            <Hero className={styles.hero} image={heroImage} imageFullWidth>
                <h1>{heroTitle}</h1>

                {!!heroDescription && (
                    <Fragment>
                        <Divider margin={2} />
                        <p>{heroDescription}</p>
                    </Fragment>
                )}
            </Hero>
            <Divider margin={4} />
            <Container maxWidth="lg">
                {!!galleryImages?.length && (
                    <ImageList
                        variant="masonry"
                        sx={{ height: '100%', width: '100%' }}
                        cols={3}
                        gap={8}
                    >
                        {galleryImages?.map?.((image, index) => (
                            <ImageListItem
                                key={index}
                                onClick={() => setActiveImageIndex(index)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <Img
                                    fluid={image?.galleryImage?.childImageSharp?.fluid}
                                    alt={`Wedding gallery image ${index + 1}`}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                )}
            </Container>
            <Divider margin={4} />
        </Layout>
    );
};

export default WeddingsGallery;

export const query = () => graphql`
    query WeddingGalleryQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: weddings_gallery_hero_title
                heroDescription: weddings_gallery_hero_description
                heroImage: weddings_gallery_hero_image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                galleryImages: weddings_gallery_images {
                    galleryImage: weddings_gallery_images_image {
                        childImageSharp {
                            fluid(maxWidth: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    title: weddings_gallery_images_image_title
                    cols: weddings_gallery_images_image_cols
                    rows: weddings_gallery_images_image_rows
                }
            }
        }
        allFile(filter: { relativeDirectory: { eq: "wedding-gallery" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
