import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { Divider } from '@components/Grid';
import SEO from '@components/SEO';

const Ceremonies = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroDescription, heroImage },
        },
        ceremonies: { edges: ceremonies },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Wedding Ceremonies | Wedding Venues Suffolk | Retreat East"
                description="Find out more about the wedding ceremony options we offer at Retreat East.  We look forward to being part of your big day."
            />
            <Hero image={heroImage} imageFullWidth imageStyle={{ objectPosition: '50% 60%' }}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>

            <Grid>
                {ceremonies.map(({ node: { frontmatter: { path, name, text, image } } }, index) => (
                    <Fragment key={path}>
                        <div className={styles.menu}>
                            <Row middle="xs" reverse={index % 2 === 0}>
                                <Col xs={12} md={6}>
                                    <div className={styles.menuContent}>
                                        <h2>{name}</h2>
                                        <p>{text}</p>
                                        <Button link={path}>View</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Img
                                        className={styles.menuImage}
                                        fluid={image?.childImageSharp?.fluid}
                                        alt={`Wedding ceremonies image ${index + 1}`}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Divider margin={4} />
                    </Fragment>
                ))}
            </Grid>
        </Layout>
    );
};

export default Ceremonies;

export const query = () => graphql`
    query CeremoniesQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: weddings_ceremonies_hero_title
                heroDescription: weddings_ceremonies_hero_description
                heroImage: weddings_ceremonies_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        ceremonies: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/ceremonies/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        name: ceremonies_ceremony_title
                        text: ceremonies_ceremony_overview_text
                        image: ceremonies_ceremony_overview_image {
                            childImageSharp {
                                fluid(maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
