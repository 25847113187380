import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import queryString from 'query-string';

import Hero from '@components/Hero';
import Button from '@components/Button';
import { Col, Grid, Row, Divider } from '@components/Grid';
import Layout from '@components/Layout';
import Select from '@components/Select';
import CallToActions from '@components/CallToActions';
import Card from '@components/Card';

import styles from './styles.module.scss';
import SEO from '@components/SEO';
import { useLocation, useNavigate } from '@reach/router';

const Stays = ({
    data: {
        page: {
            frontmatter: {
                heroTitle,
                heroSubtitle,
                heroDescription,
                heroButtonText,
                heroButtonLink,
                heroImage,
                callToActions,
            },
        },
        barnsAndRooms: { edges: barnsAndRooms },
    },
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = queryString.parse(location.search, {
        parseNumbers: true,
    });
    const guestFilter = params?.guests ?? null;
    const bedroomFilter = params?.bedrooms ?? null;

    let barnsAndRoomsFiltered = barnsAndRooms
        .map(edge => edge.node.frontmatter)
        .filter(
            stay =>
                (!guestFilter || stay.guests >= guestFilter) &&
                (!bedroomFilter || stay.bedrooms >= bedroomFilter)
        )
        .sort(
            (a, b) => b.guests + b.bedrooms + b.bathrooms - (a.guests + a.bedrooms + a.bathrooms)
        );

    const maxBedrooms = !!barnsAndRoomsFiltered.length
        ? barnsAndRoomsFiltered.reduce((prev, current) =>
              prev.bedrooms > current.bedrooms ? prev : current
          ).bedrooms
        : 0;
    const maxGuests = !!barnsAndRoomsFiltered.length
        ? barnsAndRoomsFiltered.reduce((prev, current) =>
              prev.guests > current.guests ? prev : current
          ).guests
        : 0;

    return (
        <Layout>
            <SEO
                title="Barns & Rooms | Suffolk Holidays | Retreat East"
                description="Visit our website to view our range of barns & rooms for your Suffolk holiday. Our luxury retreat is the perfect place to recharge."
            />
            <div className={styles.stays}>
                <Hero image={heroImage}>
                    {!!heroSubtitle && <h2>{heroSubtitle}</h2>}
                    <h1>{heroTitle}</h1>
                    {!!heroDescription && <p style={{ marginBottom: 10 }}>{heroDescription}</p>}
                    {!!heroButtonLink && !!heroButtonText && (
                        <Button link={heroButtonLink}>{heroButtonText}</Button>
                    )}
                    <Divider xsMargin={3} mdMargin={0} />
                </Hero>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <div className={styles.filters}>
                                <h2>Filter</h2>
                                <Select
                                    label="No. of Guests"
                                    inline
                                    onChange={e =>
                                        navigate(
                                            `${location.pathname}?${queryString.stringify({
                                                ...queryString.parse(location.search),
                                                guests: e.target.value,
                                            })}`,
                                            { replace: true }
                                        )
                                    }
                                    placeholder="-"
                                    value={guestFilter}
                                    className={styles.select}
                                    labelClassName={styles.selectLabel}
                                >
                                    {!!guestFilter && <Select.Option label="Any Guests" value="" />}
                                    {Array(maxGuests)
                                        .fill()
                                        .map((x, i) => (
                                            <Select.Option
                                                key={i}
                                                label={`${i + 1} Guest${i + 1 !== 1 ? 's' : ''}`}
                                                value={i + 1}
                                            />
                                        ))}
                                </Select>
                                <Select
                                    label="No. of Bedrooms"
                                    inline
                                    onChange={e =>
                                        navigate(
                                            `${location.pathname}?${queryString.stringify({
                                                ...queryString.parse(location.search),
                                                bedrooms: e.target.value,
                                            })}`,
                                            { replace: true }
                                        )
                                    }
                                    placeholder="-"
                                    value={bedroomFilter}
                                    className={styles.select}
                                    labelClassName={styles.selectLabel}
                                >
                                    {!!bedroomFilter && (
                                        <Select.Option label="Any Bedrooms" value="" />
                                    )}
                                    {Array(maxBedrooms)
                                        .fill()
                                        .map((x, i) => (
                                            <Select.Option
                                                key={i}
                                                label={`${i + 1} Bedroom${i + 1 !== 1 ? 's' : ''}`}
                                                value={i + 1}
                                            />
                                        ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {barnsAndRoomsFiltered.map(
                            ({ slug, title, category, guests, bedrooms, bathrooms, images }) => (
                                <Col xs={12} md={6} lg={4} key={slug}>
                                    <div className={styles.barn}>
                                        <Card
                                            className={styles.barnCard}
                                            onClick={() =>
                                                navigate(`/stay/barns-and-rooms/${slug}`)
                                            }
                                        >
                                            <Card.Image
                                                height={280}
                                                fluid={images[0].childImageSharp.fluid}
                                                alt={`Barns and rooms image`}
                                            />
                                            <Card.Title multiLine>
                                                {title}
                                                <Card.Title.Description>
                                                    {category}
                                                </Card.Title.Description>
                                            </Card.Title>
                                            <Card.Content className={styles.barnCardContent}>
                                                <ul className={styles.barnCounter}>
                                                    <li>
                                                        <p>{guests}</p>
                                                        <p>Guests</p>
                                                    </li>
                                                    <li>
                                                        <p>{bedrooms}</p>
                                                        <p>Bedrooms</p>
                                                    </li>
                                                    <li>
                                                        <p>{bathrooms}</p>
                                                        <p>Bathrooms</p>
                                                    </li>
                                                </ul>
                                            </Card.Content>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        )}
                    </Row>
                </Grid>

                {callToActions && (
                    <CallToActions>
                        {callToActions.map(callToAction => (
                            <CallToActions.CallToAction
                                key={callToAction.title}
                                {...callToAction}
                            />
                        ))}
                    </CallToActions>
                )}
            </div>
        </Layout>
    );
};

export default Stays;

export const query = () => graphql`
    query BarnsAndRoomsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: stay_barns_and_rooms_hero_title
                heroSubtitle: stay_barns_and_rooms_hero_subtitle
                heroDescription: stay_barns_and_rooms_hero_description
                heroButtonText: stay_barns_and_rooms_hero_button_text
                heroButtonLink: stay_barns_and_rooms_hero_button_link
                heroImage: stay_barns_and_rooms_hero_image {
                    childImageSharp {
                        fluid(maxHeight: 340) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                callToActions: stay_barns_and_rooms_call_to_actions {
                    title: stay_barns_and_rooms_call_to_actions_title
                    description: stay_barns_and_rooms_call_to_actions_description
                    buttonText: stay_barns_and_rooms_call_to_actions_button_text
                    buttonLink: stay_barns_and_rooms_call_to_actions_button_link
                    image: stay_barns_and_rooms_call_to_actions_image {
                        childImageSharp {
                            fluid(maxHeight: 340) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        barnsAndRooms: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { fileAbsolutePath: { regex: "/data/barn-room/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        title: stay_barn_room_title
                        category: stay_barn_room_category
                        guests: stay_barn_room_guests
                        bedrooms: stay_barn_room_bedrooms
                        bathrooms: stay_barn_room_bathrooms
                        images: stay_barn_room_images {
                            childImageSharp {
                                fluid(maxHeight: 290) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
