import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';
import Accordion from '@components/Accordion';
import CallToActions from '@components/CallToActions';
import Hero from '@components/Hero';
import { Stack, Grid as MuiGrid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';
import SEO from '@components/SEO';
import Slider from 'react-slick';

const Barn = ({ data }) => {
    const {
        barn: barnData,
        callToActions: callToActionsData,
        availabilityCallToAction: availabilityCallToActionData,
    } = data;
    const { frontmatter: barnFrontmatter } = barnData;
    const { frontmatter: callToActionsFrontmatter } = callToActionsData;
    const { frontmatter: availabilityCallToAction } = availabilityCallToActionData;
    const {
        slug,
        stay_barn_room_title: title,
        stay_barn_room_seo_title: seoTitle,
        stay_barn_room_seo_description: seoDescription,
        stay_barn_room_category: category,
        stay_barn_room_description: description,
        stay_barn_room_images: images,
        stay_barn_room_mews_id: mewsId,
        stay_barn_room_button_text: buttonText,
        stay_barn_room_guests: guests,
        stay_barn_room_bedrooms: bedrooms,
        stay_barn_room_bathrooms: bathrooms,
        stay_barn_room_essentials_title: essentialsTitle,
        stay_barn_room_essentials_description: essentialsDescription,
        stay_barn_room_essentials_items: essentials,
        stay_barn_room_features_title: featuresTitle,
        stay_barn_room_features_description: featuresDescription,
        stay_barn_room_features_items: features,
        reviews: reviews,
    } = barnFrontmatter;
    const { callToActions } = callToActionsFrontmatter;

    {
        /* SlickSlider settings and state declaration */
    }

    const [slickSlider, setSlickSlider] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: true,
        useTransform: true,
        cssEase: 'ease-in-out',
        afterChange: e => {
            setActiveIndex(e);
        },
    };

    const handleSlide = index => {
        slickSlider.slickGoTo(index);
    };

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb key="/stay" title="Stay" link="/stay" />
                <Breadcrumbs.Breadcrumb
                    key="/stay/barns-and-rooms"
                    title="Barns & Rooms"
                    link="/stay/barns-and-rooms"
                />
                <Breadcrumbs.Breadcrumb
                    key={slug}
                    title={title}
                    link={`/stay/barns-and-rooms/${slug}`}
                />
            </Breadcrumbs>

            <Hero
                images={images}
                imageWrapperStyle={{ maxHeight: 400 }}
                imageAlt={`${title}'s image`}
            >
                <h2>{category}</h2>
                <h1>{title}</h1>
                <p>{description}</p>
                <Row>
                    <Col xs={12}>
                        <ul className={styles.barnCounter}>
                            <li>
                                <p>{guests}</p>
                                <p>Guests</p>
                            </li>
                            <li>
                                <p>{bedrooms}</p>
                                <p>Bedrooms</p>
                            </li>
                            <li>
                                <p>{bathrooms}</p>
                                <p>Bathrooms</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Button link={`/stay/barns-and-rooms/${slug}?bookingId=${mewsId}`}>
                    {buttonText}
                </Button>
                <Divider xsMargin={3} mdMargin={0} />
            </Hero>
            <Grid>
                <div className={styles.essentials}>
                    <Row center="xs">
                        <Col xs={12}>
                            <h2>{essentialsTitle}</h2>
                            <Row center="xs">
                                <Col xs={12} md={8}>
                                    <p>{essentialsDescription}</p>
                                    <Divider margin={2} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row center="xs">
                        {essentials.length &&
                            essentials.map(
                                (
                                    {
                                        stay_barn_room_essentials_item_title: title,
                                        stay_barn_room_essentials_item_image: image,
                                    },
                                    index
                                ) => (
                                    <Col xs={6} sm={4} md={2} key={`${title}_${index}`}>
                                        <div className={styles.essential}>
                                            <div className={styles.essentialImage}>
                                                <Img
                                                    className={
                                                        styles.essentialImageGatsbyImageWrapper
                                                    }
                                                    alt={`${title}'s image ${index + 1}`}
                                                    fluid={image.childImageSharp.fluid}
                                                />
                                            </div>
                                            <p>
                                                <b>{title}</b>
                                            </p>
                                        </div>
                                    </Col>
                                )
                            )}
                    </Row>
                </div>
                <Divider margin={4} />
                <div className={styles.features}>
                    <Row center="xs">
                        <Col xs={12}>
                            <h2>{featuresTitle}</h2>
                            <Row center="xs">
                                <Col xs={12} md={8}>
                                    <p>{featuresDescription}</p>
                                    <Divider margin={2} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {!!features.length &&
                                features.map(
                                    (
                                        {
                                            stay_barn_room_features_item_title: title,
                                            stay_barn_room_features_item_text: text,
                                        },
                                        index
                                    ) => (
                                        <Accordion
                                            key={`${title}_${index}`}
                                            title={title}
                                            text={text}
                                        />
                                    )
                                )}
                        </Col>
                    </Row>
                </div>
            </Grid>

            {/* Barn reviews start here*/}

            {reviews?.length && (
                <Grid>
                    <Slider
                        {...settings}
                        ref={e => {
                            if (e) setSlickSlider(e);
                        }}
                    >
                        {reviews?.map(({ review_writer, review_content, review_rating }) => {
                            let reviewStarsArray = [];
                            for (let i = 1; i <= review_rating; i++) {
                                reviewStarsArray.push(i);
                            }
                            return (
                                <div className={styles.ReviewCard}>
                                    <p>{review_content}</p>
                                    <Divider margin={4} />
                                    <h4>{review_writer}</h4>

                                    <Stack direction="row">
                                        {reviewStarsArray.map((star, i) => (
                                            <div className={styles.IconContainer}>
                                                <FontAwesomeIcon icon={['fas', 'star']} />
                                            </div>
                                        ))}
                                    </Stack>
                                </div>
                            );
                        })}
                    </Slider>
                </Grid>
            )}

            {/* Barn reviews start here*/}

            <Divider margin={4} />

            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>{availabilityCallToAction.description}</p>
                    <Button link={availabilityCallToAction.link}>
                        {availabilityCallToAction.text}
                    </Button>
                </Col>
            </Row>

            <Divider margin={4} />

            {callToActions && (
                <CallToActions>
                    {callToActions.map((callToAction, index) => (
                        <CallToActions.CallToAction
                            key={`${callToAction.title}_${index}`}
                            {...callToAction}
                        />
                    ))}
                </CallToActions>
            )}
        </Layout>
    );
};

export default Barn;

export const query = graphql`
    query BarnQuery($slug: String!) {
        barn: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            frontmatter {
                slug
                stay_barn_room_title
                stay_barn_room_seo_title
                stay_barn_room_seo_description
                stay_barn_room_category
                stay_barn_room_description
                stay_barn_room_guests
                stay_barn_room_bedrooms
                stay_barn_room_bathrooms
                stay_barn_room_images {
                    childImageSharp {
                        fluid(maxWidth: 555) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                stay_barn_room_mews_id
                stay_barn_room_button_text
                stay_barn_room_essentials_title
                stay_barn_room_essentials_description
                stay_barn_room_essentials_items {
                    stay_barn_room_essentials_item_title
                    stay_barn_room_essentials_item_image {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                stay_barn_room_features_title
                stay_barn_room_features_description
                stay_barn_room_features_items {
                    stay_barn_room_features_item_title
                    stay_barn_room_features_item_text
                }
                reviews {
                    review_content
                    review_writer
                    review_rating
                }
            }
        }
        availabilityCallToAction: markdownRemark(
            fileAbsolutePath: { regex: "/data/barns-and-rooms.md/" }
        ) {
            frontmatter {
                description: stay_barns_and_rooms_individual_call_to_action_description
                text: stay_barns_and_rooms_individual_call_to_action_button_text
                link: stay_barns_and_rooms_individual_call_to_action_button_link
            }
        }
        callToActions: markdownRemark(fileAbsolutePath: { regex: "/data/stay.md/" }) {
            frontmatter {
                callToActions: stay_call_to_actions {
                    title: stay_call_to_actions_title
                    description: stay_call_to_actions_description
                    buttonText: stay_call_to_actions_button_text
                    buttonLink: stay_call_to_actions_button_link
                    image: stay_call_to_actions_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
