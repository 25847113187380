import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { findByType } from '@helpers';

// card sub components
import Image from './Image';
import Title from './Title';
import Content from './Content';
import Actions from './Actions';
import List from './List';
import Position from './Position';

import styles from './styles.module.scss';

const Card = ({
    onClick = null,
    hoverable = false,
    depth = 0,
    children = null,
    center = false,
    className = '',
    inverse = false,
    disabled = false,
    loading = false,
    fullHeight = false,
}) => {
    const cardTitle = findByType(children, Title, true);
    const cardImage = findByType(children, Image, true);
    const cardContent = findByType(children, Content, true, { loading });
    const cardActions = findByType(children, Actions, true);
    const cardList = findByType(children, List, false, { loading });
    const cardTopLeft = findByType(children, Position.Top.Left, true);
    const cardTopRight = findByType(children, Position.Top.Right, true);
    const cardBottomLeft = findByType(children, Position.Bottom.Left, true);
    const cardBottomRight = findByType(children, Position.Bottom.Right, true);
    const depthClass =
        (depth === 0 && styles.cardDepthNone) ||
        (depth === 1 && styles.cardDepthBottom) ||
        (depth === 2 && styles.cardDepthMiddle) ||
        (depth === 3 && styles.cardDepthTop);
    const centerClass = center ? styles.cardCenter : '';
    const inverseClass = inverse ? styles.cardInverse : '';
    const hoverableClass = !disabled && (hoverable || onClick) ? styles.cardHoverable : '';
    const disabledClass = disabled ? styles.cardDisabled : '';
    const fullHeightClass = fullHeight ? styles.cardFullHeight : '';

    return (
        <Fragment>
            <div
                className={`${styles.card} ${fullHeightClass} ${hoverableClass} ${disabledClass} ${centerClass} ${depthClass} ${inverseClass} ${className}`}
                onClick={disabled ? undefined : onClick}
                tabIndex={!disabled && (hoverable || onClick) ? 0 : null}
                onKeyPress={e =>
                    !disabled && onClick && (e.keyCode || e.charCode) === 13 ? onClick() : undefined
                }
            >
                {cardImage}
                {cardTopLeft || cardTopRight ? (
                    <div className={`${styles.cardPositionEnd} ${styles.cardPositionTop}`}>
                        {cardTopLeft} {cardTopRight}
                    </div>
                ) : null}
                {cardTitle}
                {cardContent}
                {cardList}
                {cardActions}
                {cardBottomLeft || cardBottomRight ? (
                    <div className={`${styles.cardPositionEnd} ${styles.cardPositionBottom}`}>
                        {cardBottomLeft} {cardBottomRight}
                    </div>
                ) : null}
            </div>
            {(hoverable || onClick) && <div className={styles.cardOverlay} />}
        </Fragment>
    );
};

Card.propTypes = {
    onClick: PropTypes.func,
    hoverable: PropTypes.bool,
    depth: PropTypes.number,
};

Card.Image = Image;
Card.Title = Title;
Card.Content = Content;
Card.Actions = Actions;
Card.List = List;
Card.Position = Position;

export default Card;
