import React from 'react';

import styles from './styles.module.scss';
import { findByType } from '../../helpers';

const Slider = ({ children, noPadding, size = 'medium' }) => (
    <div
        style={{ paddingLeft: noPadding && 0 }}
        className={`${styles.slider} ${size === 'large' ? styles.sliderLarge : ''}`}
    >
        {findByType(children, Item)}
    </div>
);

const Item = ({ children, width }) => {
    return (
        <div style={{ width: width && width }} className={styles.sliderItem}>
            {children}
        </div>
    );
};

Slider.Item = Item;
Item.displayName = 'Item';

export default Slider;
