import React from 'react';

import { Grid, Row, Col } from '@components/Grid';
import Hero from '@components/Hero';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';

import styles from './styles.module.scss';
import SEO from "@components/SEO";

const KitchenGarden = ({
    data: {
        page: {
            frontmatter: { path, heroTitle, heroDescription, heroImage, text },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Kitchen Garden | Restaurants In Ipswich | Retreat East"
                description="Discover our Kitchen Garden, the place where we grow a range of produce to use in our restaurant. You can then sample the produce in our restaurant."
            />
            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb title="Dine" link="/dine" />
                <Breadcrumbs.Breadcrumb title={heroTitle} link={path} />
            </Breadcrumbs>
            <Hero image={heroImage}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>
            <Grid>
                <Row>
                    <Col xs>
                        <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default KitchenGarden;

export const query = () => graphql`
    query KitchenGardenQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                heroTitle: dine_kitchen_garden_hero_title
                heroDescription: dine_kitchen_garden_hero_description
                heroImage: dine_kitchen_garden_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 555) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                text: dine_kitchen_garden_text
            }
        }
    }
`;
