import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import CallToActions from '@components/CallToActions';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { Divider } from '../../components/Grid';
import SEO from '@components/SEO';
import useScript from '@helpers/hooks/useScript';
import Accordion from '@components/Accordion';

const Dine = ({
    data: {
        page: {
            frontmatter: {
                heroTitle,
                heroDescription,
                heroButtonLink,
                heroButtonText,
                heroImage,
                callToActions,
                faqs,
            },
        },
        menus: { edges: menus },
    },
}) => {
    useScript('/resdiary-booking.js');

    return (
        <Layout>
            <SEO
                title="Dine | Restaurants In Ipswich | Retreat East"
                description="Find out more about the dining options at our Restaurant in Ipswich. Enjoy delicious local produce cooked by our team of expert chefs."
            />

            <Hero
                image={heroImage}
                imageFullWidth
                imageStyle={{ objectPosition: '50% 60%' }}
                heroStyle={{ overflow: 'visible' }}
            >
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>

                {/*{!!heroButtonLink && !!heroButtonText && (*/}
                {/*    <a href={heroButtonLink} rel="noopener noreferrer">{heroButtonText}</a>*/}
                {/*)}*/}

                <div id="rd-widget-frame" style={{ maxWidth: 731, margin: 'auto' }} />
                <input
                    id="rdwidgeturl"
                    name="rdwidgeturl"
                    value="https://booking.resdiary.com/widget/Mini/Landscape/RetreatEast/21476?forceBookButton=false"
                    type="hidden"
                />

                {/*<iframe src="https://booking.resdiary.com/widget/Mini/Landscape/RetreatEast/21476" frameborder="0" />*/}
            </Hero>

            <Grid>
                {menus.map(({ node: { frontmatter: { path, name, text, image } } }, index) => (
                    <Fragment key={path}>
                        <div className={styles.menu}>
                            <Row middle="xs" reverse={index % 2 === 0}>
                                <Col xs={12} md={6}>
                                    <div className={styles.menuContent}>
                                        <h2>{name}</h2>
                                        <p>{text}</p>
                                        <Button link={path}>View Menu</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Img
                                        className={styles.menuImage}
                                        fluid={image.childImageSharp.fluid}
                                        alt={`Dine image ${index + 1}`}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Divider margin={4} />
                    </Fragment>
                ))}
            </Grid>

            {!!faqs?.length &&
                faqs.map(({ question, answer }, index) => (
                    <Grid>
                        <Accordion key={`${question}_${index}`} title={question} text={answer} />
                    </Grid>
                ))}

            {callToActions && (
                <CallToActions>
                    {callToActions.map(callToAction => (
                        <CallToActions.CallToAction key={callToAction.title} {...callToAction} />
                    ))}
                </CallToActions>
            )}
        </Layout>
    );
};

export default Dine;

export const query = () => graphql`
    query DineQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: dine_hero_title
                heroDescription: dine_hero_description
                heroImage: dine_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                faqs: dine_faqs_items {
                    question: dine_faqs_items_question
                    answer: dine_faqs_items_answer
                }
                heroButtonText: dine_hero_button_text
                heroButtonLink: dine_hero_button_link
                callToActions: dine_call_to_actions {
                    title: dine_call_to_actions_title
                    description: dine_call_to_actions_description
                    buttonText: dine_call_to_actions_button_text
                    buttonLink: dine_call_to_actions_button_link
                    image: dine_call_to_actions_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        menus: allMarkdownRemark(filter: { frontmatter: {hidden: { ne: true }}, fileAbsolutePath: { regex: "/data/menus/"} }) {
            edges {
                node {
                    frontmatter {
                        path
                        name: menu_name
                        text: menu_overview_text
                        image: menu_overview_image {
                            childImageSharp {
                                fluid(maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
