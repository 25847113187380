import React, { useEffect, useState, Fragment } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { graphql, navigate, Link } from 'gatsby';
import ReactMapGL, { Marker } from 'react-map-gl';
import Layout from '@components/Layout';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';
import { Divider } from '@components/Grid';
import Card from '@components/Card';
import CallToActions from '@components/CallToActions';
import { makePostRequest } from '@helpers/requests';
import { useSnackbar } from '@components/Snackbar';
import { FORM } from '@helpers/api';
import styles from './styles.module.scss';
import SEO from '@components/SEO';
import Slider from '@components/Slider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '@components/Input';
import Select from '@components/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import Img from 'gatsby-image';
import VideoPlayer from '@components/VideoPlayer';
import Modal from '@components/Modal';
import GatsbyImage from 'gatsby-image';
import { Container, Stack } from '@mui/material';
import Faqs from "./faqs";
import Accordion from "@components/Accordion";

const WeddingsHome = ({
    data: {
        form,
        page: {
            frontmatter: {
                heroImage,
                heroTitle,
                heroHeading,
                heroButtonText,
                heroBackground,
                heroButtonLink,
                introductionTitle,
                introductionDescription,
                accommodationTitle,
                accommodationDescription,
                introductionButtonText,
                introductionButtonLink,
                items,
                callToActions,
                testimonials,
                faqs,
            },
        },
        file,
    },
}) => {
    const [heroTitleTop, setHeroTitleTop] = useState(0);
    const [heroTitleBlur, setHeroTitleBlur] = useState(0);
    const [openItem, setOpenItem] = useState(null);

    useEffect(() => {

        console.log('faqs', faqs);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        setHeroTitleTop(window.pageYOffset / 5);
        setHeroTitleBlur(window.pageYOffset / 200);
    };

    const [openSnackbar] = useSnackbar();

    const handleSubmit = async (values, actions) => {
        try {
            const postData = {
                formId: form?._id,
                sections: Object.keys(values).map(sectionId => {
                    const formSection = form?.sections?.find(({ _id }) => _id === sectionId);

                    let sectionData = {
                        _id: sectionId,
                        fields: [],
                    };

                    for (let fieldId in values[sectionId]) {
                        if (values[sectionId].hasOwnProperty(fieldId)) {
                            const formSectionField = formSection?.fields?.find(
                                ({ _id }) => _id === fieldId
                            );

                            sectionData.fields.push({
                                _id: fieldId,
                                value:
                                    formSectionField?.type === 'dropdown' ||
                                    formSectionField?.type === 'radio'
                                        ? formSectionField?.options?.find(
                                              ({ _id }) => _id === values[sectionId][fieldId]
                                          )?.label
                                        : values[sectionId][fieldId],
                            });
                        }
                    }

                    return sectionData;
                }),
            };

            const { data: formData } = await makePostRequest(FORM, postData);
            openSnackbar('Your application form was successfully submitted.');
            actions?.resetForm();
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred submitting this form, please try clearing your cache or try again later.'
                );
        }
    };

    const getFields = () => {
        const sections = {};

        for (let section of form?.sections) {
            sections[section._id] = {};

            for (let field of section?.fields) {
                sections[section._id][field?._id] = undefined;
            }
        }

        return sections;
    };

    const renderValidationField = ({ type, label, optional, validation }) => {
        let value;

        switch (type) {
            case 'string':
                value = Yup.string();
                if (validation?.type === 'text' && validation?.condition === 'email') {
                    value = value.email(validation?.error ?? 'Must be an email address.');
                }
                if (!optional) value = value.required(`${label} is required.`);
                return value;
            case 'paragraph':
            case 'dropdown':
                // case 'radio':
                value = Yup.string();
                if (!optional) value = value.required(`${label} is required.`);
                return value;
            // case 'checkbox':
            //     value = Yup.array().of(Yup.string());
            //     if (!optional) value = value.required(`${label} is required.`);
            //     return value;
        }

        return Yup.mixed().nullable();
    };

    const renderValidationFields = () => {
        const schema = {};

        Object.keys(form?.sections).forEach(sectionKey => {
            const section = form?.sections[sectionKey];
            const sectionId = section?._id;

            const fieldSchema = {};

            Object.keys(section?.fields).forEach(fieldKey => {
                const field = section?.fields[fieldKey];
                const fieldId = field?._id;

                fieldSchema[fieldId] = renderValidationField(field);
            });

            schema[sectionId] = Yup.object().shape(fieldSchema);
        });

        return Yup.object().shape(schema);
    };
    return (
        <Layout className="page-home">
            <SEO
                title="Weddings | Wedding Venues Suffolk | Retreat East"
                description="Retreat East is a stunning wedding venue in Suffolk, find out more about at Weddings on our website. We look forward to being part of your big day."
            />
            <div className={styles.hero}>
                <Stack className={styles.heroContent}>
                    <div style={{ marginBottom: 'auto' }} />

                    <div
                        className={styles.heroContentOffset}
                        style={{
                            transform: `translateY(${heroTitleTop}px)`,
                            filter: `blur(${heroTitleBlur}px)`,
                        }}
                    >
                        <Container>
                            <Stack justifyContent="center" alignItems="center">
                                <h1>{heroHeading}</h1>
                                <Divider margin={3} />
                                <div>
                                    <Button link={heroButtonLink}>{heroButtonText}</Button>
                                </div>
                            </Stack>
                        </Container>
                    </div>

                    <div style={{ marginTop: 'auto' }}>
                        <a
                            alt="Retreat East Award 2023"
                            href="https://www.thetimes.co.uk/travel/destinations/uk/england/suffolk/review/retreat-east"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                fluid={file?.childImageSharp?.fluid}
                                className={styles.awardsImage}
                            />
                        </a>

                        <Link to="/" className={styles.heroBack}>
                            <span>
                                <FontAwesomeIcon size="lg" icon={faChevronLeft} />
                            </span>

                            <p> Go back to Retreat East</p>
                        </Link>
                    </div>
                </Stack>

                <div className={styles.heroVideoOverlay} />
                {isBrowser ? (
                    <VideoPlayer url={'/videos/wedding/output.m3u8'} />
                ) : (
                    <div className={styles.heroImage}>
                        <Img fluid={heroImage?.childImageSharp?.fluid} />
                    </div>
                )}
            </div>

            <div className={styles.introduction}>
                <Grid>
                    <Row center="xs">
                        <Col xs={12}>
                            <h2>{introductionTitle}</h2>
                            <p>{introductionDescription}</p>
                            <Divider />
                            <Button secondary link={introductionButtonLink}>
                                {introductionButtonText}
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Divider margin={4} />

            <Slider noPadding>
                {testimonials?.map(({ title, description, image }, index) => (
                    <Slider.Item key={`${title}_${index}`} width={700}>
                        <Card
                            className={styles.testimonialSliderCard}
                            hoverable
                            onClick={() => setOpenItem({ image, title, description })}
                        >
                            <Card.Content className={styles.testimonialSliderCardContent}>
                                <div>
                                    <Card.Title multiline small>
                                        {title}
                                    </Card.Title>
                                    <p>
                                        {description.length > 115
                                            ? `${description
                                                  .substr(0, 115)
                                                  .replace(/<br\s*[\/]?>/gi, '')}...`
                                            : description.replace(/<br\s*[\/]?>/gi, '')}
                                    </p>
                                </div>
                            </Card.Content>
                            <Card.Image height={300} fluid={image.childImageSharp.fluid} />
                        </Card>
                    </Slider.Item>
                ))}
            </Slider>

            <Divider margin={4} />
            <Slider noPadding>
                {items.map(({ image }, index) => (
                    <Slider.Item width={350} key={`item_${index}`}>
                        <Card>
                            <Card.Image height={350} fluid={image.childImageSharp.fluid} />
                        </Card>
                    </Slider.Item>
                ))}
            </Slider>
            <Divider margin={4} />

            {callToActions && (
                <CallToActions>
                    {callToActions.map(props => (
                        <CallToActions.CallToAction key={props.title} {...props} />
                    ))}
                </CallToActions>
            )}

            <div className={styles.introduction}>
                <Grid>
                    <Row center="xs">
                        <Col xs={12}>
                            <h2>{accommodationTitle}</h2>
                            <p dangerouslySetInnerHTML={{__html: accommodationDescription}}/>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Divider margin={4} />

            <Row>
                <Col xs={12} md={6} noGutters>
                    <ReactMapGL
                        mapStyle="mapbox://styles/mapbox/outdoors-v10?optimize=true"
                        mapboxApiAccessToken="pk.eyJ1IjoiYmlncml2YWwiLCJhIjoiY2phMzRsanUzMHV6ajJxbGZzdDZsamtqayJ9.PngeT-c1grgQoIMXMhkBUA"
                        zoom={13}
                        longitude={1.121334}
                        latitude={52.134348}
                        height="100%"
                        width="100%"
                    >
                        <Marker
                            longitude={1.121334}
                            latitude={52.134348}
                            offsetLeft={-19}
                            offsetTop={-8}
                        >
                            <FontAwesomeIcon className={styles.marker} icon="map-marker-alt" />
                        </Marker>
                    </ReactMapGL>
                </Col>
                <Col xs={12} md={6} noGutters>
                    <div className={styles.contactMain}>
                        <h2>Lets Talk</h2>

                        <p>Extra venue Options</p>
                        <Formik
                            initialValues={getFields()}
                            validationSchema={renderValidationFields()}
                            onSubmit={handleSubmit}
                        >
                            {props => (
                                <Fragment>
                                    <Row>
                                        <Col xs={12}>
                                            <ContactFormikForm {...props} form={form} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Button
                                                disabled={props.isSubmitting}
                                                onClick={props.handleSubmit}
                                            >
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </Formik>
                    </div>
                </Col>
            </Row>

            <Container>
            <Divider margin={5}/>
                {!!faqs && <h2>Commonly asked questions</h2>}
                {!!faqs &&
                    faqs.map(({ question, answer }, index) => {
                        return <Accordion key={index} title={question} text={answer} />;
                    })}
                <p>For further information, visit our <a href='https://retreateast.co.uk/weddings/faqs'>FAQ page</a>.</p>
            <Divider margin={5}/>
            </Container>

            <Modal isOpen={openItem} onClose={() => setOpenItem(null)} width={700}>
                <Modal.Image image={openItem?.image} />
                <Modal.Title>{openItem?.title}</Modal.Title>
                <Modal.Content truncateHtml html={openItem?.description} />
            </Modal>
        </Layout>
    );
};
const ContactFormikForm = ({ values, handleChange, setFieldValue, touched, errors, form }) => {
    // const [messageRows, setMessageRows] = useState(0);
    // const handleMessageChange = (e, { value, rows }) => {
    //     setFieldValue('message', value);
    //     setMessageRows(rows);
    // };

    return (
        <div>
            {form?.sections?.map(section => (
                <Fragment key={section?._id}>
                    {section?.fields?.map(field => {
                        const { _id, type, hint, label, optional } = field;
                        const name = `${section._id}.${_id}`;

                        const fieldProps = {
                            name,
                            optional: optional,
                            label: label,
                            hint: hint,
                            value: values?.[section?._id]?.[_id],
                            onChange: handleChange,
                            id: _id,
                        };

                        return (
                            <Fragment key={_id}>
                                {type === 'string' && <Input {...fieldProps} />}
                                {type === 'paragraph' && <Input {...fieldProps} textarea />}
                                {/*{type === 'radio' && <Radios {...fieldProps} />}*/}
                                {/*{type === 'checkbox' && <Checkboxes {...fieldProps} />}*/}
                                {type === 'dropdown' && (
                                    <Select {...fieldProps}>
                                        {field?.options?.map(option => (
                                            <Select.Option
                                                key={option?._id}
                                                value={option?._id}
                                                label={option?.label}
                                            />
                                        ))}
                                    </Select>
                                )}

                                {touched?.[section?._id]?.[_id] &&
                                    errors?.[section?._id]?.[_id] && (
                                        <Alert
                                            type="error"
                                            message={errors?.[section?._id]?.[_id]}
                                        />
                                    )}
                            </Fragment>
                        );
                    })}
                </Fragment>
            ))}
        </div>
    );
};
export default WeddingsHome;

export const query = graphql`
    query WeddingsHomeQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroImage: weddings_home_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroTitle: weddings_home_hero_title
                heroHeading: weddings_home_hero_heading
                heroButtonText: weddings_home_hero_button_text
                heroBackground: weddings_home_hero_background {
                    childImageSharp {
                        fluid(maxHeight: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroButtonLink: weddings_home_hero_button_link
                introductionTitle: weddings_home_introduction_title
                introductionDescription: weddings_home_introduction_description
                introductionButtonText: weddings_home_introduction_button_text
                introductionButtonLink: weddings_home_introduction_button_link
                accommodationTitle: weddings_home_accommodation_title
                accommodationDescription: weddings_home_accommodation_description
                items: weddings_home_items {
                    image: weddings_home_items_image {
                        childImageSharp {
                            fluid(maxHeight: 340) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                testimonials: weddings_home_testimonials {
                    title: weddings_home_testimonials_testimonial_title
                    description: weddings_home_testimonials_testimonial_description
                    image: weddings_home_testimonials_testimonial_image {
                        childImageSharp {
                            fluid(maxWidth: 430) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                faqs: weddings_faqs {
                    question: weddings_faq_title
                    answer: weddings_faq_answer
                }
                
                callToActions: weddings_home_call_to_actions {
                    title: weddings_home_call_to_actions_title
                    description: weddings_home_call_to_actions_description
                    buttonText: weddings_home_call_to_actions_button_text
                    buttonLink: weddings_home_call_to_actions_button_link
                    image: weddings_home_call_to_actions_image {
                        childImageSharp {
                            fluid(maxHeight: 900) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        form: merlinForm(_id: { eq: "5f329b07c2fe1b001715eec6" }) {
            _id
            name
            description
            meta {
                updatedTimestamp
            }
            sections {
                _id
                title
                description
                multiple
                min
                max
                fields {
                    label
                    hint
                    optional
                    options {
                        _id
                        label
                    }
                    validation {
                        type
                        condition
                        value
                        error
                    }
                    type
                    placeholder
                    id
                    _id
                }
            }
        }
        file(relativePath: { eq: "award-the-sunday-times-awards-best-places-to-stay-2023.png" }) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
