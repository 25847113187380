// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-index-js": () => import("./../../../src/pages/awards/index.js" /* webpackChunkName: "component---src-pages-awards-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-book-index-js": () => import("./../../../src/pages/book/index.js" /* webpackChunkName: "component---src-pages-book-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-dine-index-js": () => import("./../../../src/pages/dine/index.js" /* webpackChunkName: "component---src-pages-dine-index-js" */),
  "component---src-pages-dine-kitchen-garden-index-js": () => import("./../../../src/pages/dine/kitchen-garden/index.js" /* webpackChunkName: "component---src-pages-dine-kitchen-garden-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-explore-index-js": () => import("./../../../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-explore-local-area-index-js": () => import("./../../../src/pages/explore/local-area/index.js" /* webpackChunkName: "component---src-pages-explore-local-area-index-js" */),
  "component---src-pages-explore-our-gin-index-js": () => import("./../../../src/pages/explore/our-gin/index.js" /* webpackChunkName: "component---src-pages-explore-our-gin-index-js" */),
  "component---src-pages-explore-our-story-index-js": () => import("./../../../src/pages/explore/our-story/index.js" /* webpackChunkName: "component---src-pages-explore-our-story-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-house-rules-index-js": () => import("./../../../src/pages/house-rules/index.js" /* webpackChunkName: "component---src-pages-house-rules-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-index-js": () => import("./../../../src/pages/meet-the-team/index.js" /* webpackChunkName: "component---src-pages-meet-the-team-index-js" */),
  "component---src-pages-offers-index-js": () => import("./../../../src/pages/offers/index.js" /* webpackChunkName: "component---src-pages-offers-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-shop-basket-index-js": () => import("./../../../src/pages/shop/basket/index.js" /* webpackChunkName: "component---src-pages-shop-basket-index-js" */),
  "component---src-pages-shop-checkout-index-js": () => import("./../../../src/pages/shop/checkout/index.js" /* webpackChunkName: "component---src-pages-shop-checkout-index-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-spa-index-js": () => import("./../../../src/pages/spa/index.js" /* webpackChunkName: "component---src-pages-spa-index-js" */),
  "component---src-pages-spa-treatments-retreat-for-men-js": () => import("./../../../src/pages/spa/treatments/retreat-for-men.js" /* webpackChunkName: "component---src-pages-spa-treatments-retreat-for-men-js" */),
  "component---src-pages-stay-barns-and-rooms-index-js": () => import("./../../../src/pages/stay/barns-and-rooms/index.js" /* webpackChunkName: "component---src-pages-stay-barns-and-rooms-index-js" */),
  "component---src-pages-stay-index-js": () => import("./../../../src/pages/stay/index.js" /* webpackChunkName: "component---src-pages-stay-index-js" */),
  "component---src-pages-sustainability-index-js": () => import("./../../../src/pages/sustainability/index.js" /* webpackChunkName: "component---src-pages-sustainability-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-weddings-accommodation-index-js": () => import("./../../../src/pages/weddings/accommodation/index.js" /* webpackChunkName: "component---src-pages-weddings-accommodation-index-js" */),
  "component---src-pages-weddings-activities-index-js": () => import("./../../../src/pages/weddings/activities/index.js" /* webpackChunkName: "component---src-pages-weddings-activities-index-js" */),
  "component---src-pages-weddings-ceremonies-index-js": () => import("./../../../src/pages/weddings/ceremonies/index.js" /* webpackChunkName: "component---src-pages-weddings-ceremonies-index-js" */),
  "component---src-pages-weddings-contact-us-index-js": () => import("./../../../src/pages/weddings/contact-us/index.js" /* webpackChunkName: "component---src-pages-weddings-contact-us-index-js" */),
  "component---src-pages-weddings-faqs-index-js": () => import("./../../../src/pages/weddings/faqs/index.js" /* webpackChunkName: "component---src-pages-weddings-faqs-index-js" */),
  "component---src-pages-weddings-food-and-drinks-index-js": () => import("./../../../src/pages/weddings/food-and-drinks/index.js" /* webpackChunkName: "component---src-pages-weddings-food-and-drinks-index-js" */),
  "component---src-pages-weddings-gallery-index-js": () => import("./../../../src/pages/weddings/gallery/index.js" /* webpackChunkName: "component---src-pages-weddings-gallery-index-js" */),
  "component---src-pages-weddings-index-js": () => import("./../../../src/pages/weddings/index.js" /* webpackChunkName: "component---src-pages-weddings-index-js" */),
  "component---src-pages-weddings-special-packages-index-js": () => import("./../../../src/pages/weddings/special-packages/index.js" /* webpackChunkName: "component---src-pages-weddings-special-packages-index-js" */),
  "component---src-pages-whats-on-index-js": () => import("./../../../src/pages/whats-on/index.js" /* webpackChunkName: "component---src-pages-whats-on-index-js" */),
  "component---src-templates-accommodation-index-js": () => import("./../../../src/templates/accommodation/index.js" /* webpackChunkName: "component---src-templates-accommodation-index-js" */),
  "component---src-templates-barn-room-index-js": () => import("./../../../src/templates/barn-room/index.js" /* webpackChunkName: "component---src-templates-barn-room-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-ceremony-index-js": () => import("./../../../src/templates/ceremony/index.js" /* webpackChunkName: "component---src-templates-ceremony-index-js" */),
  "component---src-templates-food-index-js": () => import("./../../../src/templates/food/index.js" /* webpackChunkName: "component---src-templates-food-index-js" */),
  "component---src-templates-ghost-page-index-js": () => import("./../../../src/templates/ghost-page/index.js" /* webpackChunkName: "component---src-templates-ghost-page-index-js" */),
  "component---src-templates-menu-index-js": () => import("./../../../src/templates/menu/index.js" /* webpackChunkName: "component---src-templates-menu-index-js" */),
  "component---src-templates-package-index-js": () => import("./../../../src/templates/package/index.js" /* webpackChunkName: "component---src-templates-package-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-treatments-index-js": () => import("./../../../src/templates/treatments/index.js" /* webpackChunkName: "component---src-templates-treatments-index-js" */)
}

