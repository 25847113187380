import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { config, library } from '@fortawesome/fontawesome-svg-core';
// import styling manually
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
    faAllergies,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faCircleNotch,
    faClock,
    faExclamationCircle,
    faExclamationTriangle,
    faMapMarkerAlt,
    faMinus,
    faPlus,
    faTimes,
    faRefresh,
    faExpand,
    faStar,
} from '@fortawesome/pro-solid-svg-icons';
import { useLocation, useNavigate } from '@reach/router';

import { faArrowLeft, faShoppingBasket, faBookArrowRight } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import SEO from '@components/SEO';
// main site style
import '@styles/style.scss';

import Navigation from '@components/Navigation';
import Footer from '@components/Footer';
import Basket from '@components/Basket';
import { bakeLocalStorage, readLocalStorage } from '@helpers/storage';
import Modal from '@components/Modal';
import Button from '@components/Button';
import useScript from '@helpers/hooks/useScript';
import queryString from 'query-string';
import { graphql, useStaticQuery } from 'gatsby';

library.add(
    faExclamationTriangle,
    faCaretDown,
    faChevronDown,
    faChevronUp,
    faPlus,
    faMinus,
    faChevronLeft,
    faChevronRight,
    faClock,
    faAllergies,
    faExclamationCircle,
    faMapMarkerAlt,
    faTimes,
    faCheck,
    faCheckCircle,
    faCircleNotch,
    fab,
    faStar,

    faArrowLeft,
    faShoppingBasket,
    faBookArrowRight,
    faExpand
);

// prevent oversizes icons on page load
config.autoAddCss = false;

const Layout = ({ children, className = '', user }) => {
    const navigate = useNavigate();
    const $main = useRef(null);
    const $navigation = useRef(null);
    const [showDialogInformation, setShowDialogInformation] = useState(false);
    const [mews, setMews] = useState(null);
    const {
        dialogMessage: {
            frontmatter: { dialogMessageTitle, dialogMessageDescription, dialogMessageButtonText },
        },
    } = useStaticQuery(graphql`
        query {
            dialogMessage: markdownRemark(fileAbsolutePath: { regex: "/dialog-message/" }) {
                frontmatter {
                    dialogMessageTitle: dialog_message_title
                    dialogMessageDescription: dialog_message_description
                    dialogMessageButtonText: dialog_message_button_text
                }
            }
        }
    `);
    const location = useLocation();
    const params = queryString.parse(location.search, { parseBooleans: true });
    const scriptLoaded = useScript('https://api.mews.com/distributor/distributor.min.js');
    const dialogKey = (dialogMessageTitle ?? '')
        .toLowerCase()
        .split(' ')
        .join('-');

    useEffect(() => {
        if (!scriptLoaded || !!mews) return;

        window?.Mews?.Distributor(
            {
                configurationIds: ['dca4b884-4d44-4888-9536-aacc00cf2249'],
            },
            distributor => {
                setMews(distributor);
            }
        );
    }, [scriptLoaded, params?.book, params?.bookingId]);

    useEffect(() => {
        if (!mews || (!params?.book && !params?.bookingId)) return;

        (async () => {
            // remove book/bookingId params once mews has been actioned
            await navigate(
                `${location.pathname}?${queryString.stringify({
                    ...queryString.parse(location.search),
                    book: undefined,
                    bookingId: undefined,
                })}`,
                { replace: true }
            );

            if (!!params?.bookingId) {
                mews?.showRates(params.bookingId);
            }

            mews?.open();
        })();
    }, [mews, params?.book, params?.bookingId]);

    const handlePageChange = ({ action, location: oldLocation }) => {
        const oldParams = queryString.parse(oldLocation?.search);
        const params = queryString.parse(location?.search);

        if (
            !!mews &&
            action === 'POP' &&
            (!!oldParams?.bookingId || !!oldParams?.book) &&
            !params?.bookingId &&
            !params?.book
        ) {
            mews?.close();
        }
    };

    useEffect(() => {
        // if user has not previously already agreed to the dialog information, display it.
        if (!readLocalStorage(`dialog-information-agreed-${dialogKey}`)) {
            setShowDialogInformation(true);
        }

        // return globalHistory.listen(handlePageChange.bind(this));
    }, []);

    const handleDialogInformationAgreed = () => {
        bakeLocalStorage(`dialog-information-agreed-${dialogKey}`, true);
        setShowDialogInformation(false);
    };

    return (
        <div className="app">
            <SEO />
            <div ref={$navigation}>
                <Navigation />
            </div>
            <Basket />
            <main ref={$main}>
                {showDialogInformation &&
                    // ensure title, description and button text exists before showing dialog
                    dialogMessageTitle &&
                    dialogMessageDescription &&
                    dialogMessageButtonText && (
                        <Modal center grid={false} onClose={handleDialogInformationAgreed}>
                            <Modal.Title>{dialogMessageTitle}</Modal.Title>
                            <Modal.Content html={dialogMessageDescription}>
                                <Button
                                    text={dialogMessageButtonText}
                                    onClick={handleDialogInformationAgreed}
                                />
                            </Modal.Content>
                        </Modal>
                    )}
                {children}
                <Footer />
            </main>
        </div>
    );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(Layout);
