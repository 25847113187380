import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import { Grid, Row, Col } from '@components/Grid';

export const PrivacyPolicy = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroText },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="House Rules | Suffolk holidays | Retreat East"
                description="Check out the house rules at Retreat East for when you are on your Suffolk holiday. Here you'll find the rules for our accommodation and more."
            />
            <Hero>
                <h1>{heroTitle}</h1>
            </Hero>
            <Grid>
                <Row>
                    <Col xs>
                        <div dangerouslySetInnerHTML={{ __html: heroText }} />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default PrivacyPolicy;

export const query = () => graphql`
    query HouseRulesQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                heroTitle: house_rules_title
                heroText: house_rules_text
            }
        }
    }
`;
