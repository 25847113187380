import React from 'react';
import {graphql, navigate} from 'gatsby';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import {Col, Divider, Grid, Row} from '@components/Grid';
import Layout from '@components/Layout';
import styles from '../stay/styles.module.scss';
import Card from '@components/Card';
import CallToActions from '@components/CallToActions';

const Packages = ({
    data: {
        page: {
            frontmatter: { title, description, image, callToActions },
        },
        packages: { edges: packages },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="What's on | Retreat East | Ipswich Hotel & Restaurant"
                description="Find out what is on at Retreat East for you to enjoy whilst you are at our Ipswich hotel and restaurant."
            />
            <Hero image={image} imageFullWidth imageStyle={{ objectPosition: '50% 100%' }}>
                <h1>{title}</h1>
                {!!description && <p>{description}</p>}
            </Hero>

            <Grid>
                <Row>
                    {packages.map(
                        ({
                            node: {
                                frontmatter: { path, title, description, images },
                            },
                        }, index) => (
                            <Col xs={12} md={6} key={title}>
                                <div className={styles.package}>
                                    <Card
                                        className={styles.packageCard}
                                        onClick={() => navigate(path)}
                                    >
                                        <Card.Image
                                            height={280}
                                            fluid={images[0].childImageSharp.fluid}
                                            alt={`What's on image ${index + 1}`}
                                        />
                                        <Card.Title multiLine>{title}</Card.Title>
                                        <Card.Content>
                                            <p>{description}</p>
                                        </Card.Content>
                                    </Card>
                                </div>
                            </Col>
                        )
                    )}
                </Row>
            </Grid>

            <Divider />

            {callToActions && (
                <CallToActions>
                    {callToActions.map(callToAction => (
                        <CallToActions.CallToAction key={callToAction.title} {...callToAction} />
                    ))}
                </CallToActions>
            )}
        </Layout>
    );
};

export default Packages;

export const query = graphql`
    query($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: whatson_title
                description: whatson_description
                image: whatson_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                callToActions: whatson_call_to_actions {
                    title: whatson_call_to_actions_title
                    description: whatson_call_to_actions_description
                    buttonText: whatson_call_to_actions_button_text
                    buttonLink: whatson_call_to_actions_button_link
                    image: whatson_call_to_actions_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        packages: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { fileAbsolutePath: { regex: "/data/whats-on/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title: package_title
                        description: package_description
                        images: package_images {
                            childImageSharp {
                                fluid(maxWidth: 560) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
