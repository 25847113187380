import React, { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './styles.module.scss';

const Tooltip = ({ className = '', text, children, placement = 'bottom' }) => {
    const [open, setOpen] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <Fragment>
            <div
                className={className}
                ref={setReferenceElement}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                {children}
            </div>

            {open && (
                <div
                    className={styles.tooltipWrapper}
                    ref={setPopperElement}
                    style={popperStyles.popper}
                    {...attributes.popper}
                >
                    <div className={`${styles.tooltip}`}>{text}</div>
                </div>
            )}
        </Fragment>
    );
};

export default Tooltip;
