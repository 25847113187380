import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import { Grid, Row, Col } from '@components/Grid';

export const TermsAndConditions = ({
    data: {
        page: {
            frontmatter: { title, text },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Terms & Conditions | Spa Hotels Suffolk | Retreat East"
                description="Read our terms and conditions for stays, spa days and more at Retreat East on our website. Here you'll find everything you need to know before booking a stay."
            />
            <Hero>
                <h1>{title}</h1>
            </Hero>
            <Grid>
                <Row>
                    <Col xs>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default TermsAndConditions;

export const query = () => graphql`
    query TermsAndConditionsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: terms_and_conditions_title
                text: terms_and_conditions_text
            }
        }
    }
`;
