import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import { Grid, Row, Col } from '@components/Grid';

export const PrivacyPolicy = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroDescription, text },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Privacy Policy | Retreat East | Spa Retreat Suffolk"
                description="Here you'll find our privacy policy that details how we handle your data. The safe processing of your data is important to the team at Retreat East."
            />
            <Hero>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>
            <Grid>
                <Row>
                    <Col xs>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default PrivacyPolicy;

export const query = () => graphql`
    query PrivacyPolicyQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: privacy_policy_hero_title
                heroDescription: privacy_policy_hero_description
                text: privacy_policy_text
            }
        }
    }
`;
