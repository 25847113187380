import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Button from '@components/Button';
import { Col, Grid, Row, Divider } from '@components/Grid';
import Layout from '@components/Layout';
import CallToActions from '@components/CallToActions';
import Card from '@components/Card';
import Slider from '@components/Slider';
import Hero from '@components/Hero';
import styles from './styles.module.scss';
import SEO from '@components/SEO';
import AccommodationSlider from '@components/AccommodationSlider';
import Accordion from '@components/Accordion';
import { Container, Stack } from '@mui/material';
import { useLocation, useNavigate } from '@reach/router';
import queryString from 'query-string';

const Stays = ({
    data: {
        barnsAndRooms: { edges: barnsAndRooms },
        page: {
            frontmatter: {
                title,
                description,
                descriptionTwo,
                descriptionThree,
                heroImage,
                heroTitle,
                heroDescription,
                heroButtonLink,
                heroButtonText,
                callToActions,
                faqs,
            },
        },
    },
}) => {
    const sortedBarnsAndRooms = barnsAndRooms?.sort(
        ({ node: { frontmatter: a } }, { node: { frontmatter: b } }) =>
            b.guests + b.bedrooms + b.bathrooms - (a.guests + a.bedrooms + a.bathrooms)
    );

    const navigate = useNavigate();
    const location = useLocation();
    const params = queryString.parse(location.search, {
        parseNumbers: true,
    });

    const [currentRoomFilter, setCurrentRoomFilter] = useState('all');

    useEffect(() => {
        const filter = params?.filter ?? 'all';
        setCurrentRoomFilter(filter);
    }, [params]);

    const updateUrl = url => {
        setCurrentRoomFilter(url === 'all' ? 'all' : url);
        return navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                filter: url,
            })}`,
            { replace: true }
        );
    };
    const hasCorrectNumberOfRooms = ({
        node: {
            frontmatter: { guests, bedrooms, bathrooms, title, slug },
        },
    }) => {
        if (currentRoomFilter === 'all') return true;
        if (currentRoomFilter === 'little-bedroom') return slug.includes('shepherds');
        if (currentRoomFilter === 'one-bedroom-barns')
            return guests >= 0 && guests <= 2 && !slug.includes('shepherds');
        if (currentRoomFilter === 'two-bedroom-barns') return guests >= 3 && guests <= 4;
        if (currentRoomFilter === 'four-bedroom-barns') return guests >= 5;
    };

    return (
        <Layout>
            <SEO
                title="Stay | Retreats In UK | Suffolk Holidays | Retreat East"
                description="Looking for a beautiful retreat in the UK? Head to our website and book your stay at Retreat East. Our luxury retreat is the perfect place to recharge."
                canonical="/stay"
            />
            <Hero image={heroImage} imageFullWidth imageStyle={{ objectPosition: '50% 100%' }}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
                <Button link={heroButtonLink}>{heroButtonText}</Button>
            </Hero>

            <Divider margin={4} />

            <Container>
                <Stack direction="row" spacing={0} flexWrap={'wrap'} alignItems="center">
                    <Button
                        className={styles.filterButton}
                        primary={currentRoomFilter === 'all' || currentRoomFilter == null}
                        onClick={() => updateUrl('all')}
                    >
                        <Stack sx={{ gap: 1 }}>Show all</Stack>
                    </Button>
                    <Button
                        className={styles.filterButton}
                        primary={currentRoomFilter === 'little-bedroom'}
                        onClick={() => updateUrl('little-bedroom')}
                    >
                        <Stack sx={{ gap: 1 }}>
                            Shepherd's Huts <span>1-2 Guests</span>
                        </Stack>
                    </Button>
                    <Button
                        className={styles.filterButton}
                        primary={currentRoomFilter === 'one-bedroom-barns'}
                        onClick={() => updateUrl('one-bedroom-barns')}
                    >
                        <Stack sx={{ gap: 1 }}>
                            One Bedroom Barns <span>1-2 Guests</span>
                        </Stack>
                    </Button>
                    <Button
                        className={styles.filterButton}
                        primary={currentRoomFilter === 'two-bedroom-barns'}
                        onClick={() => updateUrl('two-bedroom-barns')}
                    >
                        <Stack sx={{ gap: 1 }}>
                            Two Bedroom Barns <span>3-4 Guests</span>
                        </Stack>
                    </Button>
                    <Button
                        className={styles.filterButton}
                        primary={currentRoomFilter === 'four-bedroom-barns'}
                        onClick={() => {
                            setCurrentRoomFilter('four-bedroom-barns');
                            updateUrl('four-bedroom-barns');
                        }}
                    >
                        <Stack sx={{ gap: 1 }}>
                            Four Bedroom Barns <span>5+ Guests</span>
                        </Stack>
                    </Button>
                </Stack>
            </Container>

            <Divider margin={4} />

            {/* Start of Barn filter section */}

            <Slider>
                {!!currentRoomFilter
                    ? sortedBarnsAndRooms
                          .filter(room => hasCorrectNumberOfRooms(room))
                          ?.map(
                              ({
                                  node: {
                                      frontmatter: { slug, images, title, description },
                                  },
                              }) => (
                                  <Slider.Item key={slug}>
                                      <Card
                                          className={styles.packageCard}
                                          onClick={() => navigate(`/stay/barns-and-rooms/${slug}`)}
                                      >
                                          <Card.Image
                                              height={200}
                                              fluid={images[0].childImageSharp.fluid}
                                          />
                                          <Card.Title multiLine>{title}</Card.Title>
                                          <Card.Content>
                                              <p>{description}</p>
                                          </Card.Content>
                                      </Card>
                                  </Slider.Item>
                              )
                          )
                    : sortedBarnsAndRooms.map(
                          ({
                              node: {
                                  frontmatter: { slug, images, title, description },
                              },
                          }) => (
                              <Slider.Item key={slug}>
                                  <Card
                                      className={styles.packageCard}
                                      onClick={() => navigate(`/stay/barns-and-rooms/${slug}`)}
                                  >
                                      <Card.Image
                                          height={200}
                                          fluid={images[0].childImageSharp.fluid}
                                      />
                                      <Card.Title multiLine>{title}</Card.Title>
                                      <Card.Content>
                                          <p>{description}</p>
                                      </Card.Content>
                                  </Card>
                              </Slider.Item>
                          )
                      )}
            </Slider>

            <Divider margin={5} />

            {!!descriptionTwo && <Container dangerouslySetInnerHTML={{ __html: descriptionTwo }} />}

            <Divider margin={5} />

            <Grid noMargin>
                <h2>{title}</h2>
                <p className={styles.carouselSubtitle}>{description}</p>
            </Grid>

            <Divider margin={2} />

            <AccommodationSlider accommodations={sortedBarnsAndRooms} />

            <Divider margin={5} />

            {!!descriptionThree && (
                <Container dangerouslySetInnerHTML={{ __html: descriptionThree }} />
            )}

            <Divider margin={5} />

            {!!faqs?.length &&
                faqs.map(({ question, answer }, index) => (
                    <Grid noMargin>
                        <Accordion key={`${question}_${index}`} title={question} text={answer} />
                    </Grid>
                ))}

            <Divider margin={4} />

            {callToActions && (
                <CallToActions>
                    {callToActions.map(callToAction => (
                        <CallToActions.CallToAction key={callToAction.title} {...callToAction} />
                    ))}
                </CallToActions>
            )}
        </Layout>
    );
};

export default Stays;

export const query = () => graphql`
    query StaysQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: stay_title
                description: stay_description
                descriptionTwo: stay_description_two
                descriptionThree: stay_description_three
                heroTitle: stay_hero_title
                heroDescription: stay_hero_description
                heroButtonText: stay_hero_button_text
                heroButtonLink: stay_hero_button_link
                heroImage: stay_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                faqs: stay_faqs_items {
                    question: stay_faqs_items_question
                    answer: stay_faqs_items_answer
                }
                callToActions: stay_call_to_actions {
                    title: stay_call_to_actions_title
                    description: stay_call_to_actions_description
                    buttonText: stay_call_to_actions_button_text
                    buttonLink: stay_call_to_actions_button_link
                    image: stay_call_to_actions_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        barnsAndRooms: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { fileAbsolutePath: { regex: "/data/barn-room/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        title: stay_barn_room_title
                        description: stay_barn_room_description
                        images: stay_barn_room_images {
                            childImageSharp {
                                fluid(maxWidth: 750) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        guests: stay_barn_room_guests
                        bedrooms: stay_barn_room_bedrooms
                        bathrooms: stay_barn_room_bathrooms
                    }
                }
            }
        }
    }
`;
