import React from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Hero from '@components/Hero';
import {Col, Grid, Row} from '@components/Grid';
import moment from "moment";

const BlogPost = ({
	                  data: {
		                  page: {
			                  frontmatter: {
				                  blogTitle,
				                  seoTitle,
				                  seoDescription,
				                  blogHeroImage,
				                  blogAuthor,
				                  blogPublished,
				                  blogBody,
				                  blogUrl,
			                  },
		                  },
	                  },
                  }) => {


	const embedUrl = blogUrl ? blogUrl?.replace('watch?v=', 'embed/') : '';

	return (
		<Layout>
			<SEO title={seoTitle} description={seoDescription}/>
			<Hero imageFullWidth image={blogHeroImage}>
				<h1>{blogTitle}</h1>

				<div className={styles.blogHeroMeta}>
					<p>By {blogAuthor}</p> · <p>{moment(new Date(blogPublished)).format("Do MMMM Y")}</p>
				</div>

			</Hero>

			<Grid>
				<Row>
					{!!embedUrl &&
						<Col xs={12}>
							<iframe style={{aspectRatio: '16 / 9', marginBottom: '2rem'}} width='100%'
							        src={embedUrl}
							        title='YouTube video player' frameBorder='0'
							        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
							        allowFullScreen></iframe>
						</Col>
					}

					<Col xs={12}>
						<div className={styles.blogContentWrapper}>
							{!!blogBody && <div dangerouslySetInnerHTML={{__html: blogBody}}/>}
						</div>
					</Col>
				</Row>
			</Grid>
		</Layout>
	);
};

export default BlogPost;

export const query = () => graphql`
    query BlogPostQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                seoTitle: blog_post_seo_title
                seoDescription: blog_post_seo_description
                blogBody: blog_post_body
                blogTitle: blog_post_title
                blogUrl: blog_post_url
                blogHeroImage: blog_post_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                blogTags: blog_post_tags {
                    blog_tag
                }
                blogTagIds: blog_post_tag_ids {
                    blog_tag_id
                }
                blogAuthor: blog_post_author
                blogPublished: blog_post_published
            }
        }
    }
`;
