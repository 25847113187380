import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import useScript from '@helpers/hooks/useScript';
import SEO from "@components/SEO";

const Book = () => {
    const location = useLocation();
    const scriptLoaded = useScript('https://api.mews.com/distributor/distributor.min.js');

    useEffect(() => {
        if (!scriptLoaded) return;

        const params = queryString.parse(location.search);

        window?.Mews?.Distributor(
            {
                configurationIds: ['dca4b884-4d44-4888-9536-aacc00cf2249'],
            },
            distributor => {
                distributor?.open();

                if (!!params?.id) {
                    distributor?.showRates(params.id);
                }
            }
        );
    }, [scriptLoaded]);

    return <div>
        <SEO
            title="Book | Weekend Breaks In Suffolk | Retreat East"
            description="Book your weekend break in Suffolk at Retreat East today, book online via our website. Explore our accommodation, dining options and spa treatments."
        />
    </div>;
};

export default Book;
