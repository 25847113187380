import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';

import Button from '@components/Button';
import { Container, Stack, TextField, Typography } from '@mui/material';
import Input from '@components/Input';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const NewsLetter = () => {
    const [subscribed, setSubscribed] = useState(false);

    useEffect(() => {
        setSubscribed(() => window.location.search.includes('subscribed=true'));
    }, []);

    return (
        <div className="newsLetter">
            <div>
                <div>
                    <Container
                        style={{
                            marginTop: '4rem',
                            marginBottom: '4rem',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        {!subscribed ? (
                            <>
                                <h2>Sign up for our Monthly Newsletter</h2>
                                <Typography variant="body1" sx={{ maxWidth: '700px', my: 3 }}>
                                    At Retreat East, we believe that everyone deserves a little
                                    luxury and relaxation in their lives.
                                    <br />
                                    <br />
                                    By signing up for our newsletter, you'll be the first to know
                                    about our featured monthly activities and have the opportunity
                                    to book your spot before anyone else. Plus, our newsletter is
                                    filled with tips and tricks for achieving ultimate relaxation
                                    and tranquility in your daily life.{' '}
                                </Typography>

                                <a
                                    href="https://confirmsubscription.com/h/j/6F11FAE8B8732440"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button>Signup</Button>
                                </a>
                            </>
                        ) : (
                            <>
                                <h2>Thank you for subscribing to our newsletter.</h2>
                                <p>
                                    We send our emails monthly and never spam, just a curated list
                                    of the best of Retreat East that month.
                                </p>
                            </>
                        )}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default NewsLetter;
