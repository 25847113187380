import '@fortawesome/fontawesome-svg-core/styles.css';
import { useEffect } from 'react';

const RetreatForMen = () => {
    useEffect(() => {
        window.location.href = '/spa/treatments/special-offers';
    }, []);
    return null;
};

export default RetreatForMen;
