import React, { Fragment, useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { Col, Grid, Row } from '@components/Grid';
import Card from '@components/Card';
import { Divider } from '@components/Grid';
import Layout from '@components/Layout';

import styles from './styles.module.scss';
import Hero from '@components/Hero';
import Select from '@components/Select';
import SEO from '@components/SEO';
import Button from '@components/Button';

const Store = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroDescription, heroImage, items },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Gifts | Suffolk Weekend Breaks | Retreat East"
                description="Treat your loved one or yourself to a luxury gift from Retreat East. Choose from a selection of tempting treats including spa day, spa breaks, afternoon tea, treatments and more!"
            />
            <Hero image={heroImage} imageFullWidth>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>

            {items.length && (
                <Grid>
                    {items.map(({ link, title, image, description }, index) => (
                        <Fragment key={title}>
                            <div className={styles.feature}>
                                <Row middle="xs" reverse={index % 2 === 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.featureContent}>
                                            <h2>{title}</h2>
                                            <p>{description}</p>
                                            <Button link={link}>Buy Vouchers</Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Img
                                            className={styles.featureImage}
                                            fluid={image.childImageSharp.fluid}
                                            alt={`Home image ${index + 1}`}
                                        />
                                    </Col>
                                </Row>
                                <Divider margin={4} />
                            </div>
                        </Fragment>
                    ))}
                </Grid>
            )}
        </Layout>
    );
};

export default Store;

export const query = graphql`
    query($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: store_hero_title
                heroDescription: store_hero_description
                heroImage: store_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                path: path
                items: shop_items {
                    link: shop_items_link
                    title: shop_items_title
                    description: shop_items_description
                    image: shop_items_image {
                        childImageSharp {
                            fluid(maxHeight: 340) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
