import React from 'react';
import { graphql } from 'gatsby';
import Hero from '@components/Hero';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Layout from '@components/Layout';
import SEO from '@components/SEO';

const Sustainability = ({
    data: {
        page: {
            frontmatter: { title, text, heroImage },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Sustainability | Retreats in UK | Spa Breaks | Retreat East"
                description="Find out about our sustainable retreats in the UK on our website. Find out more about our garden, sustainable kitchen and more."
            />
            <Hero image={heroImage} imageFullWidth imageAlt={title}>
                <h1>{title}</h1>
            </Hero>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default Sustainability;

export const query = graphql`
    query SustainabilityQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: sustainability_title
                text: sustainability_text
                heroImage: sustainability_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
