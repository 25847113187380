import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combinedReducer from './reducers';

// Grab the state from a global variable injected into the server-generated HTML
let preloadedState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

if (typeof window !== 'undefined') {
    delete window.__PRELOADED_STATE__;
}

// Create store from the combination of our reducers
export default createStore(
    combinedReducer,
    preloadedState,
    compose(
        applyMiddleware(thunk),
        typeof window !== 'undefined' && window.devToolsExtension ? window.devToolsExtension() : f => f,
    ),
);
