import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import usePrevious from '@helpers/hooks/usePrevious';

import styles from './styles.module.scss';

const Basket = ({ basket, fixed = true, inverse }) => {
    const [addingNewItem, setAddingNewItem] = useState(false);
    const [addingNewItemTimeout, setAddingNewItemTimeout] = useState(null);
    const prevBasket = usePrevious(basket);
    const prevQuantity =
        (prevBasket &&
            prevBasket.items.reduce((accumulator, current) => accumulator + current.quantity, 0)) ||
        0;
    const quantity =
        (basket &&
            basket.items.reduce((accumulator, current) => accumulator + current.quantity, 0)) ||
        0;

    useEffect(() => {
        if (prevBasket !== undefined && quantity > prevQuantity) {
            setAddingNewItem(true);
            setAddingNewItemTimeout(setTimeout(() => setAddingNewItem(false), 300));
        }

        return () => clearTimeout(addingNewItemTimeout);
    }, [basket]);

    if (!basket) return null;

    return (
        <div
            className={`${styles.basket} ${inverse ? styles.basketInverse : ''} ${
                fixed ? styles.basketFixed : ''
            } ${addingNewItem ? styles.basketAdding : ''}`}
        >
            <Link to="/shop/basket" className={styles.basketContent}>
                <span className={styles.basketBadge}>
                    <span>{quantity}</span>
                </span>
                <FontAwesomeIcon icon={['fal', 'shopping-basket']} />
            </Link>
        </div>
    );
};

export default connect(({ basket }) => ({ basket }))(Basket);
