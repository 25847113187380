import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';
import Accordion from '@components/Accordion';
import CallToActions from '@components/CallToActions';
import Hero from '@components/Hero';
import ReactMapGL, { Marker } from 'react-map-gl';
import styles from './styles.module.scss';
import SEO from '@components/SEO';
import Features from '@components/Features';
import FsLightbox from 'fslightbox-react';
const Ceremony = ({ data }) => {
    const { barn: barnData } = data;
    const { frontmatter: barnFrontmatter } = barnData;

    const {
        path,
        ceremonies_ceremony_seo_title: seoTitle,
        ceremonies_ceremony_seo_description: seoDescription,
        ceremonies_ceremony_title: title,
        ceremonies_ceremony_category: category,
        ceremonies_ceremony_description: description,
        ceremonies_ceremony_image: image,
        ceremonies_ceremony_images: images,
        ceremonies_ceremony_button_link: buttonLink,
        ceremonies_ceremony_button_text: buttonText,
        ceremonies_ceremony_guests: guests,
        ceremonies_ceremony_reception_capacity: receptionCapacity,
        ceremonies_ceremony_accomodation: accommodation,
        ceremonies_ceremony_plan_image: planImage,
        ceremonies_ceremony_view_text: viewText,
        ceremonies_ceremony_view_button_text: viewButtonText,
        ceremonies_ceremony_view_button_link: viewButtonLink,
        ceremonies_ceremony_view_contact_text: viewContactText,
        ceremonies_ceremony_view_contact_link: viewContactLink,
    } = barnFrontmatter;
    const onlyImages = images.map(image => {
        return image?.childImageSharp?.fluid?.src;
    });
    const [viewOpen, setViewOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = event => {
        setCurrentImage(event);
        setViewOpen(!viewOpen);
    };

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <FsLightbox
                type="image"
                toggler={viewOpen}
                sources={onlyImages}
                slide={currentImage + 1}
            />
            <Hero
                images={images}
                imageWrapperStyle={{ maxHeight: 400 }}
                openLightBox={openLightbox.bind(this)}
            >
                <h1>{title}</h1>
                <Divider margin={3} />
                <p>{description}</p>
                <Divider margin={3} />
                <Row>
                    <Col xs={12}>
                        <ul className={styles.barnCounter}>
                            <li>
                                <p>{guests}</p>
                                <Divider margin={1} />
                                <p>Guests</p>
                                <Divider margin={1} />
                            </li>
                            <li>
                                <p>{accommodation}</p>
                                <Divider margin={1} />
                                <p>accommodation</p>
                                <Divider margin={1} />
                            </li>

                            <li>
                                <p>{receptionCapacity}</p>
                                <Divider margin={1} />
                                <p>Reception Capacity</p>
                                <Divider margin={1} />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Divider margin={3} />
                <Button link={buttonLink}>{buttonText}</Button>
                <Divider xsMargin={3} mdMargin={0} />
            </Hero>

            <Col xs={12} noGutters>
                <div className={styles.middleHero}>
                    <Img className={styles.image} fluid={image?.childImageSharp?.fluid} />
                </div>
            </Col>

            <div className={styles.planHero}>
                {!!planImage && (
                    <Grid noMargin>
                        <Fragment>
                            <Divider margin={5} />
                            <Row>
                                <Col xs={12}>
                                    <Img
                                        fluid={planImage?.childImageSharp?.fluid}
                                        alt={`Wedding Ceremony image`}
                                    />
                                </Col>
                            </Row>
                            <Divider margin={5} />
                        </Fragment>
                    </Grid>
                )}

                <ReactMapGL
                    mapStyle="mapbox://styles/mapbox/outdoors-v10?optimize=true"
                    mapboxApiAccessToken="pk.eyJ1IjoiYmlncml2YWwiLCJhIjoiY2phMzRsanUzMHV6ajJxbGZzdDZsamtqayJ9.PngeT-c1grgQoIMXMhkBUA"
                    zoom={13}
                    longitude={1.121334}
                    latitude={52.134348}
                    height={370}
                    width="100%"
                >
                    <Marker
                        longitude={1.121334}
                        latitude={52.134348}
                        offsetLeft={-19}
                        offsetTop={-8}
                    >
                        <FontAwesomeIcon className={styles.marker} icon="map-marker-alt" />
                    </Marker>
                </ReactMapGL>

                {(!!viewText ||
                    (!!viewButtonLink && !!viewButtonText) ||
                    (!!viewContactLink && !!viewContactText)) && (
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.heroBottom}>
                                    {!!viewText && (
                                        <Fragment>
                                            <p>{viewText}</p>
                                            <Divider margin={3} />
                                        </Fragment>
                                    )}
                                    {!!viewButtonLink && !!viewButtonText && (
                                        <Fragment>
                                            <Button link={viewButtonLink}>{viewButtonText}</Button>
                                            <Divider margin={3} />
                                        </Fragment>
                                    )}
                                    {!!viewContactLink && !!viewContactText && (
                                        <Button link={viewContactLink}>{viewContactText}</Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                )}
            </div>
        </Layout>
    );
};

export default Ceremony;

export const query = graphql`
    query CeremonyQuery($path: String!) {
        barn: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                ceremonies_ceremony_seo_title
                ceremonies_ceremony_seo_description
                ceremonies_ceremony_title
                ceremonies_ceremony_category
                ceremonies_ceremony_description
                ceremonies_ceremony_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ceremonies_ceremony_guests
                ceremonies_ceremony_reception_capacity
                ceremonies_ceremony_accomodation
                ceremonies_ceremony_images {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ceremonies_ceremony_button_link
                ceremonies_ceremony_button_text
                ceremonies_ceremony_plan_image {
                    childImageSharp {
                        fluid(maxWidth: 3000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ceremonies_ceremony_view_text
                ceremonies_ceremony_view_button_text
                ceremonies_ceremony_view_button_link
                ceremonies_ceremony_view_contact_text
                ceremonies_ceremony_view_contact_link
            }
        }
    }
`;
