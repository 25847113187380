import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';

import styles from './styles.module.scss';
import { Col } from '@components/Grid';

const DownloadFile = ({ name, file }) => {
    return (
        <div className={styles.downloadContainer}>
            <a href={file ?? ''} download>
                    <div className={styles.downloadIcon}>
                        <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div>{name}</div>
            </a>
        </div>
    );
};

export default DownloadFile;
