import React, { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import { Grid, Col, Row, Divider } from '@components/Grid';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import Button from '@components/Button';
import Badge from '@components/Badge';
const AccommodationSlider = ({ accommodations, ceremonies, wedding = false }) => {
    const [slickSlider, setSlickSlider] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: true,
        useTransform: true,
        cssEase: 'ease-in-out',
        afterChange: e => {
            setActiveIndex(e);
        },
    };

    const handleSlide = index => {
        slickSlider.slickGoTo(index);
    };

    return (
        <div className={styles.accommodationsSlider}>
            <Grid noMargin>
                <Row>
                    <Col xs={12}>
                        <Slider
                            {...settings}
                            ref={e => {
                                if (e) setSlickSlider(e);
                            }}
                        >
                            {accommodations
                                ?.filter(
                                    ({
                                        node: {
                                            frontmatter: { images },
                                        },
                                    }) => Array.isArray(images) && images.length >= 4
                                )
                                ?.map(
                                    (
                                        {
                                            node: {
                                                frontmatter: {
                                                    title,
                                                    overviewImage,
                                                    slug,
                                                    description,
                                                    image,
                                                    images,
                                                    ceremonies: ceremonyIds,
                                                },
                                            },
                                        },
                                        index
                                    ) => {
                                        const filteredCeremonies = !!ceremonyIds
                                            ? ceremonies
                                                  ?.filter(({ node: { frontmatter: { id } } }) =>
                                                      ceremonyIds.some(x => id === x)
                                                  )
                                                  ?.map(ceremony => ceremony?.node?.frontmatter)
                                            : [];

                                        return (
                                            <div key={index}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={12} md={8}>
                                                                <div
                                                                    className={styles.topLeftImage}
                                                                >
                                                                    {!!images[0]?.childImageSharp
                                                                        ?.fluid && (
                                                                        <Img
                                                                            fluid={
                                                                                images[0]
                                                                                    ?.childImageSharp
                                                                                    ?.fluid
                                                                            }
                                                                            style={{
                                                                                height: '345px',
                                                                            }}
                                                                            alt={`Accommodation Slider image ${index + 1}`}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={styles.topRightImage}
                                                                >
                                                                    {!!images?.[1]?.childImageSharp
                                                                        ?.fluid && (
                                                                        <Img
                                                                            fluid={
                                                                                images?.[1]
                                                                                    ?.childImageSharp
                                                                                    ?.fluid
                                                                            }
                                                                            style={{
                                                                                height: '345px',
                                                                            }}
                                                                            alt={`Accommodation image ${index + 1}`}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Divider margin={3} />
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={
                                                                        styles.bottomLeftImage
                                                                    }
                                                                >
                                                                    {!!images?.[2]?.childImageSharp
                                                                        ?.fluid && (
                                                                        <Img
                                                                            fluid={
                                                                                images?.[2]
                                                                                    ?.childImageSharp
                                                                                    ?.fluid
                                                                            }
                                                                            style={{
                                                                                height: '345px',
                                                                            }}
                                                                            alt={`Accommodation slider image ${index + 1}`}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={
                                                                        styles.sliderItemContentWrapper
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.sliderItemContent
                                                                        }
                                                                    >
                                                                        <h3>{title}</h3>
                                                                        {!!filteredCeremonies && (
                                                                            <Fragment>
                                                                                {filteredCeremonies?.map(
                                                                                    ({
                                                                                        id,
                                                                                        title,
                                                                                    }) => (
                                                                                        <Badge
                                                                                            key={id}
                                                                                            text={
                                                                                                title
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                )}
                                                                                <Divider
                                                                                    margin={1}
                                                                                />
                                                                            </Fragment>
                                                                        )}

                                                                        {/*{!!available && (*/}
                                                                        {/*    <Fragment>*/}
                                                                        {/*        /!*<h3*!/*/}
                                                                        {/*        /!*    className={*!/*/}
                                                                        {/*        /!*        styles.available*!/*/}
                                                                        {/*        /!*    }*!/*/}
                                                                        {/*        /!*>*!/*/}
                                                                        {/*        /!*    Available to{' '}*!/*/}
                                                                        {/*        /!*    {available*!/*/}
                                                                        {/*        /!*        .join(', ')*!/*/}
                                                                        {/*        /!*        .replace(*!/*/}
                                                                        {/*        /!*            /,(?!.*,)/gim,*!/*/}
                                                                        {/*        /!*            ' and'*!/*/}
                                                                        {/*        /!*        )}*!/*/}
                                                                        {/*        /!*</h3>*!/*/}
                                                                        {/*        <Divider margin={1} />*/}
                                                                        {/*    </Fragment>*/}
                                                                        {/*)}*/}
                                                                        <p>{description}</p>
                                                                        <Divider margin={3} />
                                                                    </div>

                                                                    <div
                                                                        className={
                                                                            styles.sliderItemButton
                                                                        }
                                                                    >
                                                                        <Button
                                                                            link={
                                                                                wedding
                                                                                    ? `/weddings/accommodation/${slug}`
                                                                                    : `/stay/barns-and-rooms/${slug}`
                                                                            }
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={
                                                                        styles.bottomRightImage
                                                                    }
                                                                >
                                                                    {!!images?.[3]?.childImageSharp
                                                                        ?.fluid && (
                                                                        <Img
                                                                            fluid={
                                                                                images?.[3]
                                                                                    ?.childImageSharp
                                                                                    ?.fluid
                                                                            }
                                                                            style={{
                                                                                height: '345px',
                                                                            }}
                                                                            alt={`Accommodation slider image ${index + 1}`}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }
                                )}
                        </Slider>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default AccommodationSlider;
