import React, { Fragment } from 'react';

import { Divider } from '@components/Grid';
import Card from '@components/Card';
import Payment from './Payment';

const Overview = ({ countries, addresses, basketProducts, shipping, currency, subtotal }) => {
    return (
        <Fragment>
            <Card>
                <Card.Title>Summary</Card.Title>
                <Card.Content>
                    <Card.List title="Items">
                        {basketProducts.map(item => (
                            <Card.List.Item
                                key={item._id}
                                bold
                                label={`${item.quantity} x ${item.name}`}
                                value={`${currency.symbol}${(item.total / 100).toFixed(2)}`}
                            />
                        ))}
                    </Card.List>
                    <Card.List divider={false} title="Shipping Details">
                        <Card.List.Item
                            id="shipping_name"
                            label="Name"
                            value={addresses?.shipping?.name}
                        />
                        <Card.List.Item
                            id="shipping_email"
                            label="Email"
                            value={addresses?.shipping?.email}
                        />
                        {!!addresses && (
                            <Card.List.Item id="shipping_address">
                                <Card.List.Item.Content>
                                    <p>{`${addresses?.shipping?.addressOne}${
                                        addresses?.shipping?.addressTwo
                                            ? `, ${addresses?.shipping?.addressTwo}`
                                            : ''
                                    },`}</p>
                                    <p>{`${addresses?.shipping?.city}, ${addresses?.shipping?.state}, ${addresses?.shipping?.zip},`}</p>
                                    <p>
                                        {countries.find(
                                            ({ code }) => code === addresses?.shipping?.country
                                        )?.name ?? ''}
                                    </p>
                                </Card.List.Item.Content>
                            </Card.List.Item>
                        )}
                    </Card.List>
                    {!!shipping && (
                        <Card.List title="Shipping Service">
                            <Card.List.Item id="shipping_service">
                                <p>{shipping?.serviceName}</p>
                            </Card.List.Item>
                        </Card.List>
                    )}
                    <Card.List divider={false}>
                        <Card.List.Item
                            id="subtotal"
                            bold
                            label="Subtotal"
                            value={
                                !!subtotal && `${currency.symbol}${parseFloat(subtotal).toFixed(2)}`
                            }
                        />
                        {/*<Card.List.Item*/}
                        {/*    id="delivery charge"*/}
                        {/*    bold*/}
                        {/*    label="Delivery"*/}
                        {/*    value={*/}
                        {/*        !!shipping &&*/}
                        {/*        `${currency.symbol}${(*/}
                        {/*            shipping?.shipmentCost ??*/}
                        {/*            0 + shipping?.otherCost ??*/}
                        {/*            0*/}
                        {/*        ).toFixed(2)}`*/}
                        {/*    }*/}
                        {/*/>*/}
                        <Card.List.Item
                            id="total"
                            bold
                            label="Total"
                            value={
                                !!subtotal &&
                                // !!shipping &&
                                `${currency.symbol}${parseFloat(
                                    subtotal /*+ (shipping?.shipmentCost ?? 0) + (shipping?.otherCost ?? 0)*/
                                ).toFixed(2)}`
                            }
                        />
                    </Card.List>
                </Card.Content>
            </Card>
            <Divider xsMargin={3} lgMargin={0} />
        </Fragment>
    );
};

export default Overview;
