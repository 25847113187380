import React from "react";
import styled from "@emotion/styled";
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {Box, Grid, IconButton, List, ListItem, Stack, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Img from 'gatsby-image';


const CustomMap = ({map, compass}) =>{

	const mapLocations = [
		'Reception and Farm Shop',
		"The Great Barn",
		"Bar",
		'Terrace',
		"The Rose Room",
		"Wellness Wing",
		'Kitchen Garden',
		"Tesla and EV Charging",
		"The Farmhouse",
		'The Roost',
		"The Granary",
		"The Cow House",
		'The Piggery',
		"The Hayloft",
		"The Stables",
		'The Little Burrow',
		"The Burrow",
		"The Nook",
		'The Den',
		"The Little Sett",
		"The Sett",
		'The Little Hive',
		"The Hive",
		"The Little Warren",
		"The Warren",
		"The Coop",
		"The Nest",
		"The Fold",
		"The Holt",
		"The Drey",
		"The Lodge",
		"The Rookery",
		"The Sty",
		"Shepherds Hut 1",
		"Shepherds Hut 2",
		"Shepherds Hut 3",
		"Shepherds Hut 4",
		"Shepherds Hut 5",
		"The Inglenook",
		"Yoga Meadow",
		"Beehives",
		"Gym",
	]

	return(
		<Styles>

			<Box className={'map-wrapper'}>
				<Box className={'map-wrapper-box'}>
					<TransformWrapper
						initialScale={1}
						initialPositionX={200}
						initialPositionY={100}
						centerOnInit={true}
					>
						{({ zoomIn, zoomOut, resetTransform }) => (
							<React.Fragment>
								<TransformComponent>
									<Img fluid={map.fluid} className='map' alt='compass'/>
								</TransformComponent>

								<Img fluid={compass.fluid} className='compass' alt='compass'/>

								<div className="tools">
									<Stack
										justifyContent={'center'}
										direction={'row'}
										pt={1}
									>
										<Box sx={{ px: 3 }} className='tools-icons'>
											<IconButton
												className="icon"
												sx={{
													color: 'black',
													borderRadius: '20px',
													backgroundColor: 'white',
												}}
												onClick={() => zoomIn()}
											>
												<FontAwesomeIcon

													icon={['fas', 'plus']}
													size={'xs'}
												/>
											</IconButton>
											<IconButton
												className="icon"

												sx={{
													color: 'black',
													borderRadius: '20px',
													backgroundColor: 'whitesmoke',
												}}
												onClick={() => zoomOut()}
											>
												<FontAwesomeIcon

													icon={['fas', 'minus']}
													size={'xs'}
												/>
											</IconButton>

											<IconButton
												className="icon"

												sx={{
													color: 'black',
													borderRadius: '20px',
												}}
												onClick={() => resetTransform()}
											>
												<FontAwesomeIcon

													icon={['fas', 'expand']}
													size={'xs'}
												/>
											</IconButton>
										</Box>
									</Stack>
								</div>
							</React.Fragment>
						)}
					</TransformWrapper>

					<List className='locations'>
						{mapLocations?.map((location, index) =>
							<ListItem className='locations-item' key={index}>
								<Typography className='locations-item-text' variant='body1'>{index + 1}. {location}</Typography>
							</ListItem>
						)}
					</List>
				</Box>
			</Box>
		</Styles>
	)
}

const Styles = styled.div`
	
	.map{
		width: 90vw;
		max-width: 1140px;
		height: auto;
		aspect-ratio: 16 / 9;

        @media all and (max-width: 768px) {
            width: 100vw;
        }
		
	}

    .map-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0 2rem;
        margin-bottom: 2rem;
	    
        @media all and (max-width: 768px) {
            padding: 0;
        }
	    
	    .compass{
		    position: absolute !important;
		    right: 5%;
		    top: 5%;
		    width: 17vw;
		    object-fit: contain;
		    max-width: 175px;
	    }
	    
	    &-box{
            max-width: 1140px;
		    position: relative;
        }
    }
	
    &-box {
        max-width: 1140px;
        position: relative;
    }

    img {
        width: 100%;
    }

    .tools {
        position: absolute;
        top: 0.5em;
        left: 1em;
        //background-color: white;
        //border-radius: 8px;

        &-icons {
            display: flex;
            gap: 0.5rem;

            .icon {
                width: 35px;
                height: 35px;
                background-color: white;

                &:hover {
                    background-color: #3d5e67;

                    * {
                        color: whitesmoke;
                    }
                }
            }
        }
    }

    .locations {
        background-color: #f8f7f0;
       	display: block;
	    columns: 4;

        padding-top: 2rem;

        @media screen and (max-width: 768px) {
            columns: 2;
        }

        &-item {
	        padding: 2px 0;
            &-text {
                font-family: 'silk-serif', 'Arial', sans-serif;
                color: #3d5e67;
                padding-left: 1rem;
                @media screen and (max-width: 768px) {
                    width: fit-content;
	                margin-left: 10vw;
                }
            }
        }
    }
`;

export default CustomMap;