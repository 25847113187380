import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@components/Switch';
import { findByType } from '@helpers';
import Button from '@components/Button';

import styles from './styles.module.scss';

const Actions = ({ divider = true, center, right, children, onBack }) => {
    return (
        <div
            className={`${styles.cardActions} ${divider ? styles.cardActionsDivider : ''} ${
                center ? styles.cardActionsCenter : ''
            } ${right ? styles.cardActionsRight : ''}`}
        >
            {onBack ? (
                <Action
                    className={styles.cardActionsActionBack}
                    onClick={onBack}
                    inverse
                    secondary
                    small
                >
                    Back
                </Action>
            ) : null}
            {findByType(children, Action)}
        </div>
    );
};

const Action = ({ onClick, ...props }) => (
    <Button
        onClick={
            onClick
                ? e => {
                      e.stopPropagation();
                      onClick(e);
                  }
                : undefined
        }
        {...props}
    />
);

Actions.propTypes = {
    divider: PropTypes.bool,
    center: PropTypes.bool,
    right: PropTypes.bool,
    onBack: PropTypes.func,
};

Actions.displayName = 'Actions';
Action.displayName = 'Action';
Actions.Action = Action;

Actions.Switch = ({ label, ...props }) => {
    return (
        <div className={styles.cardActionsSwitch}>
            {!!label && <label className={styles.cardActionsSwitchLabel}>{label}</label>}
            <Switch {...props} label={null} hint={null} optional={null} />
        </div>
    );
};
Actions.Switch.displayName = 'Switch';

export default Actions;
