const API_ROUTE = getApiServer();

export const USER_LOGIN = `${API_ROUTE}/auth/login`;
export const USER_REGISTER = `${API_ROUTE}/auth/register`;
export const USER_REFRESH = `${API_ROUTE}/auth/refresh`;
export const USER_PROFILE = `${API_ROUTE}/account`;

export const SITE = `${API_ROUTE}/client/siteData`;

export const CATEGORY = `${API_ROUTE}/client/category`;

export const PRODUCTS = `${API_ROUTE}/client/product`;
export const PRODUCT = id => `${API_ROUTE}/client/product/${id}`;

export const CONTACT = `${API_ROUTE}/client/contact`;

export const BASKET_ADD = basketId => `${API_ROUTE}/client/checkout/${basketId}`;
export const BASKET_CREATE = `${API_ROUTE}/client/checkout`;

export const CHECKOUT_SHIPPING = basketId => `${API_ROUTE}/client/checkout/${basketId}/shipping`;
export const CHECKOUT_BILLING = basketId => `${API_ROUTE}/client/checkout/${basketId}/billing`;
export const CHECKOUT_COMPLETE = basketId => `${API_ROUTE}/client/checkout/${basketId}/complete`;

export const COUNTRIES = `${API_ROUTE}/lists/countries`;

export const FORM = `${API_ROUTE}/client/form`;

function getApiServer() {
    if (process.env.NODE_ENV === 'development') {
        return 'https://api.merlinpanel.com/v1';
    }

    return 'https://api.merlinpanel.com/v1';
}
