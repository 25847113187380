import React, { Component } from "react";
import SVG from 'react-inlinesvg';
// import * as Sentry from "@sentry/browser";
import Button from "@components/Button";

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
    }

    componentDidCatch = (error, errorInfo) => {
        console.log('ERROR');
        console.log(errorInfo);
        this.setState({ error });
    };

    reportFeedback = () => {
        // Sentry.showReportDialog();
    };

    render() {
        if (this.state.error) {
            return <ErrorDialog reportFeedback={() => this.reportFeedback()} />;
        }

        if (this.props.children) {
            return this.props.children;
        }

        return <ErrorDialog reportFeedback={() => this.reportFeedback()} />;
    }
}

const ErrorDialog = ({ reportFeedback }) => (
    <div className="app-error">
        <SVG alt="Logo" src="/media/logo.svg" />
        <h2>We're sorry — something's gone wrong.</h2>
        <p className="mb-1">Our team has been notified, please refresh the page and try again.</p>
        <p>If you'd like to provide more detail to help us prevent these issues in the future, please click 'Report Feedback'</p>
        <Button onClick={reportFeedback}>
            Report Feedback
        </Button>
        <Button secondary link="/">
            Head Home
        </Button>
    </div>
);

export default Error;
