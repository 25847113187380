import React from 'react';
// import SVG from 'react-inlinesvg';
import { Link } from 'gatsby';

import styles from './styles.module.scss';
import { Grid } from '../Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    const isWedding = typeof window != 'undefined' && location.pathname.indexOf('/weddings') > -1;
    return (
        <footer className={isWedding ? styles.transparent : undefined}>
            <Grid>
                <div className={`${styles.footer} `}>
                    <h3 className={styles.footerLogo}>Retreat East</h3>

                    <div className={styles.footerSocial}>
                        <Link
                            to="https://www.tiktok.com/@retreateastofficial"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon tabIndex="0" icon={['fab', 'tiktok']} size="2x" />
                        </Link>

                        <Link
                            to="https://www.instagram.com/retreateast/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon tabIndex="0" icon={['fab', 'instagram']} size="2x" />
                        </Link>

                        <Link
                            to="https://www.facebook.com/retreateast.farm/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon
                                tabIndex="0"
                                icon={['fab', 'facebook-square']}
                                size="2x"
                            />
                        </Link>
                    </div>

                    <ul className={styles.footerAddress}>
                        <li>Brick Kiln Farm</li>
                        <li>Sandy Lane</li>
                        <li>Hemingstone</li>
                        <li>IP6 9QE</li>
                    </ul>

                    <ul className={styles.footerLinks}>
                        <li>
                            <Link to="/careers">Careers</Link>
                        </li>
                        <li>
                            <Link to="/meet-the-team">Meet the Team</Link>
                        </li>
                        <li>
                            <Link to="/house-rules">House Rules</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                            <Link to="/awards">Awards & Recognition</Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/terms-and-conditions">Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link to="/faqs">FAQ's</Link>
                        </li>
                        <li>
                            <Link to="/sustainability">Sustainability</Link>
                        </li>
                    </ul>

                    <p className={styles.footerCopyright}>
                        © Copyright Retreat East {new Date().getFullYear()}
                    </p>

                    <p className={styles.footerCredit}>
                        <a href="https://and-element.com">Website by &Element</a>
                    </p>
                </div>
                <hr style={{ borderColor: '#ddd', margin: '2rem 0' }} />
                <div
                    style={{
                        marginTop: 8,
                        opacity: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'space-around',
                    }}
                    className={`${styles.footerLegal}`}
                >
                    <p>
                        RETREAT EAST LIMITED is a company registered in England and Wales. <br />{' '}
                        Crane Court, 302 London Road, Ipswich, Suffolk, United Kingdom, IP2 0AJ.
                    </p>
                    <p className={`${styles.footerLegalRight}`}>
                        Company No. 05296976
                        <br />
                        VAT No. 837383990
                    </p>
                </div>
            </Grid>
        </footer>
    );
};

export default Footer;
