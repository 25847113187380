import { Link } from 'react-router-dom';
import React from 'react';
import Img from 'gatsby-image';

import Position from './Position';
import { findByType } from '@helpers';
import styles from './styles.module.scss';

const Image = ({ children, href, alt, src, fluid, fixed, height, square, width }) => {
    const imageClasses = styles.cardImage;
    const styleClasses = { backgroundImage: !!src ? `url(${src})` : null, height: height };

    const cardImageTopLeft = findByType(children, Position.Top.Left, true);
    const cardImageTopRight = findByType(children, Position.Top.Right, true);
    const cardImageBottomLeft = findByType(children, Position.Bottom.Left, true);
    const cardImageBottomRight = findByType(children, Position.Bottom.Right, true);

    const cardPositionTop =
        cardImageTopLeft || cardImageTopRight ? (
            <span className={`${styles.cardPositionEnd} ${styles.cardPositionTop}`}>
                {cardImageTopLeft}
                {cardImageTopRight}
            </span>
        ) : null;

    const cardPositionBottom =
        cardImageBottomLeft || cardImageBottomRight ? (
            <span className={`${styles.cardPositionEnd} ${styles.cardPositionBottom}`}>
                {cardImageBottomLeft}
                {cardImageBottomRight}
            </span>
        ) : null;

    if (href) {
        return (
            <Link to={href} className={imageClasses} alt={alt} style={styleClasses}>
                {cardPositionTop}
                {!!fluid && (
                    <Img
                        style={{ height }}
                        imgStyle={{ objectPosition: square ? 'center center' : null }}
                        className={styles.cardImageGatsbyImageWrapper}
                        sizes={{ ...fluid, aspectRatio: !!square ? 1 : fluid?.aspectRatio }}
                        alt={`Retreat East fluid image`}
                    />
                )}
                {!!fixed && (
                    <Img
                        style={{ height }}
                        imgStyle={{ objectPosition: square ? 'center center' : null }}
                        className={styles.cardImageGatsbyImageWrapper}
                        sizes={{ ...fixed, aspectRatio: !!square ? 1 : fluid?.aspectRatio }}
                        alt={`Retreat East fixed image`}
                    />
                )}
                {cardPositionBottom}
            </Link>
        );
    }

    return (
        <div className={imageClasses} alt={alt} style={styleClasses}>
            {cardPositionTop}
            {!!fluid && (
                <Img
                    style={{ height, width }}
                    imgStyle={{ objectPosition: square ? 'center center' : null }}
                    className={styles.cardImageGatsbyImageWrapper}
                    sizes={{ ...fluid, aspectRatio: !!square ? 1 : fluid?.aspectRatio }}
                    alt="Retreat East fixed image"
                />
            )}
            {!!fixed && (
                <Img
                    style={{ height }}
                    imgStyle={{ objectPosition: square ? 'center center' : null }}
                    className={styles.cardImageGatsbyImageWrapper}
                    sizes={{ ...fixed, aspectRatio: !!square ? 1 : fluid?.aspectRatio }}
                    alt="Retreat East fixed image"
                />
            )}
            {cardPositionBottom}
        </div>
    );
};

export default Image;
