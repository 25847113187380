import React from 'react';
import Img from 'gatsby-image';

import styles from './styles.module.scss';
import { Grid, Row, Col } from '@components/Grid';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Hero = ({
    className = '',
    children,
    image: img,
    images,
    imageStyle = {},
    imageWrapperStyle = {},
    heroStyle = {},
    imageFullWidth,
    openLightBox,
    imageAlt,
}) => {
    const image = img && (
        <div className={styles.heroImage}>
            {!!img?.childImageSharp?.fluid ? (
                <Img
                    style={imageWrapperStyle}
                    imgStyle={imageStyle}
                    alt={imageAlt}
                    className={styles.heroGatsbyImageWrapper}
                    fluid={img?.childImageSharp?.fluid}
                />
            ) : (
                <img className={styles.heroGatsbyImageWrapper} alt={imageAlt} src={img} />
            )}
        </div>
    );

    const LeftSliderArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            className={`${styles.heroSliderArrow} ${styles.heroSliderArrowLeft}`}
        >
            <FontAwesomeIcon icon="chevron-left" />
        </button>
    );

    const RightSliderArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            className={`${styles.heroSliderArrow} ${styles.heroSliderArrowRight}`}
        >
            <FontAwesomeIcon icon="chevron-right" />
        </button>
    );

    return (
        <div
            className={`${styles.hero} ${className} ${
                imageFullWidth && image ? styles.heroFullWidth : ''
            } ${!images && !image ? styles.heroNoImage : ''}`}
            style={heroStyle}
        >
            <Grid noMargin>
                <div className={styles.heroContent}>
                    <Row center={(!images && !image) || imageFullWidth ? 'xs' : null}>
                        <Col
                            className={styles.heroContentChildren}
                            xs={12}
                            md={!images && !image ? 10 : imageFullWidth ? 8 : 6}
                        >
                            {children}
                        </Col>
                        {!imageFullWidth && image && (
                            <Col xs={0} md={6}>
                                {image}
                            </Col>
                        )}
                        {images && (
                            <Col xs={0} md={6}>
                                <div className={styles.heroSlider}>
                                    <Slider
                                        dots={false}
                                        infinite
                                        speed={500}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        prevArrow={<LeftSliderArrow />}
                                        nextArrow={<RightSliderArrow />}
                                    >
                                        {images.length &&
                                            images.map((image, index) => (
                                                <div
                                                    className={styles.heroSliderImage}
                                                    key={index}
                                                    onClick={() =>
                                                        openLightBox && openLightBox(index)
                                                    }
                                                >
                                                    <Img
                                                        style={imageWrapperStyle}
                                                        imgStyle={imageStyle}
                                                        className={
                                                            styles.heroSliderGatsbyImageWrapper
                                                        }
                                                        alt={`${imageAlt} ${index + 1}`}
                                                        sizes={{
                                                            ...image.childImageSharp.fluid,
                                                            aspectRatio: 4 / 3,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </Slider>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </Grid>

            {imageFullWidth && image}
        </div>
    );
};

export default Hero;
