import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/Layout';
import Hero from '@components/Hero';
import CallToActions from '@components/CallToActions';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Breadcrumbs from '@components/Breadcrumbs';

import styles from './styles.module.scss';
import Button from '@components/Button';
import SEO from "@components/SEO";

const OurStory = ({
    data: {
        page: {
            frontmatter: {
                path,
                heroSubtitle,
                heroTitle,
                heroDescription,
                heroImage,
                heroButtonText,
                heroButtonLink,
                title,
                description,
                items,
            },
        },
        callToActions: {
            frontmatter: { callToActions },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Explore | Our Story | Spa Hotels Suffolk | Retreat East"
                description="Learn more about our story and why we opened Retreat East. Book your stay and be part of the story at Retreat East."
            />
            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb title="Explore" link="/explore" />
                <Breadcrumbs.Breadcrumb title={heroTitle} link={path} />
            </Breadcrumbs>

            <Hero image={heroImage}>
                <h2>{heroSubtitle}</h2>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
                <Button link={heroButtonLink}>{heroButtonText}</Button>
            </Hero>

            <Divider />

            <Grid>
                <Row center="xs">
                    <Col xs>
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </Col>
                </Row>

                <Divider margin={5} />

                <Row>
                    {items.map(({ title, description, image }, index) => (
                        <Col key={`${title}-${index}`} xs={12} className={styles.item}>
                            <div>
                                <Row middle="xs" reverse={index % 2 !== 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.itemContent}>
                                            <h2>{title}</h2>
                                            <p>{description}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Img
                                            style={{ maxHeight: 270 }}
                                            className={styles.itemImage}
                                            fluid={image.childImageSharp.fluid}
                                            alt={`Our Story image ${index + 1}`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Grid>

            {callToActions && (
                <CallToActions>
                    {callToActions
                        .filter(({ buttonLink }) => !(buttonLink === path))
                        .map(callToAction => (
                            <CallToActions.CallToAction
                                key={callToAction.title}
                                {...callToAction}
                            />
                        ))}
                </CallToActions>
            )}
        </Layout>
    );
};

export default OurStory;

export const query = () => graphql`
    query OurStoryQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                heroSubtitle: our_story_hero_subtitle
                heroTitle: our_story_hero_title
                heroDescription: our_story_hero_description
                heroImage: our_story_hero_image {
                    childImageSharp {
                        fluid(maxHeight: 400) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroButtonText: our_story_hero_button_text
                heroButtonLink: our_story_hero_button_link
                title: our_story_title
                description: our_story_description
                items: our_story_items {
                    title: our_story_items_title
                    description: our_story_items_description
                    image: our_story_items_image {
                        childImageSharp {
                            fluid(maxHeight: 270) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        callToActions: markdownRemark(fileAbsolutePath: { regex: "/data/explore.md/" }) {
            frontmatter {
                callToActions: explore_call_to_actions {
                    title: explore_call_to_actions_title
                    description: explore_call_to_actions_description
                    buttonText: explore_call_to_actions_button_text
                    buttonLink: explore_call_to_actions_button_link
                    image: explore_call_to_actions_image {
                        childImageSharp {
                            fluid(maxHeight: 340) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
