import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { Divider } from '@components/Grid';
import SEO from '@components/SEO';

const FoodAndDrinks = ({
    data: {
        page: {
            frontmatter: {
                heroTitle,
                heroDescription,
                heroImage,
                weddings_food_and_drink_view_text: viewText,
                weddings_food_and_drink_view_button_text: viewButtonText,
                weddings_food_and_drink_view_button_link: viewButtonLink,
                weddings_food_and_drink_view_contact_text: viewContactText,
                weddings_food_and_drink_view_contact_link: viewContactLink,
            },
        },
        foods: { edges: foods },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Wedding Food & Drink | Wedding Venues Suffolk | Retreat East"
                description="Find out about the food and drink options we offer for weddings at Retreat East in Suffolk. We look forward to making your big day special."
            />
            <Hero image={heroImage} imageFullWidth imageStyle={{ objectPosition: '50% 60%' }}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>
            <div className={styles.menuWrapper}>
                <Grid>
                    {foods?.map(({ node: { frontmatter: { path, name, text, image } } }, index) => (
                        <Fragment key={path}>
                            <div className={styles.menu}>
                                <Row middle="xs" reverse={index % 2 === 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.menuContent}>
                                            <h2>{name}</h2>
                                            <p>{text}</p>
                                            <Button link={path}>View</Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Img
                                            className={styles.menuImage}
                                            fluid={image?.childImageSharp?.fluid}
                                            alt={`Wedding Food and Drinks image ${index + 1}`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <Divider margin={4} />
                        </Fragment>
                    ))}
                    <Divider margin={4} />
                    <Row>
                        <Col xs={12}>
                            <div className={styles.heroBottom}>
                                <p>{viewText}</p>
                                <Divider margin={3} />
                                <Button link={viewButtonLink}>{viewButtonText}</Button>
                                <Divider margin={3} />
                                <Button link={viewContactLink}>{viewContactText}</Button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </Layout>
    );
};

export default FoodAndDrinks;

export const query = () => graphql`
    query FoodAndDrinkssQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: weddings_food_and_drink_hero_title
                heroDescription: weddings_food_and_drink_hero_description
                heroImage: weddings_food_and_drink_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                weddings_food_and_drink_view_text
                weddings_food_and_drink_view_button_text
                weddings_food_and_drink_view_button_link
                weddings_food_and_drink_view_contact_text
                weddings_food_and_drink_view_contact_link
            }
        }
        foods: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/food-and-drinks/" } }
            sort: { fields: [frontmatter___weddings_food_and_drink_priority], order: ASC }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        priority: weddings_food_and_drink_priority
                        name: weddings_food_and_drink_name
                        text: weddings_food_and_drink_overview_text
                        image: weddings_food_and_drink_overview_image {
                            childImageSharp {
                                fluid(maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
