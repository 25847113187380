import React, { Fragment, useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';

import Layout from '@components/Layout';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Button from '@components/Button';
import Card from '@components/Card';
import CallToActions from '@components/CallToActions';

import styles from './styles.module.scss';
import SEO from '@components/SEO';
import { Container, Stack, Grid as MuiGrid, Item as MuiItem, Card as MuiCard } from '@mui/material';
import Img from 'gatsby-image';
import BookDialog from '@components/BookDialog';
import Accordion from '@components/Accordion/index.js';
import NewsLetter from "@components/NewsLetter";

const Home = ({
    data: {
        page: {
            frontmatter: {
                heroTitle,
                heroHeading,
                heroButtonText,
                heroButtonLink,
                introductionTitle,
                introductionDescription,
                introductionButtonText,
                introductionButtonLink,
                items,
                callToActions,
                faqs,
            },
        },
        blogPosts: { edges: posts },
        awardImage,
    },
}) => {
    const [heroTitleTop, setHeroTitleTop] = useState(0);
    const [heroTitleBlur, setHeroTitleBlur] = useState(0);
    const [openBookDialog, setOpenBookDialog] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        setHeroTitleTop(window.pageYOffset / 5);
        setHeroTitleBlur(window.pageYOffset / 200);
    };

    return (
        <Layout className="page-home">
            <SEO
                title="Home | Luxury Retreats in Suffolk | Retreat East"
                titleTemplate="%s"
                description="Retreat East is a stunning luxury retreat in the Suffolk countryside. Visit our website to find out more about our stay, dine and spa options."
                canonical="/"
            />
            <BookDialog open={openBookDialog} onClose={() => setOpenBookDialog(false)} />
            <div className={styles.hero}>
                <Stack className={styles.heroContent}>
                    <div style={{ marginBottom: 'auto' }} />

                    <div
                        className={styles.heroContentOffset}
                        style={{
                            transform: `translateY(${heroTitleTop}px)`,
                            filter: `blur(${heroTitleBlur}px)`,
                        }}
                    >
                        <Container>
                            <Stack justifyContent="center" alignItems="center">
                                <h1>{heroHeading}</h1>
                                <Divider margin={3} />
                                <div>
                                    <Button onClick={() => setOpenBookDialog(true)}>
                                        {heroButtonText}
                                    </Button>
                                </div>
                            </Stack>
                        </Container>
                    </div>

                    <div style={{ marginTop: 'auto' }}>
                        <a
                            alt="Retreat East Award 2023"
                            href="https://www.thetimes.co.uk/travel/destinations/uk/england/suffolk/review/retreat-east"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                fluid={awardImage?.childImageSharp?.fluid}
                                className={styles.awardsImage}
                                alt={`Award image`}
                            />
                        </a>
                    </div>
                </Stack>

                <div className={styles.heroVideoOverlay} />
                <video autoPlay loop frameBorder="0" muted>
                    <source src="/videos/hero.mp4" type="video/mp4" />
                </video>
            </div>

            <div className={styles.introduction}>
                <Grid>
                    <Row center="xs">
                        <Col sm={9}>
                            <h2>{introductionTitle}</h2>
                            <p>{introductionDescription}</p>
                            {!!introductionButtonLink && !!introductionButtonText && (
                                <Fragment>
                                    <Divider />
                                    <Button secondary link={introductionButtonLink}>
                                        {introductionButtonText}
                                    </Button>
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Grid>
            </div>

            {items.length && (
                <Grid>
                    {items.map(({ link, title, image, description }, index) => (
                        <Fragment key={title}>
                            <div className={styles.feature}>
                                <Row middle="xs" reverse={index % 2 === 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.featureContent}>
                                            <h2>{title}</h2>
                                            <p>{description}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Img
                                            className={styles.featureImage}
                                            fluid={image.childImageSharp.fluid}
                                            alt={`Home image ${index + 1}`}
                                        />
                                    </Col>
                                </Row>
                                <Divider margin={4} />
                            </div>
                        </Fragment>
                    ))}
                </Grid>
            )}

            <Divider />

            <Container>
                <MuiGrid container spacing={2}>
                    <MuiGrid item xs={12}>
                        <h2>Latest Blog Posts</h2>
                    </MuiGrid>
                    {!!posts?.length &&
                        posts?.map(
                            (
                                {
                                    node: {
                                        frontmatter: {
                                            blogPath,
                                            blogImage,
                                            blogTitle,
                                            blogDescription,
                                        },
                                    },
                                },
                                index
                            ) => {
                                return (
                                    <MuiGrid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        key={`${blogPath}_${index}`}
                                    >
                                        {/* this is a Daintree Card component inside a MUI Grid. Careful of what documentation to reference. */}
                                        <Card fullHeight>
                                            <Card.Image
                                                height={200}
                                                fluid={blogImage?.childImageSharp?.fluid}
                                            />
                                            <Card.Title lineWrap>{blogTitle}</Card.Title>
                                            <Card.Content>
                                                <p style={{ flexGrow: '1' }}>
                                                    {blogDescription?.length > 200
                                                        ? `${blogDescription?.slice(0, 200)}...`
                                                        : blogDescription}
                                                </p>
                                                <Button link={blogPath} fullWidth primary>
                                                    Read more
                                                </Button>
                                            </Card.Content>
                                        </Card>
                                    </MuiGrid>
                                );
                            }
                        )}
                </MuiGrid>
            </Container>

            {!!faqs?.length &&
                faqs.map(({ question, answer }, index) => (
                    <Grid>
                        <Accordion key={`${question}_${index}`} title={question} text={answer} />
                    </Grid>
                ))}

            <Divider />

            {callToActions && (
                <CallToActions>
                    {callToActions.map(props => (
                        <CallToActions.CallToAction key={props.title} {...props} />
                    ))}
                </CallToActions>
            )}
            <NewsLetter/>
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query HomeQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: home_hero_title
                heroHeading: home_hero_heading
                heroButtonText: home_hero_button_text
                heroButtonLink: home_hero_button_link
                introductionTitle: home_introduction_title
                introductionDescription: home_introduction_description
                introductionButtonText: home_introduction_button_text
                introductionButtonLink: home_introduction_button_link
                items: home_items {
                    link: home_items_link
                    title: home_items_title
                    description: home_items_description
                    image: home_items_image {
                        childImageSharp {
                            fluid(maxHeight: 340) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                faqs: home_faqs_items {
                    question: home_faqs_items_question
                    answer: home_faqs_items_answer
                }
                callToActions: home_call_to_actions {
                    title: home_call_to_actions_title
                    description: home_call_to_actions_description
                    buttonText: home_call_to_actions_button_text
                    buttonLink: home_call_to_actions_button_link
                    image: home_call_to_actions_image {
                        childImageSharp {
                            fluid(maxHeight: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                
            }
        }
        awardImage: file(
            relativePath: { eq: "award-the-sunday-times-awards-best-places-to-stay-2023.png" }
        ) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        blogPosts: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/blog-posts/(?!.md$)/" } }
            sort: { order: DESC, fields: frontmatter___blog_post_published }
            limit: 3
        ) {
            edges {
                node {
                    frontmatter {
                        blogPath: path
                        blogTitle: blog_post_title
                        blogDescription: blog_post_description
                        blogImage: blog_post_cover_image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        ghostPages: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/ghost-pages/(?!.md$)/" } }
            sort: { order: DESC, fields: frontmatter___ghost_page_published }
            limit: 3
        ) {
            edges {
                node {
                    frontmatter {
                        ghostPath: path
                        ghostTitle: ghost_page_title
                        ghostDescription: ghost_page_description
                        ghostImage: ghost_page_cover_image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
