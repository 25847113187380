import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { Divider } from '@components/Grid';
import SEO from '@components/SEO';

const Activities = ({
    data: {
        page: {
            frontmatter: { heroTitle, heroDescription, heroImage, items },
        },
    },
}) => {
    return (
        <Layout>
            <SEO
                title="Activities | Weddings | Wedding Venues Suffolk "
                description="View the range of activities we offer for your wedding guests at our wedding venue in Suffolk. We look forward to being part of your big day."
            />
            <Hero image={heroImage} imageFullWidth imageStyle={{ objectPosition: '50% 60%' }}>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
            </Hero>
            <div className={styles.activities}>
                <Grid>
                    {items?.map(({ title, description, image }, index) => (
                        <Fragment key={index}>
                            <div className={styles.menu}>
                                <Row middle="xs" reverse={index % 2 === 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.menuContent}>
                                            <h2>{title}</h2>
                                            <p
                                                dangerouslySetInnerHTML={{ __html: description }}
                                            ></p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        {!!image && (
                                            <Img
                                                className={styles.menuImage}
                                                fluid={image?.childImageSharp?.fluid}
                                                alt={`Wedding activities image ${index + 1}`}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            <Divider margin={4} />
                        </Fragment>
                    ))}
                    <Divider margin={4} />
                    <Row>
                        <Col xs={12}>
                            <div className={styles.heroBottom}>
                                <Button link="/weddings/contact-us">Contact</Button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </Layout>
    );
};

export default Activities;

export const query = () => graphql`
    query ActivitiesQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: weddings_activities_hero_title
                heroDescription: weddings_activities_hero_description
                heroImage: weddings_activities_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                items: weddings_activities_items {
                    title: weddings_activities_items_title
                    description: weddings_activities_items_description
                    image: weddings_activities_items_image {
                        childImageSharp {
                            fluid(maxHeight: 500) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
