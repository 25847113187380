import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';

import Button from '@components/Button';
import { Col, Grid, Row, Divider } from '@components/Grid';
import Layout from '@components/Layout';
import CallToActions from '@components/CallToActions';
import Card from '@components/Card';
import Slider from '@components/Slider';
import Hero from '@components/Hero';

import styles from './styles.module.scss';
import SEO from '@components/SEO';
import AccommodationSlider from '@components/AccommodationSlider';
import Select from '@components/Select';
import Badge from '@components/Badge';

const Accommodations = ({
    data: {
        barnsAndRooms: { edges: barnsAndRooms },
        ceremonies: { edges: ceremonies },
        page: {
            frontmatter: {
                title,
                heroImage,
                heroTitle,
                heroDescription,
                heroButtonLink,
                heroButtonText,
            },
        },
    },
}) => {
    const [ceremony, setCeremony] = useState(null);

    const filteredBarnsAndRooms = barnsAndRooms
        ?.filter(
            ({
                node: {
                    frontmatter: { ceremonies },
                },
            }) =>
                !!ceremonies &&
                (!ceremony ||
                    (!!ceremony &&
                        ceremonies?.some(({ ceremony: ceremonyId }) => ceremonyId === ceremony)))
        )
        .sort(
            ({ node: { frontmatter: a } }, { node: { frontmatter: b } }) =>
                b.guests + b.bedrooms + b.bathrooms - (a.guests + a.bedrooms + a.bathrooms)
        );

    return (
        <Layout>
            <SEO
                title="Wedding Venue Accommodation | Wedding Venues Suffolk"
                description="View the wedding venue accommodation we have available at our wedding venue is Suffolk. We look forward to being part of your big day."
            />
            <Hero image={heroImage} imageFullWidth imageStyle={{ objectPosition: '50% 100%' }}>
                <h1>{heroTitle}</h1>
                {!!heroDescription && <p>{heroDescription}</p>}
                {!!heroButtonLink && !!heroButtonText && (
                    <Button link={heroButtonLink}>{heroButtonText}</Button>
                )}
            </Hero>

            <Divider margin={4} />

            <Grid noMargin>
                <h2>{title}</h2>

                {/*<Row middle="xs">*/}
                {/*     TODO: Waiting for RE to put this into production */}
                {/*    <Col xs={12} md={8}>*/}
                {/*        <h2>{title}</h2>*/}
                {/*    </Col>*/}
                {/*    <Col xs={12} md={4}>*/}
                {/*        <Select*/}
                {/*            placeholder="All Ceremonies"*/}
                {/*            label="Filter"*/}
                {/*            onChange={e => setCeremony(e.target.value)}*/}
                {/*            value={ceremony}*/}
                {/*        >*/}
                {/*            {!!ceremony && <Select.Option label="All Ceremonies" value="" />}*/}
                {/*            {ceremonies?.map(({ node: { frontmatter: { title, id } } }) => (*/}
                {/*                <Select.Option key={id} label={title} value={id} />*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Grid>

            <AccommodationSlider
                accommodations={filteredBarnsAndRooms}
                ceremonies={ceremonies}
                wedding
            />

            <Divider margin={5} />

            <Slider>
                {filteredBarnsAndRooms.map(
                    ({
                        node: {
                            frontmatter: {
                                slug,
                                images,
                                title,
                                description,
                                ceremonies: ceremonyIds = [],
                            },
                        },
                    }) => {
                        const filteredCeremonies = ceremonies
                            ?.filter(({ node: { frontmatter: { id } } }) =>
                                ceremonyIds?.some(({ ceremony }) => id === ceremony)
                            )
                            ?.map(ceremony => ceremony?.node?.frontmatter);

                        return (
                            <Slider.Item key={slug}>
                                <Card
                                    className={styles.packageCard}
                                    onClick={() => navigate(`/weddings/accommodation/${slug}`)}
                                >
                                    {!!images?.[0] && (
                                        <Card.Image
                                            height={200}
                                            fluid={images?.[0].childImageSharp.fluid}
                                            alt={`Wedding Accommodation image`}
                                        />
                                    )}
                                    <Card.Title multiLine>{title}</Card.Title>
                                    <Card.Content>
                                        <div className={styles.packageCardBadges}>
                                            {filteredCeremonies?.map(({ id, title }) => (
                                                <Badge key={id} text={title} />
                                            ))}
                                        </div>
                                        <Divider margin={1} />
                                        <p>{description}</p>
                                    </Card.Content>
                                </Card>
                            </Slider.Item>
                        );
                    }
                )}
            </Slider>

            <Divider margin={5} />
        </Layout>
    );
};

export default Accommodations;

export const query = () => graphql`
    query AccommodationsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: weddings_accommodation_title
                heroTitle: weddings_accommodation_hero_title
                heroDescription: weddings_accommodation_hero_description
                heroButtonText: weddings_accommodation_hero_button_text
                heroButtonLink: weddings_accommodation_hero_button_link
                heroImage: weddings_accommodation_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }

        barnsAndRooms: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { fileAbsolutePath: { regex: "/barn-room/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        title: stay_barn_room_title
                        description: stay_barn_room_description
                        image: stay_barn_room_weddings_accommodation_image {
                            childImageSharp {
                                fluid(maxWidth: 3000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        images: stay_barn_room_images {
                            childImageSharp {
                                fluid(maxWidth: 750) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        overviewImage: stay_barn_room_weddings_accommodation_overview_image {
                            childImageSharp {
                                fluid(maxWidth: 3000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        guests: stay_barn_room_guests
                        bedrooms: stay_barn_room_bedrooms
                        bathrooms: stay_barn_room_bathrooms
                        ceremonies: stay_barn_room_weddings_accommodation_ceremony_ids {
                            ceremony: stay_barn_room_weddings_accommodation_ceremony_id
                        }
                    }
                }
            }
        }
        ceremonies: allMarkdownRemark(
            sort: { fields: [frontmatter___title], order: ASC }
            filter: { fileAbsolutePath: { regex: "/data/ceremonies/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        title: ceremonies_ceremony_title
                        id: ceremonies_ceremony_id
                    }
                }
            }
        }
    }
`;
